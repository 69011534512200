import React, { createContext, useState, useContext } from 'react';
import { getFromSessionStorage, saveToSessionStorage } from '../components/Auth/Utils/utils';

// Create context
const AuthContext = createContext();

// Provider component
export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const login = (sessionId) => {
    saveToSessionStorage('login_session_id', sessionId);
    setIsAuthenticated(true);
  };

  const logout = () => {
    sessionStorage.removeItem('login_session_id');
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to access authentication context
export const useAuth = () => {
  return useContext(AuthContext);
};
