import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import AuthBackground from '../ReusableComponents/AuthBackground/AuthBackground';
import CardComponent from '../ReusableComponents/CardComponent/CardComponent';
import './Verify2faOTPForm.css';
import { Link, useNavigate } from 'react-router-dom';

import Spinner from '../ReusableComponents/Spinner/Spinner';
import Notification from '../ReusableComponents/Notification/Notification';
import { saveToSessionStorage, getFromSessionStorage, saveToLocalStorage, getFromLocalStorage } from '../Utils/utils';

const getCookie = (name) => {
  const cookieValue = document.cookie
    .split('; ')
    .find(row => row.startsWith(name + '='))
    ?.split('=')[1];
  return cookieValue || '';
};

const Verify2faOTPForm = () => {
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [otp, setOtp] = useState(Array(6).fill(''));
  const [maskedMobileNumber, setMaskedMobileNumber] = useState('(999) ***-**00');
  const [signupFormData, setSignupFormData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState({
    message: '',
    type: '',
    visible: false,
  });

  // Create references for each input to handle focus changes
  const inputRefs = useRef([]);

  const [mobileOtpResendCountFor2fa, setMobileOtpResendCountFor2fa] = useState(0);

  // Display notification
  const showNotification = (message, type) => {
    setNotification({ message, type, visible: true });
  };

  const closeNotification = () => {
    setNotification({ ...notification, visible: false });
  };

  // Get signup form data from sessionStorage
  useEffect(() => {
    const savedFormData = getFromSessionStorage('signupFormData');
    if (savedFormData) {
      const parsedData = savedFormData;
      setSignupFormData(parsedData);
    }
  }, []);

  useEffect(() => {
    const storedCount = getFromSessionStorage('mobileOtpResendCountFor2fa');
    if (storedCount) {
      setMobileOtpResendCountFor2fa(parseInt(storedCount, 10));
    }
  }, []);

  // Function to mask mobile number
  const maskMobileNumber = (mobile) => {
    const masked = mobile.replace(/(\d{3})\d{3}(\d{4})/, '($1) ***-****');
    return masked;
  };

  // Set masked mobile number when maskedMobile is available
  useEffect(() => {
    const maskedMobile = getFromSessionStorage("masked_mobile")
    if (maskedMobile) {
      setMaskedMobileNumber(maskMobileNumber(maskedMobile));
    }
  }, []);

  const handleOtpChange = (e, index) => {
    const value = e.target.value.replace(/[^0-9]/g, '');
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Focus the next input if available and input is not empty
    if (value && index < otp.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && index > 0 && otp[index] === '') {
      inputRefs.current[index - 1].focus();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pasteData = (e.clipboardData || window.clipboardData).getData('text');
    const digits = pasteData.replace(/[^0-9]/g, '').split('').slice(0, otp.length);

    if (digits.length) {
      const newOtp = [...otp];
      digits.forEach((digit, idx) => {
        newOtp[idx] = digit;
      });

      setOtp(newOtp);

      // Focus the last digit that was filled
      inputRefs.current[Math.min(digits.length - 1, otp.length - 1)].focus();
    }
  };


  // Handle form submission
  const onSubmit = async () => {
    const otpCode = otp.join('');
    if (otpCode.length === 6) {
      setLoading(true);
      try {
        const csrfToken = getCookie('csrftoken');
        const sessionId = getFromSessionStorage('session_id');
        const email = getFromSessionStorage('email');

        const data = {
          otp: otpCode,
          email: email,
        };

        const response = await axios.post('/signup/v1/verify_2fa_otp/', data, {
          headers: {
            'X-CSRFToken': csrfToken,
            'Content-Type': 'application/json'
          }
        });

        // Check if the response has the expected structure
        if (response && response.data && response.data.code === 1) {
          window.location.href = '/tenant/applications';
        } else {
          showNotification(response.data.message || 'Unexpected error occurred.', 'error');
        }
      } catch (error) {
        // Handle specific case for HTTP 400 status
        if (error.response && error.response.status === 400) {
          const errorCode = error.response.data.code;
          if (errorCode === -3) {
            showNotification('Please enter the correct mobile OTP code.', 'error');
          } else {
            showNotification(error.response.data.message || 'Error verifying OTP.', 'error');
          }
        } else {
          showNotification('An unexpected error occurred. Please try again.', 'error');
        }
      } finally {
        setLoading(false);
      }
    } else {
      showNotification('Please enter the correct mobile OTP code.', "error");
    }
  };


  // Handle OTP resend
  const resendOtp = async (e) => {
    e.preventDefault();
    try {
      const csrfToken = getCookie('csrftoken');
      const sessionId = getFromSessionStorage('session_id');

      const signupData = {
        session_id: sessionId,
        mobile_otp_resend_count: mobileOtpResendCountFor2fa,
      };

      const response = await axios.post('/signup/v1/resend_mobile_otp/', signupData, {
        headers: {
          'X-CSRFToken': csrfToken,
          'Content-Type': 'application/json',
        },
      });

      if (response.data.code === 1) {
        setMobileOtpResendCountFor2fa(response.data.mobile_otp_resend_count);
        saveToSessionStorage('mobileOtpResendCountFor2fa', response.data.mobile_otp_resend_count);
        showNotification(response.data.message, 'success');
      } else {
        showNotification(response.data.message, 'error');
      }
    } catch (error) {
      console.error('Error resending OTP.', error);
      showNotification('An error occurred while resending OTP. Please try again.', 'error');
    }
  };

  return (
    <AuthBackground>
      <div className="main-content min-vh-100 d-flex align-items-center">
        <div className="container-fluid">
          <div className="d-flex flex-wrap justify-content-center">
            {/* Form Card - Verify your account */}
            <CardComponent className="mt-0 form-card">
              <div className="col-12">
                <div className="d-flex justify-content-center align-items-center flex-column text-center bg-white gap-2 card-header">
                  <h3 className="main-title text-center mb-0">Verify your account</h3>
                  <p className="sub-heading text-center mb-0 fw-normal" style={{ opacity: '0.8', fontSize: "0.9rem" }}>Quick verification to keep your account secure</p>
                </div>
                <div className="">
                  <div className="card-body">
                    <form className="text-start text-dark" onSubmit={handleSubmit(onSubmit)}>
                        <div className="input-group my-4 d-flex align-items-center flex-column">
                          <div className="col-12 d-flex justify-content-between">
                            <p className="mb-0" style={{ fontSize: "0.9rem" }}>Enter the otp sent on {maskedMobileNumber}</p>
                          </div>
                          <div className="col-12 otp-inputs d-flex justify-content-between my-2">
                            {otp.map((digit, index) => (
                              <input
                                key={index}
                                ref={(el) => (inputRefs.current[index] = el)}
                                type="text"
                                inputMode='numeric'
                                className="otp-input form-control mx-1 p-2 text-center"
                                id={`digit${index + 1}`}
                                value={digit}
                                onChange={(e) => handleOtpChange(e, index)}
                                onKeyDown={(e) => handleKeyDown(e, index)}
                                onPaste={handlePaste}
                                aria-label="OTP input"
                                maxLength="1"
                                min="0"
                                max="9"
                              />
                            ))}
                          </div>
                          <div className="col-12 d-flex justify-content-between">
                            <a href="#">
                              <p className="mb-0" style={{ fontSize: "0.9rem" }} onClick={resendOtp}>Resend</p>
                            </a>
                          </div>
                        </div>
                    <div className="text-center mt-lg-3">
                        <button
                          type="submit" disabled={loading}
                          className="btn btn-primary-sm btn-primary w-100 my-3 mb-3 text-capitalize"
                        >
                          {loading ? "Verifying..." : "Continue"}
                        </button>
                      </div>
                      <div className="mt-2">
                        <p className="text-sm fw-normal text-center mb-0">
                          Already have an account?
                          <Link to="/login" className="text-info ms-1">
                            Sign in
                          </Link>
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </CardComponent>
          </div>
        </div>
      </div>

      {loading && (
        <div className="d-flex justify-content-center align-items-center spinner-wrapper">
          <Spinner animation="border" />
        </div>
      )}

      <Notification
        message={notification.message}
        type={notification.type}
        visible={notification.visible}
        onClose={closeNotification}
      />
    </AuthBackground>
  );
};

export default Verify2faOTPForm;
