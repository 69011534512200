import React, { useEffect } from 'react';

import "./Notification.css";

import Warning from "../../../../img/Warning.svg"
import Check from "../../../../img/CheckCircle.svg"
import Close from "../../../../img/XCircle.svg"

const getIcon = (type) => {
  if (type === 'error') {
    return <img src={Warning} alt="Warning" style={{ marginRight: '0.5em' }} />;
  } else {
    return <img src={Check} alt="Check" style={{ marginRight: '0.5em' }} />;
  }
};


const Notification = ({ message, type, visible, duration = 5000, onClose }) => {
  useEffect(() => {
    if (visible) {
      const timer = setTimeout(() => {
        onClose();
      }, duration);

      return () => clearTimeout(timer);
    }
  }, [visible, duration, onClose]);

  if (!visible) return null;

  return (
    <div
      className={`notification-popup ${type}`}
      style={{
        position: 'fixed',
        bottom: '20px',
        left: '50%',
        transform: 'translateX(-50%)',
        backgroundColor: type === 'error' ? '#dc3e43' : '#50ad24',
        color: '#fff',
        padding: '0.4rem 1.2rem',
        border: type === 'error' ? "1px solid rgba(150, 48, 27, 0.12);" : '1px solid rgba(27, 150, 69, 0.12)',
        borderRadius: '12px',
        boxShadow: type === 'error' ? '0px 6px 7px 0px rgba(150, 48, 27, 0.08);' : '0px 6px 7px 0px rgba(27, 150, 69, 0.08)',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        maxWidth: '100%',
        minWidth: "300px",
        width: 'auto',
        zIndex: 9999,
        animation: 'slideIn 150ms ease-in-out',
      }}
    >
      {getIcon(type)}
      <p className='mb-0 pe-2' style={{ flex: 1}}>{message}</p>
      <button
        onClick={onClose}
        style={{
          background: 'none',
          border: 'none',
          color: '#fff',
          fontSize: '2rem',
          cursor: 'pointer',
        }}
      >
        <img src={Close} alt="Close" style={{width: "20px", marginBottom: '0.2em' }} />
      </button>
    </div>
  );
};

export default Notification;
