import React from 'react';
import '../../App.css';
import {useState} from 'react';
import {PatternFormat, NumericFormat} from 'react-number-format';

import Flatpickr from "react-flatpickr";

const BuilderForm = ({builderSchema, onChange, showErrors, formErrors, formData, loadDataBtnStatus, admin_edit=false}) => {

    // const [formErrors, setFormErrors] = useState({formErrors});
    formErrors = updateFormErrors(formData, formErrors);
    const [disabledFields, setDisabledFields] = useState(builderSchema?.fields?.filter(field => field.disableOnPrePopulate === "true").map(field => field.id) || [])
    
    // admin edit editable fields
    if(admin_edit && disabledFields?.length > 0){
        setDisabledFields([])
    }

    // State for managing show/hide for fields that have mask
    const [showMaskField, setShowMaskField] = useState({});
    const toggleShowMaskField = (fieldId) => {
        setShowMaskField(prev => ({ ...prev, [fieldId]: !prev[fieldId] }));
    };
    function updateFormErrors(formData, formErrors) {
        const updatedErrors = {...formErrors}; // Create a shallow copy of formErrors to avoid mutating the original object

        for (const field in updatedErrors) {
            // Skip if formData doesn't have the field
            if (formData.hasOwnProperty(field)) {
                const value = formData[field]; // Access once
                if (typeof value === 'string' && value.trim() !== '') {
                    // Valid value found, remove the error
                    delete updatedErrors[field];
                }
            }
        }

        return updatedErrors;
    }
    const handleInputChange = (e, field, forcedValue = null) => {
        const value = (forcedValue != null) ? forcedValue : e.target.value;
        // validateField(field, value);
        onChange(e, value);
    };

    const shouldRenderField = (field) => {
        if (!field.dependentField) {
            return true; // No dependency, render the field
        }

        try {
            return Array.isArray(field.dependentFieldOption) && // Checks if field.dependentFieldOption is a valid array
                field.dependentFieldOption.some(option => {
                    // Handle string matching when formData is either "true" or "false"
                    if (formData[field.dependentField] === "true" && option === "Yes") {
                        return true;
                    }
                    if (formData[field.dependentField] === "false" && option === "No") {
                        return true;
                    }
                    // Handle the regular case where the formData[field.dependentField] is checked against a list of comma-separated values
                    return option?.split(',').includes(formData[field.dependentField] ?? "");
                });

        } catch (error) {
            console.log("Error msg : " + toString(error))
        }
    };
    const getFieldValue = (field) =>
        loadDataBtnStatus && formData ? formData[field.id] : formData?.[field.id] || '';

    const renderPatternField = (field, format) =>{

        return (

        <div key={field.id} className={`form-field ${showErrors && formErrors[field.id] ? "error" : ""} ${field.maskField? "position-relative" : ""}`}>
            <label>{field.input_label}{field.required && ' *'}</label>
            <PatternFormat
                format={format}
                type={!showMaskField[field.id] ? "text" : "password"}
                id={field.id}
                required={field.required}
                value={getFieldValue(field)}
                disabled={disabledFields?.includes(field.id)}
                className="form-control"
                placeholder={`Enter ${field.input_label.toLowerCase()}`}
                onValueChange={(values) => handleInputChange({target: {id: field.id}}, field, values.value)}
            />
            {field.maskField && (
                <span
                    onClick={() => toggleShowMaskField(field.id)}
                    style={{
                        position: 'absolute',
                        right: '30px',
                        top: '65px',
                        transform: 'translateY(-50%)',
                        cursor: 'pointer'
                    }}
                >
                        {showMaskField[field.id] ? <i className="fa fa-eye-slash"/> : <i className="fa fa-eye"/>}
                    </span>
            )}
            {renderHelperText(field)}
        </div>
    )};

    const renderHelperText = (field) => {
        // Check if there's an error for the field
        const hasError = showErrors && formErrors[field.id];
        var helperText = field.helperText
        if(field && field.id === "password" && (!helperText || helperText.length < 1)){
            helperText = "Password must be at least 8 characters, with uppercase, lowercase, number, and special character."
        }
        return (
            <span className={hasError ? "error-message" : ""}>
                {hasError ? formErrors[field.id] : helperText}
            </span>
        );
    };

    const renderField = (field) => {
        //todo: this should not be based on id. all hardcodes have to be removed.

        const isProcedureDate = field.id === "procedure_date";
        if (isProcedureDate) {
            field.type = 'date'
        }
        if (!shouldRenderField(field)) return null;

        const fieldDisabled = disabledFields?.includes(field.id);
        switch (field.type) {
            case 'string':
                const phoneFormat = '###-###-####';
                const ssnFormat = '###-##-####';
                const zipFormat = '#####';

                // Handle specific cases for string type that require select fields
                if (["state"].includes(field.id) || field.id.includes("_state")) {
                    return (
                        <div key={field.id}
                             className={`form-field ${showErrors && formErrors[field.id] ? "error" : ""}`}>
                            <label>{field.input_label}{field.required && ' *'}</label>
                            <select
                                id={field.id}
                                required={field.required}
                                className="form-control"
                                value={getFieldValue(field)}
                                disabled={fieldDisabled}
                                onChange={(e) => handleInputChange(e, field)}
                            >
                                <option value="">Select a state</option>
                                    {/* Dynamically populate options using map */}
                                    {window.states.map((option, index) => (
                                        Object.entries(option).map(([key, value]) => (
                                            <option key={key} value={key}>
                                                {value}
                                            </option>
                                        ))
                                    ))}
                            </select>
                            {renderHelperText(field)}
                        </div>
                    );
                }
                if (["mobile", "work_phone", "patient_phone_number", "business_phone_number", "coborrower_mobile", "tenant_office_number"].includes(field.id)) {
                    return renderPatternField(field, phoneFormat);
                } else if (["ssn", "patient_ssn", "tenant_ssn", "coborrower_ssn"].includes(field.id)) {
                    return renderPatternField(field, ssnFormat);
                } else if (["zip_code", "business_zip_code", "patient_zip_code"].includes(field.id)) {
                    return renderPatternField(field, zipFormat);
                } else {
                    return (
                        <div key={field.id}
                             className={`form-field ${showErrors && formErrors[field.id] ? "error" : ""} ${field.maskField? "position-relative" : ""}`}>
                            <label>{field.input_label}{field.required && ' *'}</label>
                            <input
                                type={(["password", "tenant_password"].includes(field.id) && !field.maskField) || showMaskField[field.id] ? "password" : "text"}
                                id={field.id}
                                required={field.required}
                                value={getFieldValue(field)}
                                disabled={fieldDisabled}
                                className="form-control"
                                placeholder={`Enter ${field.input_label.toLowerCase()}`}
                                onChange={(e) => handleInputChange(e, field)}
                            />
                            {field.maskField && (
                                <span
                                    onClick={() => toggleShowMaskField(field.id)}
                                    style={{
                                        position: 'absolute',
                                        right: '30px',
                                        top: '65px',
                                        transform: 'translateY(-50%)',
                                        cursor: 'pointer'
                                    }}
                                >
                                {showMaskField[field.id] ? <i className="fa fa-eye-slash"/> : <i className="fa fa-eye"/>}
                            </span>
                            )}
                            {renderHelperText(field)}
                        </div>
                    );
                }
            case 'number':
                return (
                    <div key={field.id} className={`form-field ${showErrors && formErrors[field.id] ? "error" : ""}`}>
                        <label>{field.input_label}{field.required && ' *'}</label>
                        <NumericFormat
                        id={field.id}
                        required={field.required}
                        className="form-control"
                        placeholder={`Enter ${field.input_label.toLowerCase()}`}
                        min={field.minimum}
                        max={field.maximum}
                        value={getFieldValue(field)}
                        prefix={field.display_type === "2" ? "$" : ""} // Adds "$" only for display_type "2"
                        thousandSeparator={true}
                        isNumericString={true}
                        disabled={fieldDisabled}
                        onValueChange={(values) => {
                            const { value } = values; // Unformatted numeric value
                            handleInputChange(
                                { target: { value: value === "" ? "" : value, name: field.id, id: field.id, type: "number" } },
                                field
                            );
                        }}
                    />
                        {field.maskField && (
                            <span
                                onClick={() => toggleShowMaskField(field.id)}
                                style={{
                                    position: 'absolute',
                                    right: '10px',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    cursor: 'pointer'
                                }}
                            >
                        {showMaskField[field.id] ? <i className="fa fa-eye-slash"/> : <i className="fa fa-eye"/>}
                    </span>
                        )}
                        {renderHelperText(field)}
                    </div>
                );
            case 'checkbox':
                return (
                    <div key={field.id} className={`form-field ${showErrors && formErrors[field.id] ? "error" : ""}`}>
                        <label>
                            <input type="checkbox" id={field.id} required={field.required} disabled={fieldDisabled}
                                   onChange={(e) => handleInputChange(e, field)}/>
                            {field.input_label}{field.required && ' *'}
                        </label>
                        {renderHelperText(field)}
                    </div>
                );
            case 'date':
                return (
                    <div key={field.id} className={`form-field ${showErrors && formErrors[field.id] ? "error" : ""}`}>
                        <label>{field.input_label}{field.required && ' *'}</label>
                        <Flatpickr
                            id={field.id}
                            required={field.required}
                            className="form-control"
                            value={getFieldValue(field)}
                            disabled={fieldDisabled}
                            placeholder={field.properties?.dateFormat || 'MM/DD/YYYY'}
                            options={{
                                dateFormat: "m/d/Y",
                                minDate: field.id === "procedure_date" ? "today" : field.date_range === "future" ? "today" : undefined,
                                maxDate: field.date_range === "past" ? "today" : undefined,
                                allowInput: true,
                                disableMobile: true,
                            }}
                            onChange={(selectedDates, dateStr, instance) => handleInputChange({
                                target: {
                                    id: instance._input.id,
                                    value: dateStr
                                }
                            }, field, dateStr)}
                            onClose={(selectedDates, dateStr, instance) => handleInputChange({
                                target: {
                                    id: instance._input.id,
                                    value: dateStr
                                }
                            }, field, dateStr)}
                        />
                        {renderHelperText(field)}
                    </div>
                );
            case 'list':
                const listOptions = field.enum.map((option, index) => <option key={index} value={option?.value || option}>{option?.displayValue || option}</option>);
                return (
                    <div key={field.id} className={`form-field ${showErrors && formErrors[field.id] ? "error" : ""}`}>
                        <label>{field.input_label}{field.required && ' *'}</label>
                        <select
                            id={field.id}
                            required={field.required}
                            className="form-control"
                            value={getFieldValue(field)}
                            disabled={fieldDisabled}
                            onChange={(e) => handleInputChange(e, field)}
                        >
                            <option value="">Select an option</option>
                            {listOptions}
                        </select>
                        {renderHelperText(field)}
                    </div>
                );
            case 'boolean':
                const options = [<option key="true" value="true">Yes</option>, <option key="false" value="false">No</option>]
                return (
                    <div key={field.id} className={`form-field ${showErrors && formErrors[field.id] ? "error" : ""}`}>
                        <label>{field.input_label}{field.required && ' *'}</label>
                        <select
                            id={field.id}
                            required={field.required}
                            className="form-control"
                            value={getFieldValue(field)}
                            disabled={fieldDisabled}
                            onChange={(e) => handleInputChange(e, field)}
                        >
                            <option value="">Select an option</option>
                            {options}
                        </select>
                        {renderHelperText(field)}
                    </div>
                );
            default:
                return null;
        }
    };

    const renderFields = (builderSchema) => {
        const groupedFields = builderSchema.fields.filter(field => field.group_label);
        const ungroupedFields = builderSchema.fields.filter(field => !field.group_label);


        return (
            <form>
                {/* Render Grouped Fields */}
                {builderSchema.groups.map(groupKey => (
                    <div key={groupKey} className="group-section">
                        <div className="group-label"><h3>{groupKey}</h3></div>
                        <div className="form-row">
                            {groupedFields
                                .filter(field => field.group_label === groupKey)
                                .map((field, index) => (
                                    <React.Fragment key={field.id}>
                                        {renderField(field)}
                                        {(index + 1) % 2 === 0 && <div className="clear"></div>}
                                    </React.Fragment>
                                ))}
                        </div>
                    </div>
                ))}

                {/* Render Ungrouped Fields */}
                <div className="form-row">
                    {ungroupedFields.map((field, index) => (
                        <React.Fragment key={field.id}>
                            {renderField(field)}
                            {(index + 1) % 2 === 0 && <div className="clear"></div>}
                        </React.Fragment>
                    ))}
                </div>
            </form>
        );
    };

    return (
        <form>
            {builderSchema.fields ? (
                renderFields(builderSchema)
            ) : (
                <div>No fields available.</div>
            )}
        </form>


    );
};

export default BuilderForm;
