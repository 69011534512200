import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';

import { usePlanContext } from '../../../context/PlanContext';
import AuthBackground from '../ReusableComponents/AuthBackground/AuthBackground';
import CardComponent from '../ReusableComponents/CardComponent/CardComponent';
import Spinner from '../ReusableComponents/Spinner/Spinner';
import Notification from '../ReusableComponents/Notification/Notification';
import './PaymentPage.css';
import tick from "../../../img/tick.gif";

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import PaymentCardForm from './PaymentCardForm';
import { saveToSessionStorage, getFromSessionStorage } from '../Utils/utils';

const options = {
  appearance: {
    theme: 'stripe',

    rules: {
      '.Label': {
          display: 'none',
      }
    },
  }
};

const session_id = getFromSessionStorage('session_id');


const getCookie = (name) => {
  const cookieValue = document.cookie
    .split('; ')
    .find(row => row.startsWith(name + '='))
    ?.split('=')[1];
  return cookieValue || '';
};

const formattedNumber = (number) => (new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
}).format(number));


const RenderPlanDetails = ({planKey, toggleAnualPayValue}) => {
  const {plansData} = usePlanContext();
  const plan = plansData[planKey];

  return (
    <div className="col-12">
      <div className="bg-white px-6 card-header m-0" style={{paddingTop: "1.8em", paddingBottom: "1.8em"}}>
        {plan.badge ? <div className='py-1 px-2 mb-2 d-flex align-items-center' style={{backgroundColor: "#fff4e0", borderRadius: "15px", display: "inline-block", maxWidth: "fit-content"}}><i
                        className={plan.logo}
                        style={{
                          color: "#ffa000",
                          fontSize: `${plansData[planKey].logo ? "1rem" : ""}`,
                          marginRight: `${plansData[planKey].logo ? "0.3em" : ""}`,
                        }}
                      ></i>
                        <p className="plan-badge mb-0" style={{fontSize: "0.8rem", color: "#ffa000"}}>{plan.badge}</p>
                      </div> : ""}
        <h3 className="main-title" style={{fontSize: "1.875rem"}}>{plan.title}</h3>
        <p className="my-2 fw-normal" style={{ opacity: '0.8', fontSize: "0.95rem" }}>
          {plan.description}
        </p>
      </div>
      <div className="px-6 pt-3 pb-2">
        <p className="my-2 fw-normal" style={{fontSize: "1.2rem"}}>{plan.featuresHeading}</p>
        <div className="card-body p-0">
          <ul className="list-disc p-0">
            {plan.features.map((feature, index) => (
              <div className="d-flex align-items-center mb-2">
                <i className="fa-solid fa-circle-check" style={{
                  color:"#00c661",
                  fontSize:"1rem",
                  marginRight:"0.5em",
                }}></i>
                <li key={index} className="list-unstyled" style={{fontSize: "0.95rem"}}>{feature}</li>
              </div>
            ))}
          </ul>
        </div>
      </div>

      {planKey !== "enterprise" && <div className="d-flex justify-content-center h-line col-8 my-4 position-absolute" style={{border: "0.8px solid #ddd", bottom: "8em", marginLeft: "50%", transform: "translate(-50%)"}}></div>}

      <div className="col-12 px-6 position-absolute" style={{bottom: "3em"}}>
        {planKey !== "enterprise" && <div className='d-flex align-items-center gap-2 mb-2'>
          <h4 className="mb-0">{toggleAnualPayValue ? formattedNumber(plan.discountPrice) : formattedNumber(plan.actualPrice)}</h4>
          <div className='d-flex flex-column opacity-75 justify-content-center' style={{fontSize: "0.7rem", opacity: "0.8", height: "35px", maxHeight: "100%"}}>
            {toggleAnualPayValue ? <span style={{textDecoration: "line-through", textDecorationColor: "#ff5f5f", textDecorationThickness: "1.5px"}}>{formattedNumber(plan.actualPrice)}</span> : ""}
            <span>{toggleAnualPayValue ? "/ month, paid yearly" : "/ month"}</span>
          </div>
        </div>}

        <div className='plan-final-amount py-2'>
          {/* <div className="plan-tax-amount d-flex justify-content-between pb-1">
            <p className='mb-0 font-weight-bold'>Tax</p>
            <p className='mb-0' style={{ opacity: '0.6' }}>Calculated based on zip</p>
          </div> */}
          <div className="plan-due-today d-flex justify-content-between">
            <p className='mb-0 font-weight-bold'>Due today (USD)</p>
            <p className='mb-0 font-weight-bold' style={{ fontSize: '0.9rem' }}>{toggleAnualPayValue ? formattedNumber(plan.discountPrice * 12) : formattedNumber(plan.actualPrice)}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const DiscountBadge = ({discountPrice, actualPrice }) => {

  // Helper function for formatting numbers
  const formattedNumber = (number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(number);
  };

  return (
    <div className='discount-badge'>
        <div className="p-2" style={{ backgroundColor: "#ecf9f4", color: "#009D60", border: "1px solid #baead7", fontSize: "0.9rem" }}>
          Save <span className='font-weight-bold'>{formattedNumber((actualPrice - discountPrice) * 12)}</span> when billed annually
        </div>
    </div>
  );
};

const PaymentConfirmationCard = ({showNotification}) => {
  const handleGoToClick = () => {
    const checkSubsriptionResponse = getFromSessionStorage("checkSubsriptionResponse")
    if(checkSubsriptionResponse === "success") {
      showNotification("User logged in successfully.", "success");
      setTimeout(() => {
        window.location.href = '/login';
        sessionStorage.clear();
      }, 1000)
    } else {
      showNotification("Something went wrong! Please login again.", "error");
      setTimeout(() => {
        window.location.href = '/login';
        sessionStorage.clear();
      }, 1000)
    }
  };

  return (
    <CardComponent className="mt-0 plan-card responsive-payment-confirmation-card position-relative">
      <div className="col-12">
        <div className="d-flex justify-content-center align-items-center flex-column text-center bg-white gap-2 card-header">
          <h3 className="main-title text-center mb-0">Welcome onboard!</h3>
        </div>
        <div className="">
          <div className="card-body d-flex flex-column">
            <img src={tick} alt="Confirmation tick" style={{width: "250px", display: "block", margin: "auto"}}/>
            <p style={{fontSize: "0.9rem", textAlign: "center"}}>When you are charged, we'll send you a receipt to your email. You can view your receipt from the billing history.</p>

            <div className="col-12 px-2 d-flex justify-content-center mt-3">
              <button className='btn btn-primary btn-primary-sm col-12' onClick={handleGoToClick}>Go to Dashboard</button>
            </div>
          </div>
        </div>
      </div>
    </CardComponent>
  )
}

const PaymentPage = () => {
  const navigate = useNavigate();
  const {plansData, setPlansData, toggleAnualPayValue, setToggleAnualPayValue, selectedPlan, setSelectedPlan, stripePublishableKey, setStripePublishableKey, productID, setProductID, priceID, setPriceID, customerId, setCustomerId } = usePlanContext();
  const [paymentSuccess, setPaymentSuccess] = useState(false);

  sessionStorage.setItem('currentSignupStep', 'payment_method');

  useEffect(() => {
    const savedStripeKey = getFromSessionStorage('stripePublishableKey');
    if (savedStripeKey) {
      setStripePublishableKey(savedStripeKey);
    }
  }, []);

  const stripePromise = loadStripe(stripePublishableKey);

  // State for notification
  const [notification, setNotification] = useState({
    message: '',
    type: '',
    visible: false,
  });

  const [loading, setLoading] = useState(false);

  const onPaymentSuccess = () => {
    saveToSessionStorage('paymentSuccess', 'true');
    setPaymentSuccess(true);
    showNotification('Payment successful.', 'success');
  };

  // Handle form submission
  const handleFormSubmit = (data) => {
    //
  };

  // Display notification
  const showNotification = (message, type) => {
    setNotification({ message, type, visible: true });
  };

  const closeNotification = () => {
    setNotification({ ...notification, visible: false });
  };

  useEffect(() => {
    // Check if payment was successful
    const isPaymentDone = getFromSessionStorage('paymentSuccess');
    if (isPaymentDone === 'true') {
      // setPaymentSuccess(true);
    }
  }, []);

  useEffect(() => {
    const planKey = getFromSessionStorage('planKey');
    if(planKey) {
      setSelectedPlan(planKey);
    }
  }, []);

  useEffect(() => {
    const toggleAnualPayValue = getFromSessionStorage('toggleAnualPayValue');
    if(toggleAnualPayValue) {
      setToggleAnualPayValue(toggleAnualPayValue);
    }
  }, []);

  return (
    <AuthBackground>
      <div className="main-content min-vh-100 d-flex align-items-center">
        <div className="container-fluid">
          <div className="d-flex flex-wrap justify-content-evenly align-items-center">
            <div className='d-flex col-12 col-md-6 flex-column align-items-center p-3'>
              {/* Selected Plan Details */}
              <CardComponent className="mt-0 plan-card responsive-plan-card position-relative">
                <RenderPlanDetails planKey={selectedPlan} toggleAnualPayValue={toggleAnualPayValue}/>
              </CardComponent>
              {/* Toggle Form */}
              <DiscountBadge actualPrice={plansData[selectedPlan].actualPrice} discountPrice={plansData[selectedPlan].discountPrice}/>
            </div>


            {/* Form Card - Payment Form (Stripe) */}
            {!paymentSuccess && (
            <CardComponent className="mt-0 form-card">
              <div className="col-12">
                <div className="d-flex justify-content-center align-items-center flex-column text-center bg-white gap-2 card-header">
                  <h4 className="main-title text-center mb-0">Enter your credit card details</h4>
                  <p className="sub-heading text-center fw-normal mb-0" style={{ opacity: '0.8' }}>
                      Add your payment info to unlock full access
                  </p>
                </div>
                <div className="">
                  <div className="card-body">
                    <Elements stripe={stripePromise} options={options}>
                      <PaymentCardForm
                        session_id={session_id}
                        customer_id={customerId}
                        price_id={priceID}
                        plan={selectedPlan}
                        toggleAnualPayValue={toggleAnualPayValue}
                        onSuccess={onPaymentSuccess}
                        onError={(message) => showNotification(message, 'error')}
                      />
                    </Elements>
                  </div>
                </div>
              </div>
            </CardComponent>
            )}

             {/* Payment Confirmation */}
            {paymentSuccess && <PaymentConfirmationCard showNotification={showNotification}/>}
          </div>
        </div>
      </div>

      {loading && (
        <div className="d-flex justify-content-center align-items-center spinner-wrapper">
          <Spinner animation="border" />
        </div>
      )}

      <Notification
        message={notification.message}
        type={notification.type}
        visible={notification.visible}
        onClose={closeNotification}
      />
    </AuthBackground>
  );
};

export default PaymentPage;
