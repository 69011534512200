import React, {useState, useEffect} from 'react';
import {DragDropContext, Droppable, Draggable} from "@atlaskit/pragmatic-drag-and-drop-react-beautiful-dnd-migration";
import '../../App.css';
import axios from 'axios';
import {Controls} from "../../utils/constants";
import Select from 'react-select';
import { getTestModeCookieValue } from '../../utils/global_function';

axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";
const combinedCredentials = window.api_username + ":" + window.api_password;
const ApiCredentials = btoa(combinedCredentials);
const test_mode_val = getTestModeCookieValue()


function VariableVersions({schema, heading, keyId, rules, headers, formControls, topformControls, first_step}) {
    const FormInputVariablePresent = []
    const FormInputVariableNotPresent = []
         const FormInputWidgetsPresent = []
    const FormInputWidgetsNotPresent = []
    const [variables, updatevariables] = useState(FormInputVariableNotPresent);
    const [formvariables, updateformvariables] = useState(FormInputVariablePresent);
    const [inputelements, SetInput] = useState({});
    const [hasRunFunction, setHasRunFunction] = useState(false);
    const [hasRunFunctionWidget, setHasRunFunctionWidget] = useState(false);
    const [selectedProviders, setSelectedProviders] = useState(rules ? rules.providers : null)
    const [multipleDecTrees, setMultipleDecTrees] = useState(rules ? rules.multi_dec_trees : null);
    const [firstStep, setfirstStep] = useState(first_step);
    const [selectedDecisionTree, setDecisionTree] = useState(rules ? rules.decision_tree : []);
    const [selectedTab, setSelectedTab] = useState(rules ? rules.next_step : []);
    const [required, setRequired] = useState(0);
    const [widgets, updateWidgets] = useState(FormInputWidgetsNotPresent);
    const [formwidgets, updateFormWidgets] = useState(FormInputWidgetsPresent);
    /* eslint-disable-next-line */
    const [controls, setControls] = useState(Controls);
    /* eslint-disable-next-line */
    const [usedControls, setUsedControls] = useState(formControls ? formControls : []);
    /* eslint-disable-next-line */
    const [TopusedControls, setTopUsedControls] = useState(topformControls ? topformControls : []);
    const [showProperties, setshowProperties] = useState(0);
    const [decTreeOutcomes, setdecTreeOutcomes] = useState(null);
    const [multipleProducts, setMultipleProducts] = useState(rules ? rules.multi_products : null);

    function handleDecisionTree(e) {
        setDecisionTree(e.target.value);
        getOutcomes(e.target.value)
    }

    function handleMultipleProducts(e){
        check_offer_tables(e)
    }

    function handleMultipleDecTrees(e){
        setMultipleDecTrees(e);
        if (e.length === 1) {
            getOutcomes(e[0].id)
        }
    }

    function getOutcomes(decTreeID){
        // API call to get outcomes and display the corresponding dropdowns.
        if (decTreeID > 0) {
            try {
                axios.get(`/api/v1/get_dec_tree_outcomes/?dTreeID=${decTreeID}&test_mode=${test_mode_val}`, {
                    headers: {
                        Authorization: "Basic " + ApiCredentials
                    },

                }).then(function (response) {
                    console.log(response.data.outcomes)
                    if(response.data.outcomes) {
                        setdecTreeOutcomes(response.data.outcomes)
                    }

                }).catch(function (error) {
                    window.show_alert(error);
                });
            } catch (error) {
                console.error("Error fetching data:", error);
            }

        } else {
            setdecTreeOutcomes([])
        }

    }

    function check_offer_tables(e)
    {
        if (e.length > 0) {
            try {
                axios.get(`/api/v1/check_offer_tables/?selected_product_id=${e[e.length-1].id}&test_mode=${test_mode_val}`, {
                    headers: {
                        Authorization: "Basic " + ApiCredentials
                    },
                }).then(function (response) {
                    console.log("reponseee",response.data.value)
                    if(response.data.value) {
                        setMultipleProducts(e)
                    }
                    else {
                        window.show_alert("Offer table is not setup for this particular product");
                    }
                }).catch(function (error) {
                    window.show_alert(error);
                });
                // todo: update the json structure of fetched variables before updating the state.

            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
        else {
            setMultipleProducts(null)
        }
    }

    function handleFirstStep(e) {
        setfirstStep(e.target.value);
    }

    function handleSelectedTab(e) {
        setSelectedTab(e.target.value);
    }


    const getDependentFieldAndOption = (builderSchema, label) => {
        let dependentField = null;
        let dependentOption = null;

        const traverseElements = (elements) => {
            elements.forEach(element => {
                // console.log(element, label)
                if (element.dependentField) {
                    const conditionField = element.id
                    console.log(conditionField, label)

                    if (conditionField === label) {
                        dependentField = element.dependentField;
                        dependentOption = element.dependentFieldOption;
                    }
                }
                if (element.elements) {
                    traverseElements(element.elements);
                }
            });
        };
        traverseElements(builderSchema.fields);

        return {dependentField, dependentOption};
    };

    const fetchData = async () => {

        try {
            const response = await axios.get(`/api/v1/list_variables/?test_mode=${test_mode_val}`, {
                headers: {
                    Authorization: "Basic " + ApiCredentials
                },
            });
            // todo: update the json structure of fetched variables before updating the state.
            updatevariables(response.data.results)
            setHasRunFunction(true);

        } catch (error) {
            console.error("Error fetching data:", error);
        }


        try {
            const response = await axios.get(`/api/v1/list_widgets/?product=${window.product_type}&test_mode=${test_mode_val}`, {
                headers: {
                    Authorization: "Basic " + ApiCredentials
                },
            });
            updateWidgets(response.data.results)
            setHasRunFunctionWidget(true);

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    // Call fetchData on component mount
    useEffect(() => {
        // if(selectedProviders.length == 0 && rules.providers.length > 0){
        //     setSelectedProviders(rules.providers)
        // }

        fetchData();
    }, []);


    function handlelabelfontsize(event)

    {

        let checkBox_id = event.target.getAttribute('data-checkbox-id');

        let checkBox = document.getElementById(checkBox_id)

        let label = checkBox.parentNode.querySelector('h5');

        if (checkBox.parentNode.contains(checkBox.parentNode.querySelector('label')))

        {

            label = checkBox.parentNode.querySelector('label');

        }

        if (label)

        {

            label.setAttribute("label-font-size", event.target.value)

            label.style.fontSize = event.target.value + "px"

        }

    }



    function handlerequestdoc(event)
    {
        let checkBox_id = event.target.getAttribute('data-checkbox-id');
        let checkBox = document.getElementById(checkBox_id)
        let label = checkBox.parentNode.querySelector('h4');
        if (label)
        {
            label.setAttribute("request_doc_id", event.target.value)
            if (window.request_template_data[event.target.value])
            {
                label.innerHTML = "<i class='fa-solid fa-upload me-3'></i>" + window.request_template_data[event.target.value]
            }
            else {
                label.innerHTML = "<i class='fa-solid fa-upload me-3'></i> Choose Doc Upload Template"
            }
        }
    }


    function Properties({inputelements}) {
        return (
            <>
                <div className='w-100 w-sm-25 card p-3 border' id="properties-tab">
                    <h3 className='mx-auto mt-3'>Properties</h3>
                    <div className='w-100 col-3 mb-2 mt-4'>
                        <label htmlFor="check-required" className="">Required</label>
                        <select disabled className="form-control border"
                                name="check-required[]" id="check-required" onChange={check_required}
                                defaultValue={required}>
                            <option value={0}>No</option>
                            <option value={1}>Yes</option>

                        </select>
                    </div>

                    {(inputelements["name"] === "checkbox_control" || inputelements["name"] === "label_control" || inputelements["name"] === "top_label_control") || inputelements["name"] === "document_upload" || inputelements["name"] === "prove"  ? <div></div> :
                        <div>
                             <div className='w-100 col-3 mb-2 mt-1 input-group input-group-static'>
                            <label className='fs-6 fw-bold m-0 mb-2'>Label: </label>
                            <input disabled type="text" id="properties-input-label" name="properties-input-label" className='mt-n1 me-3 form-control' defaultValue={inputelements["name"]}></input>
                            </div>
                            <div className='w-100 col-3 mb-2 mt-1'>
                            <label className='fs-6 fw-bold m-0'>Engine Name : <span>{inputelements["id"]}</span></label>
                            </div>
                        </div>
                    }

                    <div className='w-100 col-3 mb-2 mt-1'>
                    <label className='fs-6 fw-bold m-0'>Data Type : <span>{inputelements["type"]}</span></label>
                    </div>
                    <hr className="border"></hr>
                    {inputelements["type"] === "widget"  ? '':
                    <div className='w-100 col-3 mb-2 mt-4'>
                        <label htmlFor="check-required" className="fs-6 fw-bold m-0 mb-2">Required</label>
                        <select disabled className="form-control border"
                                name="check-required[]" id="check-required" onChange={check_required}
                                defaultValue={required}>
                            <option value={0}>No</option>
                            <option value={1}>Yes</option>
                            </select>
                    </div>

                    }

                    {inputelements["type"] === "widget" ?
                    <div className='w-100 col-3 mb-2 mt-4'>
                        <label htmlFor="check-required" className="">Terms & Conditions URL</label>
                        <textarea disabled className="form-control border" data-checkbox-id={inputelements["id"]}
                                name="terms-textarea" id="terms-textarea" rows="5">
                        {inputelements["data-terms-url"]}
                    </textarea>
                    </div> : ''}


                    {inputelements["name"] === "document_upload"  ?
                        <div className='mt-3 mb-4'>
                            <label className='fs-6 fw-bold'>Request Doc</label>
                            <select disabled className="form-control border"
                                name="request_library_doc[]" id="request_library_docs" onChange={handlerequestdoc} data-checkbox-id={inputelements["id"]}
                                defaultValue={inputelements["request_doc_id"] && inputelements["request_doc_id"] !== "" ? inputelements["request_doc_id"]: -1}>
                            <option value='-1'>No request doc</option>
                            {window.request_docs.map(({id, name}, index) => {
                                return (
                                    <option key={id} id={id} value={id}>
                                        {name}
                                    </option>
                                );
                            })}
                        </select>
                        </div> : <div></div>
                    }

                    {inputelements["type"] === "string" ? (
                        <div className='w-100 col-3 mb-2 mt-4 input-group input-group-static'>
                            <label className='fs-6 fw-bold'>Max length</label>
                            <input disabled type="text" id="properties-max-length" placeholder="" name="properties-max-length"
                                   defaultValue={inputelements["maxlength"]} className='mt-n1 me-3 form-control'></input>
                        </div>) : ""}
                        {inputelements["type"] === "numeric" ? (
                        <div className='w-100 col-3 mb-5 mt-4 input-group input-group-static'>

                            <label className='fs-6 fw-bold'>Min value</label>
                            <input disabled type="text" id="properties-min-value" placeholder="" name="properties-min-value"
                                   defaultValue={inputelements["minimum"]} className='mt-n1 me-3 form-control'></input>

                            <label className='fs-6 fw-bold mt-4'>Max value</label>
                            <input disabled type="text" id="properties-max-value" placeholder="" name="properties-max-value"
                                   defaultValue={inputelements["maximum"]} className='mt-n1 me-3 form-control'></input>

                        </div>) : ""}
                        {inputelements["name"] === "label_control" || inputelements["name"] === "top_label_control" ? (
                        <div className='w-100 col-3 mb-2 mt-4'>

                            <label className='fs-6 fw-bold'>Font Size</label>

                            <select disabled className="form-control border"
                                name="properties-label-font-size" id="properties-label-font-size" data-checkbox-id={inputelements["id"]}
                                defaultValue={inputelements["label-font-size"]} onChange={handlelabelfontsize}>
                                    <option value="5">5px</option>
                                    <option value="6">6px</option>
                                    <option value="7">7px</option>
                                    <option value="8">8px</option>
                                    <option value="9">9px</option>
                                    <option value="10">10px</option>
                                    <option value="11">11px</option>
                                    <option value="12">12px</option>
                                    <option value="14">14px</option>
                                    <option value="16">16px</option>
                                    <option value="18">18px</option>
                                    <option value="20">20px</option>
                                    <option value="22">22px</option>
                                    <option value="24">24px</option>
                                    <option value="26">26px</option>
                                    <option value="28">28px</option>
                                    <option value="36">36px</option>
                                    <option value="48">48px</option>
                                    <option value="72">72px</option>
                        </select>

                        </div>) : ""}
                    {(inputelements["name"] === "checkbox_control" || inputelements["name"] === "label_control" || inputelements["name"] === "top_label_control") ?
                        <div className='w-100 col-3 mb-2 mt-4'>
                            <label htmlFor="check-required" className="">Label Text<p className="small mt-1">Can include
                                hyperlinks</p></label>
                            <textarea disabled className="form-control border" data-checkbox-id={inputelements["id"]}
                                      name="checkbox-textarea" id="checkbox-textarea" rows="5">
                            {inputelements["data-text"]}
                        </textarea>
                        </div>
                        : ""}
                    {inputelements["type"] === "widget"  ? '':
                        <div className='w-100 col-3 mb-2 mt-4 input-group input-group-static'>
                            <label className='fs-6 fw-bold'>Group label</label>
                            <input disabled type="text" id="properties-group-label" placeholder="" name="properties-group-label"
                                   defaultValue={inputelements["group_label"]} className='mt-n1 me-3 form-control'></input>
                        </div>
                     }

                </div>
            </>
        );
    }

    function Rules() {
        function getOutcomeSelects() {
            var selects = []
            if (decTreeOutcomes){
                decTreeOutcomes.forEach((element) => {
                    for (const [key, value] of Object.entries(element)) {
                        var newvalue = value.replace(/\s/g, '');
                        selects.push(
                            <div className='w-100 col-3 mb-2 mt-4'>
                                <label htmlFor="decision_tree" className="">{value}</label>
                                <select disabled className="form-control border" data-outcome-id={key}
                                        name="outcome-select[]" id={"outcome-select-" + newvalue}
                                        defaultValue={selectedTab && selectedTab[0] ? selectedTab[0][key]: ''}>
                                    <option value="-1">---Select next step---</option>
                                    <option value="0">This is the last step</option>
                                    {/* eslint-disable-next-line  */}
                                    {headers.map(({title, id, label, tab_id}, index) => {
                                        if (id !== keyId)
                                            return (
                                                <option key={index} id={id} value={tab_id}>
                                                    {title}
                                                </option>
                                            );
                                    })}
                                </select>
                            </div>
                        );
                    }
                });
            }

            return selects
        }

        return (
            <>
                <div className='w-100 w-sm-25 card p-3 border'>
                    <h3 className='mx-auto mt-3'>Rules</h3>
                    <div className='w-100 col-3 mb-2 mt-4' id="provider-select">
                        <label htmlFor="provider_select" className="">Provider</label>
                        {/* eslint-disable-next-line  */}
                        <Select
                            value={selectedProviders !== null || rules === undefined || typeof rules == 'undefined' ? selectedProviders : rules.providers}
                            onChange={setSelectedProviders}
                            options={window.providers}
                            isMulti="true"
                            className="react-select"
                        />
                    </div>

                    {window.product_type === 10002 ?
                        <>
                        <div className='w-100 col-3 mb-2 mt-4' id="multi-decision-select">
                            <label htmlFor="decision_tree" className="">Multi-product decision trees
                            </label>
                            {/* eslint-disable-next-line  */}
                            <Select
                                defaultValue={multipleDecTrees != null || rules === undefined || typeof rules == 'undefined' ? multipleDecTrees : ""}
                                // value={multipleDecTrees != null ? multipleDecTrees : ""}
                                onChange={handleMultipleDecTrees}
                                // options={window.workflows}
                                options={window.workflows}
                                isMulti="true"
                            />
                        </div>

                        { formwidgets.length > 0 && formwidgets[0]["engine_name"] === "multi_offers" ?
                        <div className='w-100 col-3 mb-2 mt-4' id="multi-products-select">
                            <label htmlFor="multi_products" className="">Products
                            </label>
                            {/* eslint-disable-next-line  */}
                            <Select
                                defaultValue={multipleProducts != null || rules === undefined || typeof rules == 'undefined' ? multipleProducts : ""}
                                value={multipleProducts}
                                onChange={handleMultipleProducts}
                                // options={window.workflows}
                                options={window.products}
                                isMulti="true"
                            />
                        </div> : <div></div>
                        }
                         </>  : <div className='w-100 col-3 mb-2 mt-4' id="decision-select">
                            <label htmlFor="decision_tree" className="">Decision Tree
                            </label>
                            <select disabled className="form-control border"
                                name="decision_tree[]" id="decision_tree" onChange={handleDecisionTree}
                                defaultValue={selectedDecisionTree}>
                                <option value='-1'>No Decision Tree</option>
                                {window.workflows.map(({ id, name }, index) => {
                                    return (
                                        <option key={id} id={id} value={id}>
                                            {name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>}

                    {/*// code for multi product only*/}
                    {window.product_type === 10002 ?
                        <>
                            {multipleDecTrees && multipleDecTrees.length === 1 && decTreeOutcomes === null ? getOutcomes(selectedDecisionTree) : ''}

                            {decTreeOutcomes && decTreeOutcomes.length > 0 && multipleDecTrees && multipleDecTrees.length === 1 ?
                                <div id="decisionOutcomesDiv">
                                    <p className="text-muted mx-1  mb-2 mt-4" style={{ fontSize: "12px" }}>Select next
                                        step for
                                        each
                                        outcome of the decision tree</p>
                                    {getOutcomeSelects()}

                                </div>
                                : <div className="mt-4">
                                    <label htmlFor="decision_tree" className="">Next step</label>
                                    <select disabled className="form-control border"
                                        name="template_tab[]" id="template_tab" onChange={handleSelectedTab}
                                        defaultValue={selectedTab}>
                                        <option value="-1">---Select next step---</option>
                                        <option value="0">This is the last step</option>
                                        { /* eslint-disable-next-line */}
                                        {headers.map(({ title, id, tab_id }, index) => {
                                            if (id !== keyId)
                                                return (
                                                    <option key={index} id={id} value={tab_id}>
                                                        {title}
                                                    </option>
                                                );
                                        })}
                                    </select>
                                </div>}
                        </>
                        : <>
                            {selectedDecisionTree && decTreeOutcomes === null ? getOutcomes(selectedDecisionTree) : ''}

                            {decTreeOutcomes && decTreeOutcomes.length > 0 ?
                                <div id="decisionOutcomesDiv">
                                    <p className="text-muted mx-1  mb-2 mt-4" style={{ fontSize: "12px" }}>Select next
                                        step for each
                                        outcome of the decision tree</p>
                                    {getOutcomeSelects()}

                                </div>
                                : <div className="mt-4">
                                    <label htmlFor="decision_tree" className="">Next step</label>
                                    <select disabled className="form-control border"
                                        name="template_tab[]" id="template_tab" onChange={handleSelectedTab}
                                        defaultValue={selectedTab}>
                                        <option value="-1">---Select next step---</option>
                                        <option value="0">This is the last step</option>
                                        { /* eslint-disable-next-line */}
                                        {headers.map(({ title, id, tab_id }, index) => {
                                            if (id !== keyId)
                                                return (
                                                    <option key={index} id={id} value={tab_id}>
                                                        {title}
                                                    </option>
                                                );
                                        })}
                                    </select>
                                </div>}
                        </>}
                    <div className='w-100 col-3 mb-2 mt-4'>
                        <label htmlFor="check-required" className="fs-6 fw-bold m-0 mb-2">Is this the first
                            step?</label>
                        <select disabled className="form-control border"
                            name="first-step-select[]" id="first-step-select" onChange={handleFirstStep}
                            defaultValue={firstStep}>
                            <option value={0}>No</option>
                            <option value={1}>Yes</option>

                        </select>
                    </div>


                </div>
            </>
        );
    }



    function check_required(e) {
        var current_tab = document.getElementById("justify-tab-example-tabpane-" + keyId);
        var el = current_tab.querySelector(".focused");

        if (el) {
            var is_required_checked = e.target.value;
            setRequired(is_required_checked)
            console.log(is_required_checked)

            var parentElement = el.parentNode;
            var label = parentElement.querySelector('label');
            const iElement = label.querySelector('i');
            if (iElement) {
                label.removeChild(iElement);
            }
            // setRequired(0);
            el.setAttribute("data-isRequired", false);
            if (is_required_checked > 0) {
                if (schema && schema.schema && schema.schema.required && schema.schema.required.indexOf(el.id) < 0) {
                    schema.schema.required.push(el.id)
                }

                var ast = document.createElement('i');
                ast.className = 'fa-solid fa-asterisk fa-xs me-1 text-danger';
                label.appendChild(ast);
                el.setAttribute("data-isRequired", true);
                // setRequired(1);
            }
            else{
               if (schema && schema.schema && schema.schema.required && schema.schema.required.indexOf(el.id) > -1) {
                    schema.schema.required.splice(schema.schema.required.indexOf(el.id), 1)
                }
            }
        }

        setTimeout(() => {
            let elems = current_tab.querySelector('#properties-max-length');
            if (elems) {
                elems.addEventListener('keyup', handleKeyDown);
            }
            let labelText = current_tab.querySelector('#properties-input-label');
            if (labelText) {
                labelText.addEventListener('keyup', handleKeyDown);
            }
            let textField = current_tab.querySelector('#checkbox-textarea');
            if (textField) {
                textField.addEventListener('keyup', handleKeyDown);
            }
            let min_range = current_tab.querySelector('#properties-min-value');
            let max_range = current_tab.querySelector('#properties-max-value');
            if (min_range) {
                min_range.addEventListener('keyup', handleKeyDown);
            }
            if (max_range) {
                max_range.addEventListener('keyup', handleKeyDown);
            }

        }, 0);
    }


    function handleFocus(e) {
        const obj = {
            "name":e.target.getAttribute("name"),
            "id": e.target.id,
            "type": e.target.getAttribute("type"),
            "maxlength": e.target.getAttribute("maxlength"),
            "minimum": e.target.getAttribute("minimum"),
            "maximum": e.target.getAttribute("maximum"),
            "group_label": e.target.getAttribute('group_label'),
            "required": e.target.getAttribute('data-isrequired'),
        }
        if(e.target.getAttribute("name") === "checkbox_control" || e.target.getAttribute("name") === "label_control" || e.target.getAttribute("name") === "top_label_control" || e.target.getAttribute("name") === "document_upload"){
            obj["data-text"] = e.target.getAttribute("data-text")
            obj["label-font-size"] = e.target.getAttribute("label-font-size")
            obj["request_doc_id"] =  e.target.getAttribute("request_doc_id")
        }
        SetInput(obj);
        setshowProperties(1)
        if (e.target.getAttribute("data-isrequired") === "true") {
            setRequired(1);
        } else {
            setRequired(0)
        }


        setTimeout(() => {
            var current_tab = document.getElementById("justify-tab-example-tabpane-" + keyId)

            if(current_tab.querySelector("#check-required")) {
                current_tab.querySelector("#check-required").checked = e.target.getAttribute("data-isRequired") === "true";
            }
            var inputs = current_tab.querySelectorAll('input');
            var select_el = current_tab.querySelectorAll('select');
            var elements = current_tab.querySelectorAll('h4')
            var labels = current_tab.querySelectorAll('h5')
            inputs.forEach(function (input) {
                if (input.classList.contains('focused')) {
                    input.classList.remove('focused');
                }
            })
            select_el.forEach(function (input) {
                if (input.classList.contains('focused')) {
                    input.classList.remove('focused');
                }
            })
            elements.forEach(function (input) {
                if (input.classList.contains('focused')) {
                    input.classList.remove('focused');
                }
            })
            labels.forEach(function (input) {
                if (input.classList.contains('focused')) {
                    input.classList.remove('focused');
                }
            })
            e.target.classList.add('focused');

            let elems = current_tab.querySelector('#properties-max-length');
            if (elems) {
                elems.addEventListener('keyup', handleKeyDown);
            }
            let textField = current_tab.querySelector('#checkbox-textarea');
            if (textField) {
                textField.addEventListener('keyup', handleKeyDown);
            }
            let min_range = current_tab.querySelector('#properties-min-value');
            let max_range = current_tab.querySelector('#properties-max-value');
            if (min_range) {
                min_range.addEventListener('keyup', handleKeyDown);
            }
            if (max_range) {
                max_range.addEventListener('keyup', handleKeyDown);
            }
            let group_label = current_tab.querySelector('#properties-group-label');
            if (group_label) {
                group_label.addEventListener('keyup', handleKeyDown);
            }

            let input_label = current_tab.querySelector('#properties-input-label');
            if (input_label) {
                input_label.addEventListener('keyup', handleKeyDown);
            }

            let prove_terms_input = current_tab.querySelector('#terms-textarea');
            if (prove_terms_input) {
                prove_terms_input.addEventListener('keyup', handleKeyDown);
            }
        }, 0);
    }

    const handleKeyDown = (event) => {
        var current_tab = document.getElementById("justify-tab-example-tabpane-" + keyId)
        var ele = current_tab.querySelector(".focused")

        if (event.target.name === 'checkbox-textarea') {
            var checkBox_id = event.target.getAttribute('data-checkbox-id');
            var checkBox = document.getElementById(checkBox_id)
            checkBox.setAttribute("data-text", event.target.value)
            var label = checkBox.parentNode.querySelector('h5');
            if (checkBox.parentNode.contains(checkBox.parentNode.querySelector('label')))
            {
                label = checkBox.parentNode.querySelector('label');
            }
            label.innerHTML = event.target.value
        }
        else if(event.target.name === 'properties-min-value') {
            if (event.target.value !== '')
            {
                ele.setAttribute("minimum", event.target.value)
            }
            else {
                ele.removeAttribute("minimum")
            }
        }
        else if(event.target.name === 'properties-max-value') {
            if (event.target.value !== '')
            {
                ele.setAttribute("maximum", event.target.value)
            }
            else {
                ele.removeAttribute("maximum")
            }
        }
        else if (event.target.name === 'properties-input-label') {
            if (event.target.value !== '') {
                ele.setAttribute("name", event.target.value)
            } else {
                ele.removeAttribute("name")
            }
            if (ele.parentNode.contains(ele.parentNode.querySelector('label'))) {
                label = ele.parentNode.querySelector('label');
                label.innerHTML = event.target.value
            }
        }

    else if(event.target.name === 'properties-group-label') {
        if (event.target.value && event.target.value.includes("'")) {
            // Replace the single inverted comma with 'drivers'
            event.target.value = event.target.value.replace("'", "");
        }
        if (event.target.value !== '')
        {
            ele.setAttribute("group_label", event.target.value)
        }

        else {
            ele.removeAttribute("group_label")
        }

    } else if (event.target.name === 'terms-textarea') {
        if (event.target.value && event.target.value.includes("'")) {
            // Replace the single inverted comma with 'drivers'
            event.target.value = event.target.value.replace("'", "");
        }

        if (event.target.value !== '') {
            ele.setAttribute("data-terms-url", event.target.value)
        } else {
            ele.removeAttribute("data-terms-url")
        }
    }
        else {
            ele.setAttribute("maxlength", event.target.value)
        }

    };

    useEffect(() => {
        if (!hasRunFunction) {
            if (schema && schema.fields) {
                // Iterate over each field in the schema
                schema.fields.forEach((schemaField) => {
                    variables.forEach((inputVariable) => {
                        console.log(inputVariable, schemaField)
                        // Match variables with schema fields by 'id'
                        if (inputVariable.id === schemaField.id) {
                            // Update inputVariable properties based on the schema field
                            inputVariable['maxLength'] = schemaField.maxLength || '';
                            inputVariable['required'] = schemaField.required ? 'true' : 'false';
                            inputVariable['group_label'] = schemaField.group_label || '';
                            inputVariable['label'] = schemaField.input_label || inputVariable.name;

                            // Update 'enum' values if they exist
                            if (schemaField.enum) {
                                inputVariable['enum'] = schemaField.enum;
                            }

                            // Handle dependent fields (if applicable)
                            const { dependentField, dependentOption } = getDependentFieldAndOption(schema, schemaField.id);
                            inputVariable['dependentField'] = dependentField;
                            inputVariable['dependentFieldOption'] = dependentOption;

                            FormInputVariablePresent.push(inputVariable);
                        }
                    });
                });

                // Add any variables that aren't present in the schema fields to FormInputVariableNotPresent
                variables.forEach((inputVariable) => {
                    if (!FormInputVariablePresent.includes(inputVariable)) {
                        FormInputVariableNotPresent.push(inputVariable);
                    }
                });

                // Update states for variables present and not present in the schema
                updatevariables(FormInputVariableNotPresent);
                updateformvariables(FormInputVariablePresent);
            }
        }
        // eslint-disable-next-line
    }, [variables]);


    useEffect(() => {
        if (!hasRunFunctionWidget) {
            widgets.forEach((inputVariable) => {
                if (schema && schema.widgets && schema.widgets[inputVariable.id]) {
                    FormInputWidgetsPresent.push(inputVariable);
                } else {
                    FormInputWidgetsNotPresent.push(inputVariable);
                }
            });
            updateWidgets(FormInputWidgetsNotPresent)
            updateFormWidgets(FormInputWidgetsPresent)
        }
        // eslint-disable-next-line
    }, [widgets])


    function handleContinue() {
        setshowProperties(0)
    }

    return (
        <div className="App">
            <header className="App-header">
                <div className='d-flex flex-wrap flex-sm-nowrap justify-content-sm-between gap-2'>

                    <DragDropContext>
                        {/* THIS IS FOR INPUT VARIABLES */}

                    <div className="card w-100 w-sm-25 border">
                        <div className="">
                        </div>
                    </div>


                        {/* THIS IS FOR FORM VARIABLES */}
                                <div className='card w-100 w-sm-45 border'>
                                    <h3 className='mx-auto mt-4'>{heading}</h3>

                                    <Droppable droppableId="formControlsTop">
                                {(provided) => (
                                    <div className="row p-3" {...provided.droppableProps}
                                         ref={provided.innerRef}>
                                        { /* eslint-disable-next-line */
                                        TopusedControls.map(({id, name, text, type, required, label_font_size}, index) => {
                                            if (type === "label" || type === "toplabel")
                                            {
                                                return (
                                                    <Draggable key={'top_label_control_' + keyId +index} draggableId={'top_label_control_' + keyId + index} index={index}>
                                                        {(provided) => (
                                                            <div className="row my-1" onClick={handleFocus}
                                                                 ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                <div className="row my-1 justify-content-center">
                                                                    <label
                                                                    style={{ fontSize: label_font_size && label_font_size !== "" ? `${label_font_size}px` : '10px' }}

                                                                    name="top_label_control"
                                                                    id={'top_label_control' + keyId + index}
                                                                    type="toplabel"
                                                                    data-text={text}
                                                                    label-font-size={label_font_size && label_font_size !== "" ? label_font_size : 10}
                                                                    data-isRequired={required}
                                                                    className="col-auto m-0 drag-drop-element" htmlFor={'top_label_control' + keyId + index} dangerouslySetInnerHTML={{__html: text}} ></label>

                                                                </div>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            }
                                        })}
                                        {provided.placeholder}
                                    </div>
                                )}
                                </Droppable>

                                    <Droppable droppableId="formVariables">
                                        {(provided) => (
                                    <div className="row p-3" {...provided.droppableProps}
                                         ref={provided.innerRef}>
                                        {
                                        formwidgets.length === 0 && formvariables.length === 0 ? (
                                            <div className="empty-container row mx-auto">
                                                <i class="fa-solid fa-share-from-square fs-4"></i>
                                            <p className='fs-5'>Please drag and drop variables here</p>
                                            </div>
                                        ) :
                                        formvariables.map(({id, name,label, value, type, required, maxLength, minimum, maximum, group_label}, index) => {
                                            if (value === "offer" || name === "offer" || id === "offer")
                                            {
                                                return (
                                                    <Draggable key={id} draggableId={id} index={index}>
                                                        {(provided) => (
                                                             <div class="row mt-3" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                             <div class="col-12">
                                                                 <iframe className='shadow drag-drop-element' id={id} btn height="500px" title={id} width="100%"
                                                                         src="https://www.africau.edu/images/default/sample.pdf#toolbar=0&navpanes=0&view=fitH"></iframe>
                                                             </div>
                                                         </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            }
                                            else if (value === "agreement" || name === "agreement" || id === "agreement")
                                            {
                                                return (
                                                    <Draggable key={id} draggableId={id} index={index}>
                                                        {(provided) => (
                                                             <div className="row mt-3" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                             <div className="col-1 mt-3">
                                                             <input type="checkbox" id={id} placeholder="" name={id}
                                                                       className='mt-n1 me-3 drag-drop-element'></input>
                                                             </div>
                                                             <div className="col-5 mt-1">
                                                                <p>Agreement Text</p>
                                                             </div>
                                                             <div className="col-6 mt-3 d-flex justify-content-between">
                                                                <a href="/">Link 1</a>
                                                                <a href="/">Link 2</a>
                                                                <a href="/">Link 3</a>
                                                             </div>
                                                         </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            }
                                            else if (value === "review" || name === "review" || id === "review")
                                            {
                                                return (
                                                    <Draggable key={id} draggableId={id} index={index}>
                                                        {(provided) => (
                                                             <div class="col-12 col-lg-8 mx-auto my-5" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                             <div class="card border">
                                                                 <div class="card-body d-flex justify-content-center">
                                                                     <form id={id} className="drag-drop-element">

                                                                         <header>
                                                                             <h2 class="header-title text-center">In Review</h2>
                                                                         </header>
                                                                         <div class="row mb-5 text-center">
                                                                             Your application is in Review
                                                                         </div>
                                                                     </form>
                                                                 </div>
                                                             </div>
                                                         </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            }
                                            else if (value === "decline" || name === "decline" || id === "decline")
                                            {
                                                return (
                                                    <Draggable key={id} draggableId={id} index={index}>
                                                        {(provided) => (
                                                             <div class="col-12 col-lg-8 mx-auto my-5" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                             <div class="card border">
                                                                 <div class="card-body d-flex justify-content-center">
                                                                 <form id={id} className="drag-drop-element">

                                                                         <header>
                                                                             <h2 class="header-title text-center">In Decline</h2>
                                                                         </header>
                                                                         <div class="row mb-5 text-center">
                                                                             Your application declined
                                                                         </div>
                                                                     </form>
                                                                 </div>
                                                             </div>
                                                         </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            }
                                            else if (value === "approve" || name === "approve" || id === "approve")
                                            {
                                                return (
                                                    <Draggable key={id} draggableId={id} index={index}>
                                                        {(provided) => (
                                                             <div class="col-12 col-lg-8 mx-auto my-5" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                             <div class="card border">
                                                                 <div class="card-body d-flex justify-content-center">
                                                                 <form id={id} className="drag-drop-element">

                                                                         <header>
                                                                             <h2 class="header-title text-center">Congrats!</h2>
                                                                         </header>
                                                                         <div class="row mb-5 text-center">
                                                                         Your application got approved
                                                                         </div>
                                                                     </form>
                                                                 </div>
                                                             </div>
                                                         </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            }
                                            else if (type === "string") {
                                                return (
                                                    <Draggable key={id} draggableId={id} index={index}>
                                                        {(provided) => (
                                                            <div
                                                                className='w-50 col-3 input-variables input-group input-group-static mb-4'
                                                                ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                <label>
                                                                {label ? label : name}&nbsp;
                                                                    {required ?
                                                                        <i className="fa-solid fa-asterisk fa-xs me-1 text-danger"></i> : ''}

                                                                </label>
                                                                <input type="string" onFocus={handleFocus}
                                                                       data-isRequired={required} id={id} placeholder=""
                                                                       name={label ? label : name} maxLength={maxLength} group_label={group_label}
                                                                       className='form-control drag-drop-element mt-n1 pb-0'></input>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            } else if (type === "list") {
                                                const options = value.map((val, index) => {
                                                    return (
                                                        <option key={index} value={val.value}>
                                                            {val.displayValue}
                                                        </option>
                                                    );
                                                });
                                                return (
                                                    <Draggable key={id} draggableId={id} index={index}>
                                                        {(provided) => (
                                                            <div
                                                                className='w-50 col-3 input-variables input-group input-group-static'
                                                                ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                <label>
                                                                {label ? label : name}&nbsp;
                                                                                      {required ?
                                                                        <i className="fa-solid fa-asterisk fa-xs me-1 text-danger"></i> : ''}
                                                                </label>
                                                                <select disabled type="list" id={id}  name={label ? label : name} data-isRequired={required} group_label={group_label}
                                                                        onFocus={handleFocus}
                                                                        className='form-control mt-n1 drag-drop-element'>
                                                                    <option value=''>Select an option</option>
                                                                    {options}
                                                                </select>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            } else if (type === "boolean") {
                                                return (
                                                    <Draggable key={id} draggableId={id} index={index}>
                                                        {(provided) => (
                                                            <div
                                                                className='w-100 col-3 input-variables input-group input-group-static'
                                                                ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                <input type="checkbox" id={id} placeholder="" name={id}
                                                                       className='mt-n1 me-3 drag-drop-element'></input>
                                                                <label>
                                                                    {name}
                                                                                      {required ?
                                                                        <i className="fa-solid fa-asterisk fa-xs me-1 text-danger"></i> : ''}
                                                                </label>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            }
                                            else if (type === "date") {
                                                return (
                                                    <Draggable key={id} draggableId={id} index={index}>
                                                        {(provided) => (
                                                            <div
                                                                className='w-50 col-3 input-variables input-group input-group-static'
                                                                ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                <label>
                                                                {label ? label : name}&nbsp;
                                                                                      {required ?
                                                                        <i className="fa-solid fa-asterisk fa-xs me-1 text-danger"></i> : ''}
                                                                </label>
                                                                <input type="date" onFocus={handleFocus}
                                                                       data-isRequired={required} id={id} placeholder=""
                                                                       name={label ? label : name} group_label={group_label}
                                                                       className='form-control drag-drop-element mt-n1 pb-0'></input>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            }
                                            else if (type === "numeric") {
                                                return (
                                                    <Draggable key={id} draggableId={id} index={index}>
                                                        {(provided) => (
                                                            <div
                                                                className='w-50 col-3 input-variables input-group input-group-static'
                                                                ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                <label>
                                                                {label ? label : name}&nbsp;
                                                                                      {required ?
                                                                        <i className="fa-solid fa-asterisk fa-xs me-1 text-danger"></i> : ''}
                                                                </label>
                                                                <input type="numeric" onFocus={handleFocus}
                                                                       data-isRequired={required} id={id} placeholder=""
                                                                       name={label ? label : name}
                                                                       minimum={minimum}
                                                                       maximum={maximum} group_label={group_label}
                                                                       className='form-control drag-drop-element mt-n1 pb-0'></input>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            }
                                            else {
                                                    return (
                                                        <Draggable key={id} draggableId={id} index={index}>
                                                            {(provided) => (
                                                                <div
                                                                    className='w-50 col-3 input-variables input-group input-group-static'
                                                                    ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                    <label>
                                                                    {label ? label : name}&nbsp;
                                                                                          {required ?
                                                                        <i className="fa-solid fa-asterisk fa-xs me-1 text-danger"></i> : ''}
                                                                    </label>
                                                                    <input type="numeric" onFocus={handleFocus}
                                                                           data-isRequired={required} id={id} placeholder=""
                                                                           name={label ? label : name} group_label={group_label}
                                                                           className='form-control drag-drop-element mt-n1 pb-0'></input>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    );
                                            }

                                        })}
                                        {provided.placeholder}
                                    </div>
                                    )}
                                    </Droppable>


                                <Droppable droppableId="formWidgets">
                                {(provided) => (
                                    <div className="row p-3" {...provided.droppableProps}
                                         ref={provided.innerRef}>
                                        {
                                        formwidgets.map(({id, name, value, type, required}, index) => {
                                            if (value === "offer_agreement" || name === "offer_agreement" || id === "offer_agreement" || value === "prequalification_offer" || name === "prequalification_offer" || id === "prequalification_offer")
                                                {
                                                    return (
                                                        <Draggable key={id} draggableId={id} index={index}>
                                                            {(provided) => (
                                                                 <div class="row mt-3" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                 <div class="col-12">
                                                                     <iframe id={id} btn className="shadow drag-drop-element" title={id} height="500px" width="100%"
                                                                             src={window.pdf_url + '#toolbar=0&navpanes=0&view=fitH'}></iframe>
                                                                 </div>
                                                             </div>
                                                            )}
                                                        </Draggable>
                                                    );
                                                }
                                                else if (value === "multi_agreements" || name === "Multi Agreements" || id === "multi_agreements")
                                                {
                                                    return (
                                                        <Draggable key={id} draggableId={id} index={index}>
                                                            {(provided) => (
                                                                 <div class="row mt-3" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                 <div class="col-12">
                                                                     <iframe id={id} btn className="shadow drag-drop-element" title={id}height="500px" width="100%"
                                                                             src={window.pdf_url + '#toolbar=0&navpanes=0&view=fitH'}></iframe>
                                                                 </div>
                                                             </div>
                                                            )}
                                                        </Draggable>
                                                    );
                                                }
                                            else if (value === "agreement" || name === "agreement" || id === "agreement")
                                            {
                                                return (
                                                    <Draggable key={id} draggableId={id} index={index}>
                                                        {(provided) => (
                                                             <div className="row mt-3" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                             <div className="col-1 mt-3">
                                                             <input type="checkbox" id={id} placeholder="" name={id}
                                                                       className='mt-n1 me-3 drag-drop-element'></input>
                                                             </div>
                                                             <div className="col-5 mt-1">
                                                                <p>Agreement Text</p>
                                                             </div>
                                                             <div className="col-6 mt-3 d-flex justify-content-between">
                                                                <a href="/">Link 1</a>
                                                                <a href="/">Link 2</a>
                                                                <a href="/">Link 3</a>
                                                             </div>
                                                         </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            }
                                            else if (value === "review" || name === "review" || id === "review")
                                            {
                                                return (
                                                    <Draggable key={id} draggableId={id} index={index}>
                                                        {(provided) => (
                                                             <div class="col-12 col-lg-8 mx-auto my-5" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                             <div class="card border">
                                                                 <div class="card-body d-flex justify-content-center">
                                                                 <form id={id} className="drag-drop-element">

                                                                         <header>
                                                                             <h2 class="header-title text-center">In Review</h2>
                                                                         </header>
                                                                         <div class="row mb-5 text-center">
                                                                             Your application is in Review
                                                                         </div>
                                                                     </form>
                                                                 </div>
                                                             </div>
                                                         </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            }
                                            else if (value === "decline" || name === "decline" || id === "decline")
                                            {
                                                return (
                                                    <Draggable key={id} draggableId={id} index={index}>
                                                        {(provided) => (
                                                             <div class="col-12 col-lg-8 mx-auto my-5" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                             <div class="card border">
                                                                 <div class="card-body d-flex justify-content-center">
                                                                 <form id={id} className="drag-drop-element">

                                                                         <header>
                                                                             <h2 class="header-title text-center">In Decline</h2>
                                                                         </header>
                                                                         <div class="row mb-5 text-center">
                                                                             Your application declined
                                                                         </div>
                                                                     </form>
                                                                 </div>
                                                             </div>
                                                         </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            }
                                            else if (value === "approve" || name === "approve" || id === "approve")
                                            {
                                                return (
                                                    <Draggable key={id} draggableId={id} index={index}>
                                                        {(provided) => (
                                                             <div class="col-12 col-lg-8 mx-auto my-5" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                             <div class="card border">
                                                                 <div class="card-body d-flex justify-content-center">
                                                                 <form id={id} className="drag-drop-element">

                                                                         <header>
                                                                             <h2 class="header-title text-center">Congrats!</h2>
                                                                         </header>
                                                                         <div class="row mb-5 text-center">
                                                                         Your application got approved
                                                                         </div>
                                                                     </form>
                                                                 </div>
                                                             </div>
                                                         </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            }
                                            else if (value === "plaid_asset" || name === "Plaid Asset" || id === "plaid_asset")
                                            {
                                                return (
                                                    <Draggable key={id} draggableId={id} index={index}>
                                                        {(provided) => (
                                                             <div class="col-12 col-lg-8 mx-auto my-5" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                             <div class="card border">
                                                                 <div class="card-body d-flex justify-content-center">
                                                                 <form id={id} className="drag-drop-element">

                                                                         <header>
                                                                             <h2 class="header-title text-center">Plaid Asset</h2>
                                                                         </header>
                                                                         <div class="row mb-5 text-center">
                                                                             Calling Plaid Asset Provider...
                                                                         </div>
                                                                     </form>
                                                                 </div>
                                                             </div>
                                                         </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            }
                                            else if (value === "plaid_cra_base" || name === "Plaid CRA Base" || id === "plaid_cra_base")
                                                {
                                                    return (
                                                        <Draggable key={id} draggableId={id} index={index}>
                                                            {(provided) => (
                                                                 <div class="col-12 col-lg-8 mx-auto my-5" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                 <div class="card border">
                                                                     <div class="card-body d-flex justify-content-center">
                                                                     <form id={id} className="drag-drop-element">

                                                                             <header>
                                                                                 <h2 class="header-title text-center">Plaid</h2>
                                                                             </header>
                                                                             <div class="row mb-5 text-center">
                                                                                 Calling Plaid provider... Please wait!
                                                                             </div>
                                                                         </form>
                                                                     </div>
                                                                 </div>
                                                             </div>
                                                            )}
                                                        </Draggable>
                                                    );
                                                }
                                                else if (value === "plaid_cra_income" || name === "Plaid CRA Income" || id === "plaid_cra_income")
                                                {
                                                    return (
                                                        <Draggable key={id} draggableId={id} index={index}>
                                                            {(provided) => (
                                                                 <div class="col-12 col-lg-8 mx-auto my-5" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                 <div class="card border">
                                                                     <div class="card-body d-flex justify-content-center">
                                                                     <form id={id} className="drag-drop-element">

                                                                             <header>
                                                                                 <h2 class="header-title text-center">Plaid Income</h2>
                                                                             </header>
                                                                             <div class="row mb-5 text-center">
                                                                                 Calling Plaid provider... Please wait!
                                                                             </div>
                                                                         </form>
                                                                     </div>
                                                                 </div>
                                                             </div>
                                                            )}
                                                        </Draggable>
                                                    );
                                                }
                                            else if (value === "plaid_auth" || name === "Plaid Auth" || id === "plaid_auth")
                                            {
                                                return (
                                                    <Draggable key={id} draggableId={id} index={index}>
                                                        {(provided) => (
                                                             <div class="col-12 col-lg-8 mx-auto my-5" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                             <div class="card border">
                                                                 <div class="card-body d-flex justify-content-center">
                                                                 <form id={id} className="drag-drop-element">

                                                                         <header>
                                                                             <h2 class="header-title text-center">Plaid Auth</h2>
                                                                         </header>
                                                                         <div class="row mb-5 text-center">
                                                                             Calling Plaid Auth provider...
                                                                         </div>
                                                                     </form>
                                                                 </div>
                                                             </div>
                                                         </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            }
                                            else if (value === "flinks_and_welspot" || name === "Flinks" || id === "flinks_and_welspot")
                                                {
                                                    return (
                                                        <Draggable key={id} draggableId={id} index={index}>
                                                            {(provided) => (
                                                                 <div class="col-12 col-lg-8 mx-auto my-5" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                 <div class="card border">
                                                                     <div class="card-body d-flex justify-content-center">
                                                                     <form id={id} className="drag-drop-element">

                                                                             <header>
                                                                                 <h2 class="header-title text-center">Flinks</h2>
                                                                             </header>
                                                                             <div class="row mb-5 text-center">
                                                                                 Calling Flinks Provider...
                                                                             </div>
                                                                         </form>
                                                                     </div>
                                                                 </div>
                                                             </div>
                                                            )}
                                                        </Draggable>
                                                    );
                                                }
                                                else if (value === "loan_offers" || name === "Loan Offers" || id === "loan_offers")
                                                {
                                                    return (
                                                        <Draggable key={id} draggableId={id} index={index}>
                                                            {(provided) => (
                                                                 <div class="col-12 col-lg-12 mx-auto my-5" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                 <div class="card border">
                                                                     <div class="card-body d-flex justify-content-center">
                                                                     <form id={id} className="drag-drop-element">

                                                                             <header>
                                                                                 <h2 class="header-title text-center">Loan Offers</h2>
                                                                             </header>
                                                                             <div class="row mb-5 text-center">
                                                                                Please wait for your offer, it can take up to one minute to process
                                                                             </div>
                                                                         </form>
                                                                     </div>
                                                                 </div>
                                                             </div>
                                                            )}
                                                        </Draggable>
                                                    );
                                                }
                                                else if (value === "multi_offers" || name === "Multi Offers" || id === "multi_offers")
                                                {
                                                    return (
                                                        <Draggable key={id} draggableId={id} index={index}>
                                                            {(provided) => (
                                                                 <div class="col-12 col-lg-12 mx-auto my-5" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                 <div class="card border">
                                                                     <div class="card-body d-flex justify-content-center">
                                                                     <form id={id} className="drag-drop-element">

                                                                             <header>
                                                                                 <h2 class="header-title text-center">Multi Offers</h2>
                                                                             </header>
                                                                             <div class="row mb-5 text-center">
                                                                                Please wait for your offer, it can take up to one minute to process
                                                                             </div>
                                                                         </form>
                                                                     </div>
                                                                 </div>
                                                             </div>
                                                            )}
                                                        </Draggable>
                                                    );
                                                }
                                                else if (value === "prove" || name === "Prove" || id === "prove")
                                                {
                                                    return (
                                                        <Draggable key={'prove'} draggableId={'prove'} index={index}>
                                                            {(provided) => (
                                                                 <div class="col-12 col-lg-12 mx-auto my-5" onClick={handleFocus} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                 <div class="card border">
                                                                     <div class="card-body d-flex justify-content-center drag-drop-element" type="widget" onClick={handleFocus} id="prove" name="prove" data-terms-url="">
                                                                     <form  className="" style={{"pointer-events":"none"}} disabled={true}>

                                                                                 <h2 class="header-title text-center" disabled={true}>Prove</h2>
                                                                         </form>
                                                                     </div>
                                                                 </div>
                                                             </div>
                                                            )}
                                                        </Draggable>
                                                    );
                                                }
                                                else if (value === "product_order_widget" || name === "Product Order Widget" || id === "product_order_widget")
                                                    {
                                                        return (
                                                            <Draggable key={id} draggableId={id} index={index}>
                                                                {(provided) => (
                                                                     <div class="col-12 col-lg-8 mx-auto my-5" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                     <div class="card border">
                                                                         <div class="card-body d-flex justify-content-center">
                                                                         <form id={id} className="drag-drop-element">

                                                                                 <header>
                                                                                     <h5 class="header-title text-center">Product Order Widget</h5>
                                                                                 </header>
                                                                                 <div class="row mb-5 text-center">
                                                                                    Configure Products on Manual Processing
                                                                                 </div>
                                                                             </form>
                                                                         </div>
                                                                     </div>
                                                                 </div>
                                                                )}
                                                            </Draggable>
                                                        );
                                                    }
                                                else if (value === "chirp_verification" || name === "Chirp" || id === "chirp_verification")
                                                {
                                                    return (
                                                        <Draggable key={id} draggableId={id} index={index}>
                                                            {(provided) => (
                                                                 <div class="col-12 col-lg-8 mx-auto my-5" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                 <div class="card border">
                                                                     <div class="card-body d-flex justify-content-center">
                                                                     <form id={id} className="drag-drop-element">

                                                                             <header>
                                                                                 <h2 class="header-title text-center">Chirp</h2>
                                                                             </header>
                                                                             <div class="row mb-5 text-center">
                                                                                 Calling Chirp Provider...
                                                                             </div>
                                                                         </form>
                                                                     </div>
                                                                 </div>
                                                             </div>
                                                            )}
                                                        </Draggable>
                                                    );
                                                }
                                            else {
                                                return (
                                                    <Draggable key={id} draggableId={id} index={index}>
                                                        {(provided) => (
                                                             <div class="col-12 col-lg-8 mx-auto my-5" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                             <div class="card border">
                                                                 <div class="card-body d-flex justify-content-center">
                                                                 <form id={id} className="drag-drop-element">

                                                                         <header>
                                                                             <h2 class="header-title text-center">Congrats!</h2>
                                                                         </header>
                                                                         <div class="row mb-5 text-center">
                                                                         Your application got approved
                                                                         </div>
                                                                     </form>
                                                                 </div>
                                                             </div>
                                                         </div>
                                                        )}
                                                    </Draggable>
                                                );

                                            }

                                        })}
                                        {provided.placeholder}
                                    </div>
                                )}
                                </Droppable>

                                <Droppable droppableId="formControls">
                                {(provided) => (
                                    <div className="row p-3" {...provided.droppableProps}
                                         ref={provided.innerRef}>
                                        { /* eslint-disable-next-line */
                                        usedControls.map(({id, name, text, type, required, label_font_size, request_doc_id}, index) => {
                                            if (type === "document_upload")
                                                {   let temp_name = window.request_template_data[request_doc_id]
                                                    return (
                                                        <Draggable key={'document_upload_' + keyId +index} draggableId={'document_upload_' + keyId + index} index={index}>
                                                            {(provided) => (
                                                                <div className="row mt-3 mb-5" onClick={handleFocus}
                                                                     ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                    <div className="row mt-3 justify-content-center">

                                                                    <div class="wrapper-file-input">
                                                                        <div className='input-box text-center'>
                                                                            <h4 id={'document_upload' + keyId + index} placeholder=""
                                                                               name='document_upload' data-isRequired={required} data-text={text} type="document_upload"
                                                                               request_doc_id={request_doc_id} className='drag-drop-element'>
                                                                            <i class="fa-solid fa-upload me-3"></i>
                                                                            {request_doc_id && request_doc_id !== '' ? temp_name : "Choose Doc Upload Template"}
                                                                            </h4>
                                                                            <label className="col-auto m-0" htmlFor={'document_upload' + keyId + index}></label>
                                                                        </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    );
                                                }
                                            else if (type === "checkbox")
                                            {
                                                return (
                                                    <Draggable key={'checkbox_control_' + keyId +index} draggableId={'checkbox_control_' + keyId + index} index={index}>
                                                        {(provided) => (
                                                            <div className="row mt-3" onClick={handleFocus}
                                                                 ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                <div className="row mt-3 justify-content-center">
                                                                    <input disabled type="checkbox" id={'checkbox_control' + keyId + index} placeholder=""
                                                                           name='checkbox_control' data-isRequired={required} data-text={text}
                                                                           className='col-1 drag-drop-element' style={{"height": "20px"}}></input>
                                                                    <label className="col-auto m-0" htmlFor={'checkbox_control' + keyId + index} dangerouslySetInnerHTML={{__html: text}} ></label>

                                                                </div>
                                                                {/*<div*/}
                                                                {/*    className="col-6 mt-3 d-flex justify-content-between">*/}
                                                                {/*    <a href="/">Link 1</a>*/}
                                                                {/*    <a href="/">Link 2</a>*/}
                                                                {/*    <a href="/">Link 3</a>*/}
                                                                {/*</div>*/}
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            }
                                            else if (type === "label")
                                            {
                                                return (
                                                    <Draggable key={'label_control_' + keyId +index} draggableId={'label_control_' + keyId + index} index={index}>
                                                        {(provided) => (
                                                            <div className="row my-1" onClick={handleFocus}
                                                                 ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                <div className="row my-1 justify-content-center">
                                                                    <label
                                                                    style={{fontSize: label_font_size && label_font_size !== "" ? `${label_font_size}px` : '10px' }}
                                                                    name="label_control"
                                                                    id={'label_control' + keyId + index}
                                                                    type="label"
                                                                    data-text={text}
                                                                    label-font-size={label_font_size && label_font_size !== "" ? label_font_size : 10}
                                                                    data-isRequired={required}
                                                                    className="col-auto m-0 drag-drop-element" htmlFor={'label_control' + keyId + index} dangerouslySetInnerHTML={{__html: text}} ></label>

                                                                </div>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            }

                                        })}
                                        {provided.placeholder}
                                    </div>
                                )}
                                </Droppable>
                                    <button
                                        className="btn mx-auto overflow-visible btn-primary btn-primary-sm continue_btn my-2"
                                        onClick={handleContinue}>Continue
                                    </button>
                                </div>
                    </DragDropContext>
                    {showProperties ? <Properties inputelements={inputelements}/> : <Rules/>}


                </div>
            </header>
        </div>
    );
}

export default VariableVersions;