import React, { useState, useEffect } from 'react';
import '../../../App.css';

const CustomModal = ({ isOpen, onClose, children }) => {

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };
  if (!isOpen) return null;

  return (
    <div className="custom-modal-overlay" onClick={handleOverlayClick}>
      <div className="custom-modal-content">
        <button className="custom-modal-close-btn" onClick={onClose}>x</button>
        {children}
      </div>
    </div>
  );
};

export default CustomModal;
