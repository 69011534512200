import * as React from "react";
import {useEffect, useState} from "react";

function EnerveeLoanOffer({loanData, setEnerveeLoanOffer, submitForm}) {
    const [openAccordions, setOpenAccordions] = useState([false, false]);
    const [selectedOffer, setSelectedOffer] = useState("autopay");

    const toggleAccordion = (index) => {
        setOpenAccordions((prev) => {
            const updatedAccordions = [...prev];
            updatedAccordions[index] = !updatedAccordions[index];
            return updatedAccordions;
        });
    };

    const formatNumber = (value) => {
        if (!isNaN(value)) {
            return Number(value).toFixed(2);
        }
        return value;
    };

    useEffect(() => {
        var offerData;
        if (selectedOffer === 'non_autopay') {
            offerData = {
                "autopay": false,
                "monthly_payment": loanData?.non_autopay?.periodicPaymentAmount || null,
                "enervee_interest_rate": loanData?.non_autopay?.aprEffective || null,
                "loan_term": loanData?.non_autopay?.numberPayments || null,
                "enervee_finance_charge": loanData?.non_autopay?.totalInterestAmount || null,
                "enervee_amount_financed": loanData?.non_autopay?.amountFinanced || null,
                "enervee_total_payments": loanData?.non_autopay?.totalPaymentsAmount || null,
                "enervee_number_of_payments": loanData?.non_autopay?.numberPayments || null,
                "enervee_amount_of_payments": loanData?.non_autopay?.periodicPaymentAmount || null,
                "enervee_first_payment_date": loanData?.non_autopay?.schedule[0].date || null,
            }
        } else {
            offerData = {
                "autopay": true,
                "monthly_payment": loanData?.autopay?.periodicPaymentAmount || null,
                "enervee_interest_rate": loanData?.autopay?.aprEffective || null,
                "loan_term": loanData?.autopay?.numberPayments || null,
                "enervee_finance_charge": loanData?.autopay?.totalInterestAmount || null,
                "enervee_amount_financed": loanData?.autopay?.amountFinanced || null,
                "enervee_total_payments": loanData?.autopay?.totalPaymentsAmount || null,
                "enervee_number_of_payments": loanData?.autopay?.numberPayments || null,
                "enervee_amount_of_payments": loanData?.autopay?.periodicPaymentAmount || null,
                "enervee_first_payment_date": loanData?.autopay?.schedule[0].date || null,
            }

        }
        setEnerveeLoanOffer(offerData)
        /* eslint-disable-next-line */
    }, [selectedOffer])
    return (<>
        <div className='container enervee-loan-widget col-lg-8 text-dark'>
            <div className="row" style={{textAlign: 'initial'}}>
                <div className="mt-2">
                    <div className="mb-5">
                        <h4>Congratulations! You're pre-qualified</h4>
                        <p className="my-3">You pre-qualify for the financing options below.</p>
                    </div>
                    <h5>With auto-pay</h5>
                    <p className="mt-2">The worry and hassle free way to pay your bill. Auto-pay includes a discounted
                        rate too!</p>
                    <div
                        className={`border ${selectedOffer === "autopay" ? 'border-accent' : ''} mt-3 text-dark mb-lg-4 p-4 offer-div pb-0`}
                        style={{"border-radius": "8px"}} onClick={() => setSelectedOffer('autopay')}>
                        <div className="row h5 mb-3 justify-content-between header-display"
                             style={{cursor: 'pointer'}}>
                            <div className='col-6 px-1 d-flex'>
                                <input
                                    type="radio"
                                    value="option1"
                                    checked={selectedOffer === "autopay" ? true : false}
                                    style={{
                                        "height": "25px", "margin-right": "20px"
                                    }}
                                    className="radio-input mt-0"
                                />
                                <div className="row">
                                    <strong
                                        style={{float: 'left'}}>${loanData?.autopay?.periodicPaymentAmount || ''} /mo *
                                    </strong>
                                    <p>for {loanData?.autopay?.numberPayments || ''} months</p>
                                </div>
                            </div>
                            <div className='col-4 px-1'>
                                <div className="row">
                                    <strong
                                        style={{float: 'right'}}>{formatNumber(loanData?.autopay?.aprEffective) || ''} %
                                    </strong>
                                    <p>APR</p>
                                </div>
                            </div>
                            <div className="col-1" onClick={() => toggleAccordion(0)}>
                                <i className={`fa fa-solid ${openAccordions[0] ? "fa-chevron-up" : "fa-chevron-down"}`}></i>
                            </div>
                        </div>
                        {openAccordions[0] && (<>
                            <div className='col-lg-12 d-flex justify-content-between'>
                                <strong style={{float: 'left'}}>Amount financed</strong> <span
                                className="ms-2"
                                style={{fontWeight: '100'}}> ${loanData?.autopay?.amountFinanced} </span>
                            </div>

                            <div className="col-lg-12 d-flex justify-content-between">
                                <strong style={{float: 'left'}}>Total interest</strong>
                                <span className="ms-2"
                                      style={{fontWeight: '100'}}>${loanData?.autopay?.totalInterestAmount} </span>
                            </div>

                            <div className='col-lg-12 d-flex justify-content-between mb-4'>
                                <strong style={{float: 'left'}}>Total of payments</strong><span
                                className="ms-2">${loanData?.autopay?.totalPaymentsAmount} </span>
                            </div>
                        </>)}

                    </div>
                    <h5 className="mt-5">Without auto-pay</h5>
                    <p>Make each payment on Eco Financing with the touch of a button</p>
                    <div
                        className={`border ${selectedOffer === "non_autopay" ? 'border-accent' : ''} mt-3 text-dark mb-lg-4 p-4 offer-div pb-0`}
                        style={{"border-radius": "8px"}} onClick={() => setSelectedOffer('non_autopay')}>

                        <div className="row h5 mb-3 justify-content-between header-display"
                             style={{cursor: 'pointer'}}>
                            <div className='col-6 px-1 d-flex'>
                                <input
                                    type="radio"
                                    value="option1"
                                    checked={selectedOffer === "non_autopay" ? true : false}
                                    style={{
                                        "height": "25px", "margin-right": "20px"
                                    }}
                                    className="radio-input mt-0"
                                />
                                <div className="row">
                                    <strong
                                        style={{float: 'left'}}>${loanData?.non_autopay?.periodicPaymentAmount} /mo *
                                    </strong>
                                    <p>for {loanData?.non_autopay?.numberPayments} months</p>
                                </div>
                            </div>
                            <div className='col-4 px-1'>
                                <div className="row">
                                    <strong
                                        style={{float: 'right'}}>{formatNumber(loanData?.non_autopay?.aprEffective)} %
                                    </strong>
                                    <p>APR</p>
                                </div>
                            </div>
                            <div className="col-1" onClick={() => toggleAccordion(1)}>
                                <i className={`fa fa-solid ${openAccordions[1] ? "fa-chevron-up" : "fa-chevron-down"}`}></i>
                            </div>
                        </div>
                        {openAccordions[1] && (<>
                            <div className='col-lg-12 d-flex justify-content-between'>
                                <strong style={{float: 'left'}}>Amount financed</strong> <span
                                className="ms-2"
                                style={{fontWeight: '100'}}> ${loanData?.non_autopay?.amountFinanced} </span>
                            </div>

                            <div className="col-lg-12 d-flex justify-content-between">
                                <strong style={{float: 'left'}}>Total interest</strong>
                                <span className="ms-2"
                                      style={{fontWeight: '100'}}>${loanData?.non_autopay?.totalInterestAmount} </span>
                            </div>

                            <div className='col-lg-12 d-flex justify-content-between mb-4'>
                                <strong style={{float: 'left'}}>Total of payments</strong><span
                                className="ms-2">${loanData?.non_autopay?.totalPaymentsAmount} </span>
                            </div>
                        </>)}

                    </div>
                </div>
            </div>
        </div>
        <div className='d-flex'>
            <button
                className="btn btn-primary btn-primary-sm mx-auto mt-5"
                id="continue_submit_btn"
                type="submit" onClick={submitForm}>
                Continue
            </button>
        </div>
        <div className="col-md-8 mx-auto mt-3 text-justify"><small style={{"font-size":"10px"}}>*© Eco Financing “as low as 9.99%” Annual Percentage Rate (APR) is effective as of 1/1/2023, is

            based on creditworthiness, underwriting factors, and information obtained by third party companies. It is
            our best rate which includes an incentive rate discount for setting up automatic monthly loan payments at
            loan origination and is subject to loan approval. Monthly payment per $1,000 for 12 months at 9.99% is
            $87.91. A sample payment for a $1,000 for 60 months at 9.99% is $21.24. These payment examples are for
            illustration purposes only. Your actual payment may vary. All Eco Financing loan programs, rates, terms, and
            conditions are subject to change without prior notice. Minimum Experian Vantage Score 4.0 of 580 to qualify.
            Other underwriting factors apply. Eco Financing by Enveree loan programs are owned and funded by Lewis &
            Clark Bank, Member FDIC.

            + Applying will not impact your credit score. Once a loan is approved and monies are received, it will, like
            all loans, impact your credit score.

            Eco Financing loan programs and offers are only valid for Southern California Edison customers who are legal
            residents of the State of California. Applicants must purchase a qualifying appliance through the Enveree
            Marketplace. If utility customer and residency are not validated or if a qualifying appliance is not
            purchased, program offering is invalid. Financing is subject to loan approval.

            For Enveree's return policy, click here. For Enveree's shipping policy, click here. To contact Enveree
            support, click here.</small></div>
    </>)
}

export default EnerveeLoanOffer;