import React from "react";

function InvoiceWidget({ invoiceData }) {
  // Separate product and tax items
  const productItems = invoiceData.filter(
    (item) => !item.name.toLowerCase().includes("tax") && item.quantity * item.price > 0
  );
  const taxItems = invoiceData.filter((item) => item.name.toLowerCase().includes("tax"));

  const cashPrice = invoiceData.reduce((acc, item) => acc + (item.quantity * item.price), 0);

  const totalPrice =
    productItems.reduce((acc, item) => acc + item.quantity * item.price, 0) +
    taxItems.reduce((acc, item) => acc + item.quantity * item.price, 0);

  const productItemsTotal = productItems.reduce(
    (acc, item) => acc + item.quantity * item.price,
    0
  );
  const negativeTotalItems = invoiceData.filter(
    (item) => item.quantity * item.price < 0 || item.name.toLowerCase().includes("deposit")
  );

  const additionalCashPaidToday = invoiceData[0]?.additional_cash_paid_today || 0;

  return (
    <div className="container col-md-8">
      <table className="table table-striped table-responsive invoice-widget-table">
        <thead>
          <tr className="font-weight-bold">
            <th>ITEM #</th>
            <th>DESCRIPTION</th>
            <th>QTY</th>
            <th>UNIT PRICE</th>
            <th>TOTAL PRICE</th>
          </tr>
        </thead>
        <tbody>
          {productItems.map((item, index) => (
            <tr key={index}>
              <td>{item.serial_number}</td>
              <td>{item.name}</td>
              <td>{item.quantity}</td>
              <td>${Math.abs(item.price).toFixed(2)}</td>
              <td>${Math.abs(item.price * item.quantity).toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr className="font-weight-bold">
            <td colSpan="4" className="text-end invoice-heading">
              SubTotal
            </td>
            <td>${productItemsTotal.toFixed(2)}</td>
          </tr>
          {taxItems.map((item, index) => (
            <tr key={index}>
              <td colSpan="2">{item.name}</td>
              <td></td>
              <td>${Math.abs(item.price).toFixed(2)}</td>
              <td>${Math.abs(item.price * item.quantity).toFixed(2)}</td>
            </tr>
          ))}
          <tr className="font-weight-bold">
            <td colSpan="4" className="text-end invoice-heading">
              Invoice Total
            </td>
            <td>${totalPrice.toFixed(2)}</td>
          </tr>
          <tr className="font-weight-bold">
            <td colSpan="4" className="text-end invoice-heading">
              Cash Paid Today
            </td>
            <td>${additionalCashPaidToday.toFixed(2)}</td>
          </tr>
          {negativeTotalItems.map((item, index) => (
            <tr key={index}>
              <td colSpan="2">{item.name}</td>
              <td></td>
              <td>${Math.abs(item.price).toFixed(2)}</td>
              <td>${Math.abs(item.price * item.quantity).toFixed(2)}</td>
            </tr>
          ))}
          <tr className="font-weight-bold">
            <td colSpan="4" className="text-end invoice-heading">
              Cash Price
            </td>
            <td>${(cashPrice.toFixed(2) - additionalCashPaidToday.toFixed(2)).toFixed(2)}</td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
}

export default InvoiceWidget;
