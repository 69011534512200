import React from 'react';
import "./CardComponent.css"

const CardComponent = ({ children, className = '' }) => {
  return (
    <div className={`col-sm-8 col-lg-5 col-xl-4 col-11 card border ${className}`}>
      {children}
    </div>
  );
};

export default CardComponent;
