import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

import AuthBackground from '../ReusableComponents/AuthBackground/AuthBackground';
import CardComponent from '../ReusableComponents/CardComponent/CardComponent';
import logo from "../../../img/lendapi-logo.png";
import Spinner from '../ReusableComponents/Spinner/Spinner';
import Notification from '../ReusableComponents/Notification/Notification';
import "./LoginForm.css";
import { saveToSessionStorage, getFromSessionStorage, createDBRouterCookieForTestMode } from '../Utils/utils';


const getCookie = (name) => {
  const cookieValue = document.cookie
    .split('; ')
    .find(row => row.startsWith(name + '='))
    ?.split('=')[1];
  return cookieValue || '';
};


const LoginForm = () => {
  const navigate = useNavigate();
  const [redirectUrl, setRedirectUrl] = useState('');
  const [showEmailLogin, setShowEmailLogin] = useState(true);

  const [rememberMe, setRememberMe] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const { register, handleSubmit, setValue, formState: { errors } } = useForm();

  const location = useLocation();
  const { state } = location;

  // State for notification
  const [notification, setNotification] = useState({
  message: '',
  type: '',
  visible: false,
  });

  const [loading, setLoading] = useState(false);

  // Display notification
  const showNotification = (message, type) => {
    setNotification({ message, type, visible: true });
  };

  const closeNotification = () => {
    setNotification({ ...notification, visible: false });
  };

  // reset password error message
  useEffect(() => {
    if (state?.resetPasswordError) {
      showNotification(state.resetPasswordError, "error", true);
    }
  }, [state]);


  useEffect(() => {
    const savedUsername = getFromSessionStorage('rememberedUsername');
    if (savedUsername) {
      setValue('username', savedUsername);
      setRememberMe(true);
    }
  }, [setValue]);


  const onSubmit = async (data) => {
    setLoading(true);
    createDBRouterCookieForTestMode(false);
    try {
      const csrfToken = getCookie('csrftoken');

      const config = {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken,
        },
        withCredentials: true,
      };

      const response = await axios.post('/signup/v1/login/', {
        username: data.username,
        password: data.password,
      }, config);


      const { code, message, status, next, session_id, session, masked_mobile } = response.data;

      if (code === 100) {
        saveToSessionStorage("masked_mobile", masked_mobile);
        setRedirectUrl('/signup/v1/verify_2fa_otp');
      } else if (code === 1) {
        setRedirectUrl('/tenant/applications');
      } else if (code === 2) {
        setRedirectUrl('/manager/tenants');
      } else if (code === 10) {
        setRedirectUrl(`/applicant/dashboard/${session}`);
      } else if (code === 12) {
        setRedirectUrl(`/application/wizard/${session}`);
      } else if (code === 13) {
        setRedirectUrl(`/application/loan_wizard/${session}`);
      } else if (code === 14) {
        setRedirectUrl(`/business_app/wizard/${session}`);
      } else if (code === 15) {
        setRedirectUrl('/applicant/application_list');
      } else if (code === 11) {
        setRedirectUrl(next);
      } else {
        showNotification(message || 'An error occurred.', 'error');
      }

      saveToSessionStorage('email', data.username);

      if(status === "success") {
        showNotification('Login successful.', "success");
      }

      if (rememberMe) {
        saveToSessionStorage('rememberedUsername', data.username);
      } else {
        sessionStorage.removeItem('rememberedUsername');
      }

    } catch (error) {
      if (error.response && error.response.status === 401) {
        showNotification('Invalid credentials, Please check your credentials.', "error");
      } else {
        showNotification(error.response.data.message, "error");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (redirectUrl) {
      window.location.href = redirectUrl;
    }
  }, [redirectUrl]);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <AuthBackground>
      <div className="w-100 vh-100 d-flex justify-content-center align-items-center">
        <CardComponent>
          <div className="login-card d-flex justify-content-center align-items-center flex-column text-center bg-white gap-2 card-header">
            <div className="img">
              <img src={logo} alt="LendAPI Logo" />
            </div>
            <h3 className="fw-bold main-title mb-0">
              Welcome to LendAPI
            </h3>
            <p className="sub-heading fw-normal mb-0" style={{ opacity: 0.8, fontSize: "0.95rem" }}>
              Good to see you back, login to access your account
            </p>
          </div>

          <div className="">
            <div className="card-body">
              {/* Conditionally Render Form */}
              {showEmailLogin ? (
                <form
                  role="form"
                  className="text-start text-dark"
                  id="form-login"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  {/* Email Input */}
                  <div className="input-group mb-4">
                    <label>Email <span>*</span></label>
                    <input
                      type="email"
                      className={`form-control p-2 border rounded ${(errors.username) ? 'is-invalid' : ''
                        }`}
                      placeholder="Email"
                      aria-label="Email"
                      {...register('username', { required: 'Email is required' })}
                    />
                    {errors.username && (
                      <div id="email-error" style={{ color: '#d83e37' }}>
                        {errors.username.message}
                      </div>
                    )}
                  </div>

                  {/* Password Input */}
                  <div className="input-group mb-2">
                    <p className="w-100 mb-0">
                      <label>Password <span>*</span></label>
                      <input
                        type={isPasswordVisible ? 'text' : 'password'}
                        className={`form-control p-2 border ${(errors.password) ? 'is-invalid' : ''
                          }`}
                        placeholder="Password"
                        aria-label="Password"
                        {...register('password', {
                          required: 'Password is required',
                        })}
                      />
                      <i toggle="#com-pass"
                        className={`fa ${isPasswordVisible ? 'fa-eye-slash' : 'fa-eye'} field-icon toggle-password`}
                        onClick={togglePasswordVisibility}
                        style={{ opacity: `${isPasswordVisible ? "0.7" : "0.9"}`, top: "60%", position: "obsolute", right: "12px", transform: "trasnlateY(-50%)", cursor: "pointer" }}
                      ></i>
                    </p>
                    {errors.password && (
                      <div id="password-error" style={{ color: '#d83e37' }}>
                        {errors.password.message}
                      </div>
                    )}
                  </div>

                  {/* Remember Me Checkbox */}
                  <div className="text-center mt-lg-3">
                    <div className="d-flex align-items-center mt-4 justify-content-between">
                      <div className="form-check">
                        <input
                          className="form-check-input login-form-check-input"
                          type="checkbox"
                          id="rememberMe"
                          checked={rememberMe}
                          onChange={() => setRememberMe(!rememberMe)}
                        />
                        <label
                          className="form-check-label mb-0 ms-1"
                          htmlFor="rememberMe"
                        >
                          Remember me
                        </label>
                      </div>
                    </div>

                    {/* Submit Button */}
                    <button
                      id="sign_in_btn"
                      type="submit"
                      className="btn btn-primary w-100 my-4 mb-3 text-capitalize"
                    >
                      Sign in
                    </button>
                  </div>

                  {/* Forgot Password Link */}
                  <div className="mt-2">
                    <p className="text-sm fw-normal text-center mb-0">
                      Forgot Password?
                      <a href="/signup/v1/forgot_password" className="text-info ms-1">
                        Reset here
                      </a>
                    </p>
                  </div>
                </form>
              ) : (
                <div className='d-flex justify-content-center align-items-center flex-column gap-2'>
                  <button
                    id="signin_email_btn"
                    type="button"
                    className="btn btn-secondary w-100 text-capitalize"
                  >
                    Sign in with Email
                  </button>
                </div>
              )}
            </div>
          </div>
        </CardComponent>
      </div>

      {loading && (
        <div className="d-flex justify-content-center align-items-center spinner-wrapper">
          <Spinner animation="border" />
        </div>
      )}

      <Notification
        message={notification.message}
        type={notification.type}
        visible={notification.visible}
        onClose={closeNotification}
      />

    </AuthBackground>
  );
};

export default LoginForm;
