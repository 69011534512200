import React, { useState } from 'react';
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import Spinner from '../ReusableComponents/Spinner/Spinner';

import './PaymentCardForm.css';
import { saveToSessionStorage, getFromSessionStorage} from '../Utils/utils';

const cardStyle = {
  base: {
    iconColor: '#c4f0ff',
    color: '#0F0F0F',
    fontWeight: '500',
    fontFamily: 'Open Sauce Sans, Open Sans, Segoe UI, sans-serif',
    fontSize: '16px',
    fontSmoothing: 'antialiased',
    '::placeholder': {
      color: '#4B55633D',
    },
  },
  focus: {
    borderColor: 'rgba(111, 75, 209, 1)',
    boxShadow: '0 0 0 3px rgba(111, 75, 209, 0.3)',
  },
  invalid: {
    iconColor: '#df1b41',
    color: '#df1b41',
    '::placeholder': {
      color: '#df1b41',
    },
  },
};

const PaymentCardForm = ({ price_id, plan, toggleAnualPayValue, onSuccess, onError }) => {
  const stripe = useStripe();
  const elements = useElements();

  const { register, handleSubmit, formState: { errors }, watch } = useForm({ mode: "onChange" });
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);

  const session_id = getFromSessionStorage('session_id');
  const customer_id = getFromSessionStorage('customer_id');

  const [cardErrors, setCardErrors] = useState({
    cardNumber: '',
    cardExpiry: '',
    cardCvc: '',
  });

  // Card holder name validation states
  const [cardHolderNameValidation, setCardHolderNameValidation] = useState({
    lengthValid: false,
    lettersValid: false,
  });

  // zip code validation states
  const [zipCodeValidation, setZipCodeValidation] = useState({
    lengthValid: false,
  });


  const [isCardNumberFocused, setCardNumberFocused] = useState(false);
  const [isCardExpiryFocused, setCardExpiryFocused] = useState(false);
  const [isCardCvcFocused, setCardCvcFocused] = useState(false);


  // Handle card holder name validation
  const handleCardHolderNameChange = (cardHolderName) => {
    const lengthValid = cardHolderName.replace(/\s+/g, "").length >= 3;
    const lettersValid = /^[A-Za-z]+(\s[A-Za-z]+)*$/.test(cardHolderName);

    setCardHolderNameValidation({
      lengthValid,
      lettersValid,
    });
  };

  // Handle zip code validation
  const handleZipCodeChange = (zipCode) => {
    const lengthValid = zipCode.replace(/\s+/g, "").length === 5;

    setZipCodeValidation({
      lengthValid,
    });
  };


  // Handlers for focus and blur
  const handleFocus = (field) => {
    if (field === 'cardNumber') {
      setCardNumberFocused(true);
    } else if (field === 'cardExpiry') {
      setCardExpiryFocused(true);
    } else if (field === 'cardCvc') {
      setCardCvcFocused(true);
    }
  };

  const handleBlur = (field) => {
    if (field === 'cardNumber') {
      setCardNumberFocused(false);
    } else if (field === 'cardExpiry') {
      setCardExpiryFocused(false);
    } else if (field === 'cardCvc') {
      setCardCvcFocused(false);
    }
  };

  const handleCardChange = (event, field) => {
    if (event.error) {
      setCardErrors((prev) => ({
        ...prev,
        [field]: event.error.message,
      }));
    } else {
      setCardErrors((prev) => ({
        ...prev,
        [field]: '',
      }));
    }
  };


  const onSubmit = async (data) => {
    setIsProcessing(true);
    setError(null);

    try {
      const cardElement = elements.getElement(CardNumberElement);

      if (!cardElement) {
        onError && onError('Card element is not available');
        return;
      }

      // Create subscription
      const { data: subscriptionData } = await axios.post(
        '/signup/v1/create_subscription/',
        {
          session_id,
          customer_id,
          plan,
          duration: toggleAnualPayValue ? "annually" : "monthly",
        },
        { headers: { 'Content-Type': 'application/json' } }
      );

      const { subscriptionId, clientSecret } = subscriptionData;

      // Confirm payment
      const { error: confirmError, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: data.cardHolderName,
            address: {
              postal_code: data.zipCode,
            },
          },
        },
      });

      if (confirmError) {
        onError && onError(confirmError.message || 'Payment confirmation failed.');
        return;
      }

      if (paymentIntent.status === 'succeeded') {
        try {
          const checkSubsriptionResponse = await axios.post('/signup/v1/check_subscription/', {
            session_id,
            paymentIntentId: paymentIntent.id
          }, {
            headers: {
              'Content-Type': 'application/json',
            },
          });

          if(checkSubsriptionResponse.data.code === 1 && checkSubsriptionResponse.data.status === "success") {
            saveToSessionStorage('checkSubsriptionResponse', checkSubsriptionResponse.data.status);
          } else {
            saveToSessionStorage('checkSubsriptionResponse', checkSubsriptionResponse.data.status);
          }
        } catch (error) {
          if (error.response && error.response.data) {
            saveToSessionStorage('checkSubsriptionResponse', error.response.data.status);
          }
          else {
            saveToSessionStorage('checkSubsriptionResponse', "failed");
          }
        }
        onSuccess && onSuccess();
      } else {
        onError && onError('Payment failed. Please try again.');
      }
    } catch (error) {
      setError(error.message);
      onError && onError(error.message);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="text-start" id="form-payment">
      {/* Card Details Section */}
      <div className="input-group mb-2">
        <label>Card Details</label>
        <div className="input-group mb-2">
          <div className={`form-control ${cardErrors.cardNumber ? 'error' : ''} ${isCardNumberFocused ? 'focused' : ''}`}>
            <CardNumberElement
              options={{ style: cardStyle }}
              onFocus={() => handleFocus('cardNumber')}
              onBlur={() => handleBlur('cardNumber')}
              onChange={(e) => handleCardChange(e, 'cardNumber')}
            />
          </div>
          {cardErrors.cardNumber && <small className="text-danger">{cardErrors.cardNumber}</small>}
        </div>

        <div className="w-100 d-flex gap-3">
          <div className="input-group" style={{ flex: '1 1 50%', marginBottom: "1rem" }}>
            <div className={`form-control ${cardErrors.cardExpiry ? 'error' : ''} ${isCardExpiryFocused ? 'focused' : ''}`}>
              <CardExpiryElement
                options={{ style: cardStyle }}
                onFocus={() => handleFocus('cardExpiry')}
                onBlur={() => handleBlur('cardExpiry')}
                onChange={(e) => handleCardChange(e, 'cardExpiry')}
              />
            </div>
            {cardErrors.cardExpiry && <small className="text-danger">{cardErrors.cardExpiry}</small>}
          </div>

          <div className="input-group" style={{ flex: '1 1 50%', marginBottom: "1rem" }}>
            <div className={`form-control ${cardErrors.cardCvc ? 'error' : ''} ${isCardCvcFocused ? 'focused' : ''}`}>
              <CardCvcElement
                options={{ style: cardStyle }}
                onFocus={() => handleFocus('cardCvc')}
                onBlur={() => handleBlur('cardCvc')}
                onChange={(e) => handleCardChange(e, 'cardCvc')}
              />
            </div>
            {cardErrors.cardCvc && <small className="text-danger">{cardErrors.cardCvc}</small>}
          </div>
        </div>
      </div>

      {/* Address Section */}
      <div className="input-group mb-0">
        <label className='ms-0'>Cardholder Name</label>
        <input
          type="text"
          className={`form-control ${errors.cardHolderName ? 'is-invalid' : ''}`}
          placeholder="Enter cardholder name"
          {...register('cardHolderName', {
            required: 'Cardholder name is required',
            pattern: {
              value: /^[A-Za-z]+(\s[A-Za-z]+)*$/,
              message: 'Invalid cardholder name. Only letters and spaces are allowed.',
            },
            minLength: {
              value: 3,
              message: 'Cardholder name must be at least 3 characters long.',
            },
            onChange: (e) => handleCardHolderNameChange(e.target.value),
          })}
        />
        {/* {errors.cardHolderName && <small className="text-danger">{errors.cardHolderName.message}</small>} */}
      </div>

      {/* Helper Texts Below Card holder name */}
      <div className="password-help-texts mb-4">
        <p
          className={`help-text mb-0 ${
            (cardHolderNameValidation.lengthValid &&
            cardHolderNameValidation.lettersValid)
              ? 'valid'
              : 'invalid'
          }`}
        >
          At least 3 characters, only letters and spaces allowed.
        </p>
      </div>



      <div className="input-group mb-0">
        <label className='ms-0'>ZIP Code</label>
        <input
          type="text"
          className={`form-control ${errors.zipCode ? 'is-invalid' : ''}`}
          placeholder="Enter ZIP code"
          {...register('zipCode', {
            required: 'ZIP Code is required',
            pattern: {
              value: /^[0-9]{5}(-[0-9]{4})?$/,
              message: 'Invalid ZIP Code',
            },
            onChange: (e) => handleZipCodeChange(e.target.value),
          })}
          onInput={(e) => {
            e.target.value = e.target.value.replace(/\D/g, '');
          }}
        />
        {/* {errors.zipCode && <small className="text-danger">{errors.zipCode.message}</small>} */}
      </div>

      {/* Helper Texts Below Zip Code */}
      <div className="password-help-texts mb-3">
        <p
          className={`help-text mb-0 ${
            zipCodeValidation.lengthValid
              ? 'valid'
              : 'invalid'
          }`}
        >
          ZIP Code should be 5 digits long.
        </p>
      </div>

      {/* Submit Button */}
      <button className='btn btn-primary w-100 mt-3' type="submit" disabled={!stripe || isProcessing}>
        {isProcessing ? 'Processing...' : 'Confirm'}
      </button>
      <p className='text-center py-2' style={{fontSize: "0.7rem"}}>By confirming your subscription, you allow LendAPI to charge you for future payments in accordance with their terms.</p>

      {isProcessing && (
        <div className="d-flex justify-content-center align-items-center spinner-wrapper">
          <Spinner animation="border" />
        </div>
      )}

      {/* Error Message */}
      {/* {error && <p style={{ color: 'red' }}>{error}</p>} */}
    </form>
  );
};

export default PaymentCardForm;
