import React, { useState, useCallback, useRef, useMemo, useEffect } from 'react';
import JoditEditor from 'jodit-react';
import Select from 'react-select';
// import Modal from 'react-bootstrap/Modal';
import CustomModal from "./components/customModal";

export const RichTextEditor = ({ editorContent, id, onChange }) => {
    const editor = useRef(null);
    const [content, setContent] = useState(editorContent ? editorContent : '');
	const [selectedVariable, setSelectedVariable] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const closeModal = () => setIsModalOpen(false);
    const variableOptions = window.consent_variables.map(({ id, engine_name }) => ({
        value: id,
        label: engine_name,
    }));

    // Define the buttons including your custom button
    const options = [
        'bold',
        'italic',
        '|',
        'ul',
        'ol',
        '|',
        'font',
        'fontsize',
        'lineHeight',
        '|',
        'outdent',
        'indent',
        'align',
        '|',
        'hr',
        '|',
        'fullsize',
        'brush',
        '|',
        'table',
        'link',
        '|',
        'undo',
        'redo',
        '|',
		{	
            name: "addVariableBtn",
			text: 'Add Variable', // The text on the button
            tooltip: 'Insert variable', // Tooltip text
			exec: function (editor) {
                setIsModalOpen(prevState => !prevState);
			}
		}
    ];

    const config = useMemo(
        () => ({
            readonly: false,
            placeholder: '',
            defaultActionOnPaste: 'insert_as_html',
            defaultLineHeight: 1.5,
            enter: 'div',
            buttons: options,
            buttonsMD: options,
            buttonsSM: options,
            buttonsXS: options,
            statusbar: false,
            height: 300,
            sizeLG: 900,
            sizeMD: 700,
            sizeSM: 400,
            toolbarAdaptive: false,
            readonly: false, // all options from https://xdsoft.net/jodit/doc/
      events: 
           { 
            afterInit: (instance) => { editor.jodit = instance; } 

}
        }),
        [],
    );

    const handleTextChange = (newContent) => {
        console.log(content, newContent)
        if(content != newContent) {
            setContent(newContent);
            if (onChange) {
                onChange(newContent, id); // Send the content back to the parent
            }
        }
    };

	const buttonClick = (event) => { 
        editor.jodit.selection.insertHTML(`<span>{{${selectedVariable ? selectedVariable.label : ""}}}</span>`);
        closeModal()
    };


    return (
		<>
			<JoditEditor
				ref={editor}
				value={content}
				config={config}
				tabIndex={1} // tabIndex of textarea
				 // Update content on blur
				onChange={handleTextChange}
			/>

            <CustomModal isOpen={isModalOpen} onClose={closeModal}>
                <div className="">
                    <div className="">
                        <h5 className="modal-title">Add Variable</h5>
                    </div>
                    <div className="">
                        <div className="input-group mt-4 row provider-select">
                            <label htmlFor="documents" className="mb-2" style={{display: "block"}}>
                                Variables
                            </label>
                            {/* Select dropdown with search */}
                            <Select
                                options={variableOptions}
                                onChange={(selectedOption) => setSelectedVariable(selectedOption)}
                                id='react-select-id'
                                placeholder="Search and select a variable"
                                isClearable
                                isSearchable
                                className='react-select'
                                defaultValue={selectedVariable ? selectedVariable.label : null}
                            />
                        </div>
                    </div>
                    <div className="modal-footer mt-5">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            data-bs-dismiss="modal"
                            onClick={() => closeModal()}
                        >
                            Close
                        </button>
                        <button type="button" className="btn btn-primary" onClick={(event) => buttonClick(event)}>
                            Add Variable
                        </button>
                    </div>
                </div>
            </CustomModal>
			
		</>

    );
};
