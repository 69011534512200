import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';

import AuthBackground from '../ReusableComponents/AuthBackground/AuthBackground';
import CardComponent from '../ReusableComponents/CardComponent/CardComponent';
import IdCard from '../ReusableComponents/IdCard/IdCard';
import Spinner from '../ReusableComponents/Spinner/Spinner';
import Notification from '../ReusableComponents/Notification/Notification';
import './SignupForm.css';
import { saveToSessionStorage, getFromSessionStorage} from '../Utils/utils';

import bgSignup from "../../../img/bg-signup.svg";

const getCookie = (name) => {
  const cookieValue = document.cookie
    .split('; ')
    .find(row => row.startsWith(name + '='))
    ?.split('=')[1];
  return cookieValue || '';
};

const SignupForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    company_name: '',
    first_name: '',
    last_name: '',
    email: '',
    mobile: '',
  });

  const { register, handleSubmit, formState: { errors }, setValue } = useForm();

  // State for notification
  const [notification, setNotification] = useState({
    message: '',
    type: '',
    visible: false,
  });

  const [loading, setLoading] = useState(false);

  const [termsAccepted, setTermsAccepted] = useState(false);

  // Handle checkbox change
  const handleCheckboxChange = (e) => {
    setTermsAccepted(e.target.checked);
  };


  // Display notification
  const showNotification = (message, type) => {
    setNotification({ message, type, visible: true });
  };

  const closeNotification = () => {
    setNotification({ ...notification, visible: false });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setValue(name, value, { shouldValidate: true });
  };


  useEffect(() => {
    if (formData.first_name && formData.last_name && formData.company_name && formData.email && formData.mobile) {
      // Store the form data in sessionStorage except the password
      saveToSessionStorage('signupFormData', {
        first_name: formData.first_name,
        last_name: formData.last_name,
        company_name: formData.company_name,
        email: formData.email,
        mobile: formData.mobile,
      });
    }
  }, [formData]);


  // Function to handle form submission
  const onSubmit = async (data) => {
    setLoading(true);
    try {
      // Manual client-side validation using react-hook-form errors
      const csrfToken = getCookie('csrftoken');
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken,
        },
        withCredentials: true,
      };

      // Add necessary data to FormData
      const formData = {
        first_name: data.first_name,
        last_name: data.last_name,
        company_name: data.company_name,
        email: data.email,
        mobile: data.mobile,
      };

      // Submit the form data using axios
      const response = await axios.post('/signup/v1/signup/', formData,
        config
      );

      // Handle response
      if (response.status === 201) {
        showNotification(response.data.message, "success");
        saveToSessionStorage('session_id', response.data.session_id);
        navigate('/email_otp');
      }
    } catch (error) {
      if (error.response.status === 400) {
        showNotification('Email exists. Try using a different email.', 'error');
      } else {
        showNotification('API request failed. Please try again.', 'error');
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const autofillFields = document.querySelectorAll('input');
    autofillFields.forEach((field) => {
      if (field.value) {
        setValue(field.name, field.value, { shouldValidate: true });
      }
    });
  }, [setValue]);

  // Populate form data from sessionStorage when component mounts
  // useEffect(() => {
  //   const savedFormData = getFromSessionStorage('signupFormData');
  //   if (savedFormData) {
  //     const parsedData = savedFormData;
  //     setFormData(parsedData);
  //     setValue('first_name', parsedData.first_name);
  //     setValue('last_name', parsedData.last_name);
  //     setValue('company_name', parsedData.company_name);
  //     setValue('email', parsedData.email);
  //     setValue('mobile', parsedData.mobile);
  //   }
  // }, [setValue]);

  useEffect(() => {
    const savedFormData = getFromSessionStorage('signupFormData');
    if (savedFormData) {
      Object.entries(savedFormData).forEach(([key, value]) => {
        setValue(key, value, { shouldValidate: true });
      });
      setFormData(savedFormData);
    }
  }, [setValue]);


  return (
    <AuthBackground>
      <div className="main-content min-vh-100 d-flex align-items-center">
        <div className="container-fluid">
          <div className="d-flex flex-wrap justify-content-evenly">

            {/* Id Card */}
            <div className="d-flex justify-content-center align-items-center col-sm-8 col-lg-5 col-xl-4 col-11">
              <IdCard formData={formData} />
            </div>

            {/* Form Card - Signup Form */}
            <CardComponent className="mt-0 form-card">
              <div className="col-12">
                <div className="d-flex justify-content-center align-items-center flex-column text-center bg-white gap-2 card-header">
                  <h3 className="main-title text-center mb-0">Create your account</h3>
                  <p className="sub-heading text-center mb-0 fw-normal" style={{ opacity: '0.8' }}>
                    Get started with your business details
                  </p>
                </div>
                <div className="">
                  <div className="card-body">
                    <form onSubmit={handleSubmit(onSubmit)} className="text-start" id="form-signup">
                      {/* Form Fields */}
                      {/* First Name */}
                      <div className="input-group mb-4">
                        <label>First Name <span>*</span></label>
                        <input
                          type="text"
                          maxLength="15"
                          className={`form-control p-2 border rounded ${(errors.first_name) ? 'is-invalid' : ''}`}
                          placeholder="First name"
                          {...register('first_name', { required: 'First name is required', maxLength: 15 })}
                          onChange={handleInputChange}
                        />
                        {errors.first_name && <p className="text-danger">{errors.first_name.message}</p>}
                      </div>
                      {/* Last Name */}
                      <div className="input-group mb-4">
                        <label>Last Name <span>*</span></label>
                        <input
                          type="text"
                          maxLength="10"
                          className={`form-control p-2 border rounded ${(errors.last_name) ? 'is-invalid' : ''}`}
                          placeholder="Last name"
                          {...register('last_name', { required: 'Last name is required', maxLength: 10 })}
                          onChange={handleInputChange}
                        />
                        {errors.last_name && <p className="text-danger">{errors.last_name.message}</p>}
                      </div>
                      {/* Company Name */}
                      <div className="input-group mb-4">
                        <label>Company Name <span>*</span></label>
                        <input
                          type="text"
                          className={`form-control p-2 border rounded ${(errors.company_name) ? 'is-invalid' : ''}`}
                          placeholder="Company name"
                          {...register('company_name', { required: 'Company name is required', maxLength: 60 })}
                          onChange={handleInputChange}
                        />
                        {errors.company_name && <p className="text-danger">{errors.company_name.message}</p>}
                      </div>
                      {/* Email */}
                      <div className="input-group mb-4">
                        <label>Company Email <span>*</span></label>
                        <input
                          type="email"
                          className={`form-control p-2 border rounded ${(errors.email) ? 'is-invalid' : ''}`}
                          placeholder="Email"
                          {...register('email', {
                            required: 'Email is required',
                            pattern: {
                              value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                              message: 'Enter a valid company email (e.g., example@lendapi.com)',
                            },
                          })}
                          onChange={handleInputChange}
                        />
                        {errors.email && <p className="text-danger">{errors.email.message}</p>}
                      </div>
                      {/* Mobile */}
                      <div className="input-group mb-4">
                        <label>Mobile <span>*</span></label>
                        <input
                          id="mobile_id"
                          type="text"
                          className={`form-control p-2 border rounded ${(errors.mobile) ? 'is-invalid' : ''}`}
                          placeholder="Mobile number"
                          {...register('mobile',
                          { required: 'Mobile number is required',
                          pattern: {
                            value: /^[0-9]{10}$/,
                            message: 'Invalid mobile number, must be 10 digits.',
                          },
                          maxLength: {
                            value: 10,
                            message: 'Mobile number cannot exceed 10 digits.',
                          }, })}
                          onChange={handleInputChange}
                        />
                        {errors.mobile && <p className="text-danger">{errors.mobile.message}</p>}
                      </div>

                      {/* Terms and Conditions */}
                      <div className="d-flex flex-column flex-wrap gap-1 align-items-center justify-content-center w-100">
                        <div className="form-check d-flex align-items-center justify-content-center">
                          <input
                            className="form-check-input"
                            checked={termsAccepted}
                            type="checkbox"
                            id="terms-checkbox"
                            {...register('terms', { required: 'You must agree to the terms and conditions' })}
                            onChange={handleCheckboxChange}
                          />
                          <label className="form-check-label ms-2 mb-0" htmlFor="terms-checkbox">
                            I agree to the <a href="https://lendapi.com/terms-of-service" target="_blank">Terms of Service</a> and <a href="https://lendapi.com/privacy-notice" target="_blank">Privacy Notice</a>
                          </label>
                        </div>
                        {errors.terms && <p className="text-danger">{errors.terms.message}</p>}
                      </div>
                      {/* Submit Button */}
                      <button type="submit" disabled={loading} className="btn btn-primary btn-md btn-block my-3 w-100 mt-4" id="submit">
                        {loading ? "Creating Account..." : "Create Account"}
                      </button>

                      <div className="mt-2">
                        <p className="text-sm fw-normal text-center mb-0">
                          Already have an account?
                          <Link to="/login" className="text-info ms-1">
                            Sign in
                          </Link>
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </CardComponent>
          </div>
        </div>
      </div>

      {loading && (
        <div className="d-flex justify-content-center align-items-center spinner-wrapper">
          <Spinner animation="border" />
        </div>
      )}

      <Notification
        message={notification.message}
        type={notification.type}
        visible={notification.visible}
        onClose={closeNotification}
      />
    </AuthBackground>
  );
};

export default SignupForm;
