import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import AuthBackground from '../ReusableComponents/AuthBackground/AuthBackground';
import CardComponent from '../ReusableComponents/CardComponent/CardComponent';
import IdCard from '../ReusableComponents/IdCard/IdCard';
import './MobileVerificationOTPForm.css';
import { Link, Navigate, useNavigate, useLocation } from 'react-router-dom';

import Spinner from '../ReusableComponents/Spinner/Spinner';
import Notification from '../ReusableComponents/Notification/Notification';
import { saveToSessionStorage, getFromSessionStorage } from '../Utils/utils';

const getCookie = (name) => {
  const cookieValue = document.cookie
    .split('; ')
    .find(row => row.startsWith(name + '='))
    ?.split('=')[1];
  return cookieValue || '';
};

const MobileVerificationOTPForm = () => {
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [otp, setOtp] = useState(Array(6).fill(''));
  const [maskedMobileNumber, setMaskedMobileNumber] = useState('(999) ***-**00');
  const [signupFormData, setSignupFormData] = useState(null);
  const [isChangingNumber, setIsChangingNumber] = useState(false);
  const [newMobile, setNewMobile] = useState('');
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState({
    message: '',
    type: '',
    visible: false,
  });

  const location = useLocation();
  const { state } = location;

  // Function to mask mobile number
  const maskMobileNumber = (mobile) => {
    const masked = mobile.replace(/(\d{3})-?\d{3}-?(\d{3})/, '($1) ***-****');
    return masked;
  };


  // mobileOtpResponse message
  useEffect(() => {
    if (state?.message) {
      showNotification(state.message, state.messageType);
    }
  }, [state]);

  // Set masked mobile number when signupFormData is available
  // useEffect(() => {
  //   if (signupFormData?.mobile) {
  //     setMaskedMobileNumber(maskMobileNumber(signupFormData.mobile));
  //   }
  // }, [signupFormData]);

  useEffect(() => {
    const newMobile = getFromSessionStorage("newMobile")
    console.log("newMobile", newMobile)
    if (newMobile) {
      setMaskedMobileNumber(maskMobileNumber(newMobile));
    } else if(signupFormData?.mobile) {
      setMaskedMobileNumber(maskMobileNumber(signupFormData.mobile));
    }
  }, [newMobile, signupFormData]);

  // Create references for each input to handle focus changes
  const inputRefs = useRef([]);

  const [mobileOtpResendCount, setMobileOtpResendCount] = useState(0);

  // Display notification
  const showNotification = (message, type) => {
    setNotification({ message, type, visible: true });
  };

  const closeNotification = () => {
    setNotification({ ...notification, visible: false });
  };

  const handleChangeNumber = () => {
    setIsChangingNumber(!isChangingNumber);
  };

  const handleMobileChange = (e) => {
    setNewMobile(e.target.value);
  };

  const updatePhoneNumber = async () => {
    setLoading(true);
    try {
      const csrfToken = getCookie('csrftoken');
      const sessionId = getFromSessionStorage('session_id');

      const response = await axios.post(
        '/signup/v1/update_phone_number/',
        {
          session_id: sessionId,
          mobile: newMobile,
        },
        {
          headers: {
            'X-CSRFToken': csrfToken,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.data.code === 1) {
        showNotification('Mobile number updated successfully.', 'success');
        saveToSessionStorage("newMobile", newMobile);
        setMaskedMobileNumber(maskMobileNumber(newMobile));
        setIsChangingNumber(false);

        const mobileOtpData = {
          session_id: sessionId
        };

        try {
          const mobileOtpResponse = await axios.post('/signup/v1/signup_mobile_otp/', mobileOtpData, {
            headers: {
              'X-CSRFToken': csrfToken,
              'Content-Type': 'application/json',
            },
          });

          if (mobileOtpResponse.data.status === "success") {
            showNotification(mobileOtpResponse.data.message, "success")
          } else {
            showNotification(mobileOtpResponse.data.message, "error")
          }
        } catch (mobileOtpError) {
            showNotification(mobileOtpError.response.data.message, "error")
        }

      } else {
        showNotification(response.data.message, 'error');
      }
    } catch (error) {
      showNotification('Failed to update mobile number. Please try again.', 'error');
    } finally {
      setLoading(false);
    }
  };

  // Get signup form data from sessionStorage
  useEffect(() => {
    const savedFormData = getFromSessionStorage('signupFormData');
    if (savedFormData) {
      const parsedData = savedFormData;
      setSignupFormData(parsedData);
    }
  }, []);

  useEffect(() => {
    const storedCount = getFromSessionStorage('mobileOtpResendCount');
    if (storedCount) {
      setMobileOtpResendCount(parseInt(storedCount, 10));
    }
  }, []);


  const handleOtpChange = (e, index) => {
    const value = e.target.value.replace(/[^0-9]/g, '');
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Focus the next input if available and input is not empty
    if (value && index < otp.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && index > 0 && otp[index] === '') {
      inputRefs.current[index - 1].focus();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pasteData = (e.clipboardData || window.clipboardData).getData('text');
    const digits = pasteData.replace(/[^0-9]/g, '').split('').slice(0, otp.length);

    if (digits.length) {
      const newOtp = [...otp];
      digits.forEach((digit, idx) => {
        newOtp[idx] = digit;
      });

      setOtp(newOtp);

      // Focus the last digit that was filled
      inputRefs.current[Math.min(digits.length - 1, otp.length - 1)].focus();
    }
  };

  // Handle form submission
  const onSubmit = async () => {
    const otpCode = otp.join('');
    if (otpCode.length === 6) {
      setLoading(true);
      try {
        const csrfToken = getCookie('csrftoken');
        const sessionId = getFromSessionStorage('session_id');

        const data = {
          otp: otpCode,
          session_id: sessionId
        };

        const response = await axios.post('/signup/v1/verify_signup_mobile/', data, {
          headers: {
            'X-CSRFToken': csrfToken,
            'Content-Type': 'application/json'
          }
        });

        // Check if the response has the expected structure
        if (response && response.data && response.data.code === 1) {
          navigate('/signup/v1/set_password/');
          showNotification(response.data.message, 'success');
        } else {
          showNotification(response.data.message || 'Unexpected error occurred.', 'error');
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          const errorCode = error.response.data.code;
          if (errorCode === -3) {
            showNotification('Please enter the correct mobile OTP code.', 'error');
          } else {
            showNotification(error.response.data.message || 'Error verifying OTP.', 'error');
          }
        } else {
          showNotification('An unexpected error occurred. Please try again.', 'error');
        }
      } finally {
        setLoading(false);
      }
    } else {
      showNotification('Please enter the correct mobile OTP code.', "error");
    }
  };


  // Handle OTP resend
  const resendOtp = async (e) => {
    e.preventDefault();
    try {
      const csrfToken = getCookie('csrftoken');
      const sessionId = getFromSessionStorage('session_id');

      const signupData = {
        session_id: sessionId,
        mobile_otp_resend_count: mobileOtpResendCount,
      };

      const response = await axios.post('/signup/v1/resend_mobile_otp/', signupData, {
        headers: {
          'X-CSRFToken': csrfToken,
          'Content-Type': 'application/json',
        },
      });

      if (response.data.code === 1) {
        setMobileOtpResendCount(response.data.mobile_otp_resend_count);
        saveToSessionStorage('mobileOtpResendCount', response.data.mobile_otp_resend_count);
        showNotification(response.data.message, 'success');
      } else {
        showNotification(response.data.message, 'error');
      }
    } catch (error) {
      if (error.response && error.response.data) {
        showNotification(error.response.data.message, 'error');
      } else {
        showNotification('Error verifying OTP.', 'error');
      }
    }
  };

  return (
    <AuthBackground>
      <div className="main-content min-vh-100 d-flex align-items-center">
        <div className="container-fluid">
          <div className="d-flex flex-wrap justify-content-evenly">
            {/* Id Card */}
            <div className="d-flex justify-content-center align-items-center col-sm-8 col-lg-5 col-xl-4 col-11">
              <IdCard formData={signupFormData} />
            </div>

            {/* Form Card - Mobile Verification */}
            <CardComponent className="mt-0 form-card">
              <div className="col-12">
                <div className="d-flex justify-content-center align-items-center flex-column bg-white gap-2 card-header">
                  <h3 className="main-title text-center mb-0">Mobile Verification</h3>
                  <p className="sub-heading text-center mb-0 fw-normal" style={{ opacity: '0.8', fontSize: "0.9rem" }}>Quick verification to keep your account secure</p>
                </div>
                <div className="">
                  <div className="card-body">
                  {!isChangingNumber ? (
                    <form className="text-start text-dark" onSubmit={handleSubmit(onSubmit)}>
                      <>
                        <div className="input-group my-4 flex-column">
                          <div className="col-12 d-flex justify-content-between">
                            <p className="mb-0" style={{ fontSize: "0.9rem" }}>Enter the otp sent on {maskedMobileNumber}</p>
                          </div>
                          <div className="col-12 otp-inputs d-flex justify-content-between my-2">
                            {otp.map((digit, index) => (
                              <input
                                key={index}
                                ref={(el) => (inputRefs.current[index] = el)}
                                type="text"
                                inputMode="numeric"
                                className="otp-input form-control mx-1 p-2 text-center"
                                id={`digit${index + 1}`}
                                value={digit}
                                onChange={(e) => handleOtpChange(e, index)}
                                onKeyDown={(e) => handleKeyDown(e, index)}
                                onPaste={handlePaste}
                                aria-label="OTP input"
                                maxLength="1"
                                min="0"
                                max="9"
                              />
                            ))}
                          </div>
                          <div className="col-12 d-flex justify-content-between">
                            <a href="#">
                              <p className="mb-0" style={{ fontSize: "0.9rem" }} onClick={resendOtp}>Resend</p>
                            </a>
                            <a href="#">
                              <p className="mb-0" style={{ fontSize: "0.9rem" }} onClick={handleChangeNumber}>Change number</p>
                            </a>
                          </div>
                        </div>
                      </>
                      <div className="text-center mt-lg-3">
                        <button
                          type="submit" disabled={loading || isChangingNumber}
                          className="btn btn-primary-sm btn-primary w-100 my-3 mb-3 text-capitalize"
                        >
                          {loading ? "Verifying..." : "Continue"}
                        </button>
                      </div>
                      <div className="mt-2">
                        <p className="text-sm fw-normal text-center mb-0">
                          Already have an account?
                          <Link to="/login" className="text-info ms-1">
                            Sign in
                          </Link>
                        </p>
                      </div>
                    </form>
                  ) : (
                    <>
                    <form className="text-start text-dark" onSubmit={handleSubmit(updatePhoneNumber)}>
                    <div className="input-group my-4 d-flex flex-column">
                          <div className="col-12 d-flex justify-content-between my-4 mb-1">
                              <input
                                id="mobile_id"
                                type="text"
                                className={`form-control mb-2 p-2 border rounded ${(errors.mobile) ? 'is-invalid' : ''}`}
                                placeholder="Mobile number"
                                {...register('mobile',
                                  { required: 'Mobile number is required',
                                  pattern: {
                                    value: /^[0-9]{10}$/,
                                    message: 'Invalid mobile number, must be 10 digits.',
                                  },
                                  maxLength: {
                                    value: 10,
                                    message: 'Mobile number cannot exceed 10 digits.',
                                  },})}
                                onChange={handleMobileChange}
                              />
                          </div>
                          {errors.mobile && <p className="text-danger text-start">{errors.mobile.message}</p>}
                          <div className="col-12 d-flex justify-content-between">
                            <a href="#">
                              <p className="mb-0" style={{ fontSize: "0.9rem" }} onClick={handleChangeNumber}>Go back</p>
                            </a>
                            <button type="submit" className="btn btn-link" style={{
                              textDecoration: "none",
                              padding: "0",
                              color: "#6f4bd1",
                              fontWeight: "normal",
                              cursor: "pointer",
                            }}>
                              Update number
                            </button>
                          </div>
                      </div>
                      <div className="text-center mt-lg-3">
                        <button
                          type="submit" disabled={loading || isChangingNumber}
                          className="btn btn-primary-sm btn-primary w-100 my-3 mb-3 text-capitalize"
                        >
                          {loading ? "Verifying..." : "Continue"}
                        </button>
                      </div>
                      <div className="mt-2">
                        <p className="text-sm fw-normal text-center mb-0">
                          Already have an account?
                          <Link to="/login" className="text-info ms-1">
                            Sign in
                          </Link>
                        </p>
                      </div>
                      </form>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </CardComponent>
          </div>
        </div>
      </div>

      {loading && (
        <div className="d-flex justify-content-center align-items-center spinner-wrapper">
          <Spinner animation="border" />
        </div>
      )}

      <Notification
        message={notification.message}
        type={notification.type}
        visible={notification.visible}
        onClose={closeNotification}
      />
    </AuthBackground>
  );
};

export default MobileVerificationOTPForm;
