import React, { createContext, useContext, useState } from 'react';

const plansDataObj = {
  launch: {
    title: 'Launch',
    description: 'Essential toolkit to kickstart your journey, all in one plan',
    actualPrice: 4999,
    discountPrice: 3999,
    featuresHeading: "What's included",
    features: [
      '3,000 completed applications',
      'Application Dashboard',
      'Product Studio',
      'Pricing Engine',
      'Rules Studio'
    ],
    logo:'',
    badge: ''
  },
  core: {
    title: 'Core',
    description: 'Enhanced features for businesses expanding & growing',
    actualPrice: 6999,
    discountPrice: 5999,
    featuresHeading: "Everything in Launch, plus",
    features: [
      '6,000 completed applications',
      'APIs & Webhooks',
      'Custom Variables',
      'Custom Models'
    ],
    logo:'',
    badge: ''
  },
  scale: {
    title: 'Scale',
    description: 'Advanced tools to elevate your business to next level',
    actualPrice: 9999,
    discountPrice: 8999,
    featuresHeading: "Everything from Core, plus",
    features: [
      '12,000 completed applications',
      'Sub Tenant features',
      'Decision Back Testing',
      'Application & Decision A/B testing'
    ],
    logo:'fa-solid fa-bolt',
    badge: 'MOST POPULAR'
  },
  enterprise: {
    title: 'Enterprise',
    description: 'Tailored for large scale operations with full customization',
    discountPrice: '',
    featuresHeading: "What's included",
    features: [
      'Tailored solution',
      'Dedicated Support',
      'Priority Integrations',
    ],
    logo:'fa-solid fa-fire',
    badge: 'MOST ENHANCED'
  },
};

const PlanContext = createContext(null);

export const usePlanContext = () => {
  return useContext(PlanContext);
};

export const PlanProvider = ({ children }) => {
  const [plansData, setPlansData] = useState(plansDataObj);
  const [toggleAnualPayValue, setToggleAnualPayValue] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState('launch');

  const [stripePublishableKey, setStripePublishableKey] = useState(null);
  const [productID, setProductID] = useState(null);
  const [priceID, setPriceID] = useState(null);
  const [customerId, setCustomerId] = useState(null);

  return (
    <PlanContext.Provider value={{plansData, setPlansData, toggleAnualPayValue, setToggleAnualPayValue, selectedPlan, setSelectedPlan, stripePublishableKey, setStripePublishableKey, productID, setProductID, priceID, setPriceID, customerId, setCustomerId }}>
      {children}
    </PlanContext.Provider>
  );
};
