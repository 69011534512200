import {useState, useEffect} from 'react';
import * as React from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import axios from 'axios';
import VariableVersions from './variable_versions';
import { getTestModeCookieValue } from '../../utils/global_function';

axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";

const combinedCredentials = window.api_username + ":" + window.api_password;
const ApiCredentials = btoa(combinedCredentials);
const test_mode_val = getTestModeCookieValue()

function TabVersionComponent() {
    const [header, setHeader] = useState([])
    /* eslint-disable-next-line */
    const [activeTab, setActiveTab] = useState(null);
    const [key, setKey] = useState();

    const fetchData = async () => {
        try {
            const response = await axios.get(`/api/v1/list_tab_versions/?product_referral=${window.product_referral.toString()}&version=${window.version.toString()}&test_mode=${test_mode_val}`,
                 {
                headers: {
                    Authorization: "Basic " + ApiCredentials
                },
            });
            var templateTabDataResult = []
            response.data.results.forEach((data) => {
                // Access the "template_tab" field
                var templateTabData = data.template_tab_version;
                templateTabData.builder_schema = data.template_tab_version.builder_schema;
                templateTabData.rules = undefined;
                // templateTabData.controls = undefined
                if (data.engine && data.engine.length > 0){
                    templateTabData.rules = data.engine[0];
                }
                // if (jsonObject['controls'] && jsonObject['controls'].length > 0) {
                //     console.log(jsonObject['controls'], jsonObject['controls'].length)
                //     templateTabData.controls = jsonObject['controls'];
                // }
                templateTabDataResult.push(templateTabData)
            });
            if(templateTabDataResult.length > 0) {
                setActiveTab(templateTabDataResult[0].id)
            }
            setHeader(templateTabDataResult)
            // Handle the response data here
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    // Call fetchData on component mount
    useEffect(() => {
        fetchData();
    }, []);


    return (<>
            <div className='col-12 text-start ms-n4 row'>
                <h4 className='ms-3 pb-2'>Page Builder</h4>
            </div>
            {header.length > 0 ?
                <div className='centered-tabs pe-0'>
                    <Tabs
                        activeKey={key}
                        id="justify-tab-example"
                        className="mb-3"
                        justify
                        onSelect={(k) => {
                            setKey(k)
                            setActiveTab(k);
                        }}>
                        {header.map((tab) => (
                            <Tab eventKey={tab.id} title={tab.label}>
                                <VariableVersions
                                    key={tab.id}
                                    schema={tab.builder_schema}
                                    heading={tab.title}
                                    keyId={tab.id}
                                    rules={tab.rules}
                                    headers={header}
                                    formControls={tab.controls}
                                    topformControls={tab.Topcontrols}
                                    first_step={tab.first_step}
                                />
                            </Tab>
                        ))}
                    </Tabs>
                </div>
                : <div className="d-flex justify-content-center flex-column flex-wrap gap-2 mx-auto text-center h-90 p-7 my-7 w-100">
                        <header><i className="fa fa-pen-to-square fs-18" aria-hidden="true"
                                   style={{'color': 'rgb(17 17 17 / 7%)', fontSize: '5rem'}}></i><h2
                            className="header-title text-center mt-4">No tabs</h2></header>
                        <div className="mb-5 text-center">Create your first tab to build a form.</div></div>}
        </>
    );

}

export default TabVersionComponent;