import idCard from "../../../../img/id-card.png";
import bgSignup from "../../../../img/bg-signup.svg";
import { getFromSessionStorage } from "../../Utils/utils";
import spinner from "../../../../img/spinner2.gif";
import './IdCard.css';

const IdCard = ({ formData }) => {
  return (
    <div className="id-card-container">
      {/* ID Card */}
      {/* White Background */}
      {/* <div
        className="position-absolute"
        style={{
          backgroundColor: "#fff",
          top: 0,
          left: 0,
          right: "60%",
          bottom: 0,
          zIndex: -2,
        }}
      >
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              bottom: 0,
              width: "300px",
              background: "linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1)",
              filter: "blur(12px)",
              transform: "translateX(25px)",
          }}
          ></div>
      </div> */}

      {/* Background Image */}
      <img
        src={bgSignup}
        alt="signup background"
        className="bg-signup position-fixed"
        style={{
          top: 0,
          left: 0,
          right: "50%",
          bottom: 0,
          zIndex: -1,
          objectFit: "cover",
          width: "50%",
          height: "100%",
        }}
      />

      <div
        className="id-card d-flex align-items-center justify-content-center position-relative"
      >
        <img
          src={idCard}
          alt="Badge"
          className="id-card-img"
        />
        {/* Company Name */}
        <div className="id-card-content position-absolute">
          <div className="fw-bold">
            {formData?.company_name || "Company name"}
          </div>

          {/* First and Last Name */}
          <div>
            <div
              className="fw-bold fs-4 position-absolute first-name"
              style={formData?.first_name ? { color: '#ddd'} : { color: '#898a8e'}}
            >
              {formData?.first_name || "First name"}
            </div>
            <div
              className="fw-bold fs-4 position-absolute last-name"
              style={formData?.last_name ? { color: '#ddd'} : { color: '#898a8e'}}
            >
              {formData?.last_name || "Last name"}
            </div>
          </div>

          {/* Contact Details */}
          <div>
            <div className="fs-6 pb-1">
              {formData?.email || "username@company.com"}
            </div>
            <div className="text-bold fs-6">
              {formData?.mobile || "(999) 999-9900"}
            </div>
          </div>
        </div>
      </div>

    </div>

  );
};

// const IdCard = ({ formData }) => {
//   return (
//     <div className="id-card col-sm-8 col-lg-5 col-xl-4 col-11 d-flex align-items-center justify-content-center">
//       <div className="id-card card border col-lg-9 col-12 mx-auto mt-lg-5 p-0" style={{ backgroundColor: '#1c1e24' }}>
//         <div className="row p-4 justify-content-center" style={{ minHeight: '60vh' }}>
//           <div className="card-body d-flex flex-column justify-content-between">
//             <div className="position-relative">
//               <div className="text-bold fs-5" style={{ color: '#898a8e' }}>
//                 {formData?.company_name || 'Company'}
//               </div>
//               <div className="h-line-1"></div>
//               <div className="mt-5 main-title">
//                 <div className="fw-bold fs-4" style={formData?.first_name ? { color: '#ddd' } : { color: '#898a8e' }}>{formData?.first_name || 'First name'}</div>
//                 <div className="fw-bold fs-4" style={formData?.last_name ? { color: '#ddd' } : { color: '#898a8e' }}>{formData?.last_name || 'Last name'}</div>
//               </div>
//               <div className="h-line-2"></div>
//             </div>
//             <div className="mt-3 mb-lg-4" style={{ color: '#898a8e' }}>
//               <div className="fs-6 pb-1">{formData?.email || 'username@lendapi.com'}</div>
//               <div className="text-bold fs-6">{formData?.mobile || '(999) 999-9900'}</div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

export default IdCard;