import {useState, useEffect} from 'react';
import * as React from "react";
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Variables from './variables';
import axios from 'axios';
import { getTestModeCookieValue } from '../../utils/global_function';
import ConfigModal from '../Pagebuilder/components/productConfig.js'
import { Dropdown, DropdownButton } from 'react-bootstrap';

axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";

const combinedCredentials = window.api_username + ":" + window.api_password;
const ApiCredentials = btoa(combinedCredentials);
const test_mode_val = getTestModeCookieValue()

function TabComponent() {
    const [modalShow, setModalShow] = useState(false);
    const [pluginModalShow, setPluginModalShow] = useState(false);
    const [removeModalShow, setRemoveModalShow] = useState(false);
    const [editModalShow, setEditModalShow] = useState(false);
    const [header, setHeader] = useState([])
    const [activeTab, setActiveTab] = useState(null);
    const [TabLabel, setTabLabel] = useState(null);
    const [key, setKey] = useState();
    const [publishModalShow, setPublishModalShow] = useState(false);
    const [configModalShow, setConfigModalShow] = useState(false);
    const [productConfig, setProductConfig] = useState(null);



    const fetchData = async () => {
        try {
            const response = await axios.get(`/api/v1/list_tabs/?product_referral=${window.product_referral.toString()}&test_mode=${test_mode_val}`,
                 {
                headers: {
                    Authorization: "Basic " + ApiCredentials
                },
            });
            var templateTabDataResult = []
            setProductConfig(response.data.product_config)
            response.data.results.forEach((data) => {
                // Access the "template_tab" field
                var templateTabData = data.template_tab;
                templateTabData.builder_schema = data.template_tab.builder_schema;
                templateTabData.rules = undefined;
                // templateTabData.controls = undefined
                if (data.engine && data.engine.length > 0){
                    templateTabData.rules = data.engine[0];
                }
                templateTabDataResult.push(templateTabData)
            });
            //todo : change id with title, update backend
            if(templateTabDataResult.length > 0) {
                setActiveTab(templateTabDataResult[0].id)
                setTabLabel(templateTabDataResult[0].label)
            }
            setHeader(templateTabDataResult)
            // Handle the response data here
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    // Call fetchData on component mount
    useEffect(() => {
        fetchData();
    }, []);

    function checkEditTabName(e) {
        const input = e.target.value;
        const sanitizedInput = input.replace(/[^a-zA-Z0-9 _-]/g, '');
        if (sanitizedInput !== input) {
            e.target.value = sanitizedInput; 
            window.show_alert("Special characters are not allowed!");
        }
    }

    function MyVerticallyCenteredModal(props) {
        const [title, setTitle] = useState('')


        function handle_change(e) {
            e.preventDefault();
            const input = e.target.value;
            // Allow only letters, numbers, spaces, underscores, and hyphens
            const regex = /^[a-zA-Z0-9 _-]*$/;

            if (!regex.test(input)) {
                window.show_alert("Special characters are not allowed!");
                return; 
            }

            setTitle(input);
            // setTitle(e.target.value);
        }


        function add_title(e) {
            e.preventDefault();
            // null check
            if (!title || title.trim().length < 1)
            {
                window.show_alert("Please enter a title")
                return false
            }
            const newTab = {
                "label": title,
                "id": title.replaceAll(" ", ''),
                "schema": undefined,
                "title": title
            };
            const postData = {
              title: title,
              product: window.product_type,
              product_referral: window.product_referral,
              label: title.replaceAll(" ", ''),
              test_mode: test_mode_val
              // Other data fields you want to send
            };

            axios.post("/api/v1/create_tab/",
                postData,
                {
               headers: {
                   Authorization: "Basic " + ApiCredentials
               },
           }
            ).then(function (response) {
                setHeader([...header, newTab]);
                setModalShow(false);
                window.location.reload();
            }).catch(function (error) {
                window.show_alert(error.response.data.message);
            });
        }


        return (
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Add Tab
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='input-group input-group-static'>
                        <label className='form-label fs-6'>Title <span>*</span></label>
                        <input className='form-control mt-5 p-0' id="add-title" value={title} onChange={handle_change}
                               type="text" placeholder=''></input>
                    </div>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                    <button className='btn btn-secondary btn-secondary-sm' style={{boxShadow: "none"}} onClick={props.onHide}>Close</button>
                    <button className='btn btn-primary btn-primary-sm' type="submit" onClick={add_title}>Submit</button>
                </Modal.Footer>
            </Modal>
        );
    }

    function PluginModal(props) {
        var pluginUrl = window.location.origin + '/static/v2/lendapi_plugin.js'
        var productType = window.product_referral + "_" + window.tenant_id;


        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Wizard Plugin
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h2>Installation</h2>
                    <p>To begin, add the following <code>script</code> near the end of your
                        page:</p>
                    <pre style={{background: '#1e1e1e', color: '#ffffff', padding: '10px', borderRadius: '5px'}}>
                        <code>&lt;script src='{pluginUrl}'&gt;&lt;/script&gt;</code>
                      </pre>
                    <div style={{backgroundColor: '#f8f3fd', padding: '10px', borderRadius: '5px', margin: '10px 0'}}>
                        <strong>💡 Note:</strong>
                        <p>Insert the script before the end of body tag. The plugin injects some elements to the page,
                            so it requires rest of the page to be fully loaded to work properly.</p>
                    </div>
                    <p>Including this script will introduce a global <code>LendAPI</code> variable. This is the sole
                        modification that
                        the plugin will make.</p>
                    <p>The plugin operates independently and doesn't require any external dependencies</p>

                    <h2>Initialise Plugin</h2>
                    <p>For the plugin to work correctly, you will need to call the <code>LendAPIWizard</code> method
                        while
                        passing the desired product, button text and the target element(the button will be appended to
                        this element).</p>
                    <p>You can create an empty div element and pass its ID in place of DIV_ID below.</p>
                    <pre style={{background: '#1e1e1e', color: '#ffffff', padding: '10px', borderRadius: '5px'}}>
                    <code>
                        &lt;script&gt;
                        <br/>

{`window.onload = function () {
    window.LendAPIWizard({
        targetSelector: '#DIV-ID',
        buttonText: 'Pay with LendAPI',
        productType: '${productType}',`}
    {test_mode_val === 'true' || test_mode_val === true
        ? `
        test_mode: true,`
        : ''}
    {`
            });
        };`}
                        <br/>
                        &lt;/script&gt;

                    </code>
                  </pre>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                    <button className='btn btn-secondary btn-secondary-sm' style={{boxShadow: "none"}} onClick={props.onHide}>Close</button>
                </Modal.Footer>
            </Modal>
        );
    }

    function PublishModal(props) {
        const [publishDesc, setPublishDesc] = useState('')
        const [isDefault, setIsDefault] = useState(false)

        const handleClick = () => setIsDefault(!isDefault)

        function handle_change(e) {
            e.preventDefault();
            setPublishDesc(e.target.value);
        }


        function publish_pagebuilder(e) {
            e.preventDefault();
            const postData = {
              is_default: isDefault,
              product: window.product_type,
              product_referral: window.product_referral,
              description: publishDesc,
              test_mode: test_mode_val
              // Other data fields you want to send
            };

            if (publishDesc === '')
            {
                window.show_alert("Please fill the description")
                return false;
            }

            //todo : api call to save tab
            axios.post("/api/v1/publish_pagebuilder/",
                postData,
                {
               headers: {
                   Authorization: "Basic " + ApiCredentials
               },
           }
            ).then(function (response) {
                window.show_success("Published Successfully", 1)
            }).catch(function (error) {
                window.show_alert(error.response.data.message);
            });
        }


        return (
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Publish Page Builder
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="input-group input-group-static w-100">
                        <label className="form-label fs-6">Add Description <span>*</span></label>

                        <textarea
                            className="form-control border p-2 mt-5"
                            rows="4"
                            cols="50"
                            id="publish_desc"
                            value={publishDesc}
                            required
                            name="publish_desc"
                            onChange={handle_change}
                        ></textarea>

                        <div className="d-flex align-items-center mt-3">
                            <input
                                type="checkbox"
                                onClick={handleClick}
                                id="defaultCheckbox"
                                style={{height: '17px', width: '17px'}}
                                className="me-2"
                                checked={isDefault}
                                name="defaultCheckbox"
                            />
                            <label htmlFor="defaultCheckbox" className="pb-0 pt-2" style={{cursor: 'pointer', margin: 0}}>
                                Set New version to default version
                            </label>
                        </div>
                    </div>


                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                    <button className='btn btn-secondary btn-secondary-sm' style={{boxShadow: "none"}}
                            onClick={props.onHide}>Close
                    </button>
                    <button className='btn btn-primary btn-primary-sm' type="submit"
                            onClick={publish_pagebuilder}>Submit
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }


    function RemoveTabModal(props) {
        return (
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                    Remove Tab
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <label className='form-label fs-6'>Are you sure you want to remove this Tab?</label>
                    </div>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                    <button className='btn btn-secondary btn-secondary-sm' style={{boxShadow: "none"}} onClick={props.onHide}>Close</button>
                    <button className='btn btn-error btn-error-sm' type="submit" onClick={removeTab}>Remove</button>
                </Modal.Footer>
            </Modal>
        );
    }

    function EditTabModal(props) {
        return (
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Change tab name
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row px-2">
                        <input className='form-control px-3 py-2' id="update_tab_name_input" defaultValue={TabLabel} onChange={checkEditTabName}/>
                    </div>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                    <button className='btn btn-secondary btn-secondary-sm' style={{boxShadow: "none"}} onClick={props.onHide}>Close</button>
                    <button className='btn btn-primary btn-primary-sm' type="submit" onClick={updateTabName}>Save</button>
                </Modal.Footer>
            </Modal>
        );
    }


    const removeTab = () => {
      if (activeTab) {
          const updatedTabs = header.filter((tab) => tab.id !== activeTab);
          const labels = header.filter((tab) => tab.id === activeTab)[0]["id"];

          const postData = {
            title: labels,
            product: window.product_type,
            product_referral: window.product_referral,
            test_mode: test_mode_val
          };

          // Request headers, including the CSRF token
          const headers = {
            Authorization: "Basic " + ApiCredentials,
            // 'X-CSRFToken': localStorage.getItem("csrfToken"),
          };
          //todo : api call to save tab
          axios.post("/api/v1/remove_tab/",
            postData, { headers }
          )
              .then(function (response) {
                  setHeader(updatedTabs);
                  setActiveTab(header[0]);
                  window.location.reload()
              }).catch(function (error) {
                  console.log(error);
                  window.show_alert(error.response.data.message);

              });

      }
      setRemoveModalShow(false);
  };
    const updateTabName = () => {
        var newName = document.getElementById("update_tab_name_input").value;
        var tabId = activeTab

        if(!newName || newName.trim().length < 1){
            window.show_alert("Please enter a title")
            return false
        }
        const postData = {
            new_title: newName,
            tab_id: tabId,
            product: window.product_type,
            product_referral: window.product_referral,
            label: newName.replaceAll(" ", ''),
            test_mode: test_mode_val
        };

        // Request headers, including the CSRF token
        const headers = {
            Authorization: "Basic " + ApiCredentials,
            // 'X-CSRFToken': localStorage.getItem("csrfToken"),
        };
        //todo : api call to save tab
        axios.post("/api/v1/update_tab_name/",
            postData, {headers}
        )
            .then(function (response) {
                window.show_success('Tab name updated successfully', 1);
            }).catch(function (error) {
                window.show_alert(error.response.data.message);
            });
        setRemoveModalShow(false);
    };


    return (<>
            <div className='d-flex justify-content-between align-items-center flex-wrap mt-2 mb-3 px-4'>
            <div className="me-2 row d-flex align-items-center">
                <h5 className="me-2 d-flex align-items-center">
                    <i 
                        className="fa-solid fa-arrow-left ms-n4 cursor-pointer" 
                        style={{ width: 'max-content' }} 
                        aria-hidden="true" 
                        onClick={() => (window.location.href = '/products/')}
                    ></i>
                    Product Studio: <p className="small mt-3 ms-2">{window.product_name}</p>
                </h5>
            </div>


                <div className='d-flex justify-content-start align-items-center flex-wrap gap-2 pe-2'>
                    {/* Three-dot menu with Config, Edit Tab Name, Remove Tab, and Integrate Plugin options */}
                    <DropdownButton
                        variant="secondary"
                        size="sm"
                        title="⋮"
                        align="end"
                        className="btn-secondary"
                    >
                        <Dropdown.Item className="py-2" onClick={() => setConfigModalShow(true)}>
                            <i alt="Config Icon" className="me-2 fa fa-gear"/>
                            Product Config
                        </Dropdown.Item>
                        <Dropdown.Item className="py-2" onClick={() => setEditModalShow(true)}>
                            <i alt="Edit Icon" className="me-2 fa fa-pen"/>
                            Edit Tab Name
                        </Dropdown.Item>
                        <Dropdown.Item className="py-2" onClick={() => setRemoveModalShow(true)}>
                            <i alt="Remove Icon" className="me-2 fa fa-trash"/>
                            Remove Tab
                        </Dropdown.Item>
                        <Dropdown.Item className="py-2" onClick={() => setPluginModalShow(true)}>
                            <i alt="Plugin Icon" className="me-2 fa fa-plug"/>
                            Integrate Plugin
                        </Dropdown.Item>
                    </DropdownButton>

                    {/* Remaining buttons with icons */}
                    <button className='btn btn-secondary btn-secondary-sm' onClick={() => setModalShow(true)}>
                        <i alt="Add Icon" className="me-2 fa fa-plus-circle"/>Add Tab
                    </button>
                    <button className='btn btn-secondary btn-secondary-sm'
                            onClick={() => window.location.href = '/page_builder/pagebuilder_versions/' + window.product_referral.toString() + "/"}>
                        <i alt="Version Icon" className="me-2 fa fa-code-branch"/>Versions
                    </button>
                    <button className='btn btn-primary btn-primary-sm' onClick={() => setPublishModalShow(true)}>
                        <i alt="Publish Icon" className="me-2 fa fa-paper-plane"/>Publish
                    </button>
                </div>
            </div>
            {header.length > 0 ?
                <div className='centered-tabs'>
                    <Tabs
                        activeKey={key}
                        id="justify-tab-example"
                        className="mb-3"
                        justify
                        onSelect={(k) => {
                            setKey(k)
                            setActiveTab(k);

                            for (var idx = 0; idx < header.length; idx++) {
                                if (header[idx].id === k) {
                                    setTabLabel(header[idx].title)
                                }
                            }

                        }}>
                        {header.map((tab) => (
                            <Tab eventKey={tab.id} title={tab.label}>
                                {/* render only one variable component at a time. This will reduce re-renders and keep the data binding clean. */}
                                {tab.id === activeTab ?
                                    <Variables
                                        key={tab.id}
                                        heading={tab.title}
                                        schema={tab.builder_schema}
                                        keyId={tab.id}
                                        rules={tab.rules}
                                        headers={header}
                                        formControls={tab.controls}
                                        topformControls={tab.Topcontrols}
                                        first_step={tab.first_step}
                                        manual_processing_step={tab.manual_processing_step}
                                    /> : ''}
                            </Tab>
                        ))}
                    </Tabs>
                </div>
                : <div
                    className="d-flex justify-content-center flex-column flex-wrap gap-2 mx-auto text-center h-90 p-7 my-7 w-100">
                    <header><i className="fa fa-pen-to-square fs-18" aria-hidden="true"
                                   style={{'color': 'rgb(17 17 17 / 7%)', fontSize: '5rem'}}></i><h2
                            className="header-title text-center mt-4">No tabs</h2></header>
                        <div className="mb-5 text-center">Create your first tab to build a form.</div></div>}

            <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
            <PluginModal
                show={pluginModalShow}
                onHide={() => setPluginModalShow(false)}
            />
            <RemoveTabModal
                show={removeModalShow}
                onHide={() => setRemoveModalShow(false)}
            />
            <EditTabModal
                show={editModalShow}
                onHide={() => setEditModalShow(false)}
            />
            <PublishModal
                show={publishModalShow}
                onHide={() => setPublishModalShow(false)}
            />
            {productConfig && (
                <ConfigModal
                    show={configModalShow}
                    onHide={() => setConfigModalShow(false)}
                    productConfig={productConfig}
                />
            )}
        </>
    );

}

export default TabComponent;