import React, {useState, useEffect} from 'react';
import {DragDropContext, Droppable, Draggable} from "@atlaskit/pragmatic-drag-and-drop-react-beautiful-dnd-migration";
import '../../App.css';
import {
    create_input_elements_data,
    createBuilderSchema,
} from '../../utils/global_function';
import axios from 'axios';
import {Controls} from "../../utils/constants";
import Select from 'react-select';
import { getTestModeCookieValue } from '../../utils/global_function';
import { RichTextEditor } from './richTextEditor';

axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";
const combinedCredentials = window.api_username + ":" + window.api_password;
const ApiCredentials = btoa(combinedCredentials);
const test_mode_val = getTestModeCookieValue()


function Variables({schema, heading, keyId, rules, headers, formControls, topformControls, first_step, manual_processing_step}) {

    const FormInputVariablePresent = []
    const FormInputVariableNotPresent = []
    const FormInputWidgetsPresent = []
    const FormInputWidgetsNotPresent = []
    const [variables, updatevariables] = useState(FormInputVariableNotPresent);
    const [formvariables, updateformvariables] = useState(FormInputVariablePresent);
    const [inputelements, SetInput] = useState({});
    const [hasRunFunction, setHasRunFunction] = useState(false);
    const [hasRunFunctionWidget, setHasRunFunctionWidget] = useState(false);
    const [selectedProviders, setSelectedProviders] = useState(rules ? rules.providers : null)
    const [nextProviders, setNextProviders] = useState(rules ? rules.next_providers : null)
    const [multipleDecTrees, setMultipleDecTrees] = useState(rules ? rules.multi_dec_trees : null);
    const [multipleProducts, setMultipleProducts] = useState(rules ? rules.multi_products : null);
    const [selectedDecisionTree, setDecisionTree] = useState(rules ? rules.decision_tree : []);
    const [firstStep, setfirstStep] = useState(first_step);
    const [prePopulate, setPrePopulate] = useState(false);
    const [selectedTab, setSelectedTab] = useState(rules ? rules.next_step : []);
    const [required, setRequired] = useState(0);
    const [widgets, updateWidgets] = useState(FormInputWidgetsNotPresent);
    const [formwidgets, updateFormWidgets] = useState(FormInputWidgetsPresent);
    /* eslint-disable-next-line */
    const [controls, setControls] = useState(Controls);
    const [usedControls, setUsedControls] = useState(formControls ? formControls : []);
    const [TopusedControls, setTopUsedControls] = useState(topformControls ? topformControls : []);
    const [showProperties, setshowProperties] = useState(0);
    const [decTreeOutcomes, setdecTreeOutcomes] = useState(null);
    const [conditionalFieldOptions, setConditionalFieldOptions] = useState({});
    const [dependentField, setDependentField] = useState('');
    const [dependentOptions, setDependentOptions] = useState([]);
    const [isDraggingFromUl, setIsDraggingFromUl] = useState(false);
    const [manualProcessingStep, setManualProcessingStep] = useState(manual_processing_step === true ? 1 : 0);
    const [mobileWidgetPhoneInput, setmobileWidgetPhoneInput] = useState("");
    const [richTextValues, setRichTextValues] = useState({});

    const [filterQuery, setFilterQuery] = useState("");

    // Filter each list based on the search term
    const filteredWidgets = widgets.filter(({ name, engine_name }) =>
        name.toLowerCase().includes(filterQuery.toLowerCase()) ||
        engine_name.toLowerCase().includes(filterQuery.toLowerCase())
    );

    const filteredVariables = variables.filter(({ name, engine_name }) =>
        name.toLowerCase().includes(filterQuery.toLowerCase()) ||
        engine_name.toLowerCase().includes(filterQuery.toLowerCase())
    );

    const filteredControls = controls.filter(({ name, id }) =>
        name.toLowerCase().includes(filterQuery.toLowerCase()) ||
        id.toLowerCase().includes(filterQuery.toLowerCase())
    );


    function handleDecisionTree(e) {
        setDecisionTree(e.target.value);
        getOutcomes(e.target.value)
    }
    function handleMultipleProducts(e){
        check_offer_tables(e)
    }
    function handleMultipleDecTrees(e){
        setMultipleDecTrees(e);
        if (e.length === 1) {
            getOutcomes(e[0].id)
        }
    }
    function getOutcomes(decTreeID){
        // API call to get outcomes and display the corresponding dropdowns.
        if (decTreeID > 0) {
            try {
                axios.get(`/api/v1/get_dec_tree_outcomes/?dTreeID=${decTreeID}&test_mode=${test_mode_val}`, {
                    headers: {
                        Authorization: "Basic " + ApiCredentials
                    },
                }).then(function (response) {
                    if(response.data.outcomes) {
                        setdecTreeOutcomes(response.data.outcomes)
                    }
                }).catch(function (error) {
                    window.show_alert(error);
                });
                // todo: update the json structure of fetched variables before updating the state.

            } catch (error) {
                console.error("Error fetching data:", error);
            }
        } else {
            setdecTreeOutcomes([])
        }
    }

    function check_offer_tables(e)
    {
        if (e.length > 0) {
            try {
                axios.get(`/api/v1/check_offer_tables/?selected_product_id=${e[e.length-1].id}&test_mode=${test_mode_val}`, {
                    headers: {
                        Authorization: "Basic " + ApiCredentials
                    },
                }).then(function (response) {
                    if(response.data.value) {
                        setMultipleProducts(e)
                    }
                    else {
                        window.show_alert("Offer table is not setup for this particular product");
                    }
                }).catch(function (error) {
                    window.show_alert(error);
                });
                // todo: update the json structure of fetched variables before updating the state.

            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
        else {
            setMultipleProducts(null)
        }
    }


    function handleSelectedTab(e) {
        setSelectedTab(e.target.value);
    }

    function handleFirstStep(e) {
        setfirstStep(e.target.value);
    }

    function getConditonalFieldsOptions(fields) {
        let conditionalFields = {};

        fields.forEach(field => {
            if (field.type === 'list') {
                conditionalFields[field.engine_name] = field.list_values_json.map((item, index) => ({
                    id: index + 1,
                    label: item.displayValue,
                    name: item,
                    value: item.value,
                }));
            }
            else if(field.type === 'boolean'){
                conditionalFields[field.engine_name] = [{"id": 1, "label": "Yes", "name": "Yes", "value": "Yes"}, {"id": 2, "label": "No", "name": "No", "value": "No"}]
            }
        });
        return conditionalFields;
    };

    // set manual processing step
    function handleManualProcessingStep(e) {
        // update the state
        setManualProcessingStep(e.target.value);
    }


    function handlelabelfontsize(event)
    {
        let checkBox_id = event.target.getAttribute('data-checkbox-id');
        let checkBox = document.getElementById(checkBox_id)
        let label = checkBox.parentNode.querySelector('h5');
        if (checkBox.parentNode.contains(checkBox.parentNode.querySelector('label')))
        {
            label = checkBox.parentNode.querySelector('label');
        }
        if (label)
        {
            label.setAttribute("label-font-size", event.target.value)
            label.style.fontSize = event.target.value + "px"
        }
    }

    function handleLabelAlignment(event)
    {
        let checkBox_id = event.target.getAttribute('data-checkbox-id');
        let checkBox = document.getElementById(checkBox_id)
        let label = checkBox.parentNode.querySelector('h5');
        if (checkBox.parentNode.contains(checkBox.parentNode.querySelector('label')))
        {
            label = checkBox.parentNode.querySelector('label');
        }
        if (label)
        {
            label.setAttribute("label-alignment", event.target.id)
            label.parentElement.style.textAlign = event.target.id
        }

        // Get all the <i> elements
        const icons = document.querySelectorAll('.fa-align-left, .fa-align-center, .fa-align-right');

        // Loop through all the icons and reset their background color to white
        icons.forEach((icon) => {
            icon.style.backgroundColor = "white";  // Reset to white
        });

        // Set the background color of the clicked icon
        event.target.style.backgroundColor = "lightgray";
    }


    function handleDocLibrary(event) {
        let checkBox_id = event.target.getAttribute('data-checkbox-id');
        let checkBox = document.getElementById(checkBox_id)
        let label = checkBox.parentNode.querySelector('h4');
        let libName = window.doc_libs.find(item => item.id === event.target.value);
        if (label) {
            label.setAttribute("request_doc_id", event.target.value)
            if (window.request_template_data[event.target.value]) {
                label.innerHTML = "<i class='fa-solid fa-file me-3'></i>" + (libName ? libName.name : "")
            } else {
                label.innerHTML = "<i class='fa-solid fa-file me-3'></i> Choose consent doc"
            }
        }
    }

    function handlerequestdoc(event) {
        let checkBox_id = event.target.getAttribute('data-checkbox-id');
        let checkBox = document.getElementById(checkBox_id)
        let label = checkBox.parentNode.querySelector('h4');
        if (label) {
            label.setAttribute("request_doc_id", event.target.value)
            if (window.request_template_data[event.target.value]) {
                label.innerHTML = "<i class='fa-solid fa-upload me-3'></i>" + window.request_template_data[event.target.value]
            } else {
                label.innerHTML = "<i class='fa-solid fa-upload me-3'></i> Choose Doc Upload Template"
            }
        }
    }

    const handleDependentFieldChange = (event) => {
        const field = event.target.value;
        setDependentField(field);
        setDependentOptions(conditionalFieldOptions[field] || []);

    };

    const handlePrePopulateChange = (event) => {
        const value = event.target.value === 'true';  // Convert string to boolean
        setPrePopulate(value);
        handleKeyDown(event)
    };

    const handleRichTextEditorChange1= (content, editorId) => {
        setRichTextValues(prevState => ({
            ...prevState,
            [editorId]: content
        }));
    };

    function Properties({inputelements}) {
        function insertDoclib() {
            const myField = document.getElementById('checkbox-textarea');
            const mySelect = document.getElementById('doc_lib_select');
            const myValue = '[[' + mySelect.options[mySelect.selectedIndex].text + ']]';
            //IE support
            if (document.selection) {
                myField.focus();
                var sel = document.selection.createRange();
                sel.text = myValue;
            }
            //MOZILLA and others
            else if (myField.selectionStart || myField.selectionStart === '0') {
                var startPos = myField.selectionStart;
                var endPos = myField.selectionEnd;
                myField.value = myField.value.substring(0, startPos)
                    + myValue
                    + myField.value.substring(endPos, myField.value.length);
            } else {
                myField.value += myValue;
            }
            myField.dispatchEvent(new Event("keyup"));
        }

        useEffect(() => {
            if (inputelements["dependentField"]) {
                setDependentOptions(conditionalFieldOptions[dependentField] || []);
            }
        });

        function insertConsentVariable() {
            const myField = document.getElementById('checkbox-textarea');
            const mySelect = document.getElementById('consent_variable_select');
            const myValue = '{{' + mySelect.options[mySelect.selectedIndex].text + '}}';
            //IE support
            if (document.selection) {
                myField.focus();
                var sel = document.selection.createRange();
                sel.text = myValue;
            }
            //MOZILLA and others
            else if (myField.selectionStart || myField.selectionStart === '0') {
                var startPos = myField.selectionStart;
                var endPos = myField.selectionEnd;
                myField.value = myField.value.substring(0, startPos)
                    + myValue
                    + myField.value.substring(endPos, myField.value.length);
            } else {
                myField.value += myValue;
            }
            myField.dispatchEvent(new Event("keyup"));
        }
        return (
            <>
                <div className='w-100 w-sm-25 card p-3 border' id="properties-tab">
                    <h3 className='mx-auto mt-3'>Properties</h3>

                    {(inputelements["name"] === "checkbox_control" || inputelements["name"] === "label_control" || inputelements["name"] === "top_label_control") || inputelements["name"] === "document_upload" || inputelements["name"] === "prove" ?
                        <div></div> :
                        <div>
                            <div className='w-100 col-3 mb-2 mt-1 input-group input-group-static'>
                                <label className='fs-6 fw-bold m-0 mb-2'>Label: </label>
                                <input type="text" id="properties-input-label" name="properties-input-label"
                                       className='mt-n1 me-3 form-control' defaultValue={inputelements["name"]}></input>
                            </div>
                            <div className='w-100 col-3 mb-2 mt-1'>
                                <label className='fs-6 fw-bold m-0'>Engine Name
                                    : <span>{inputelements["id"]}</span></label>
                            </div>
                        </div>
                    }

                    <div className='w-100 col-3 mb-2 mt-1'>
                        <label className='fs-6 fw-bold m-0'>Data Type : <span>{inputelements["type"]}</span></label>
                    </div>
                    <hr className="border"></hr>


                    {["label_control", "top_label_control", "checkbox_control", "widget"].includes(inputelements["name"]) ? '' :
                        <div className='w-100 col-3 mb-2 mt-4'>
                            <label htmlFor="check-required" className="fs-6 fw-bold m-0 mb-2">Required</label>
                            <select className="form-control border"
                                    name="check-required[]" id="check-required" onChange={check_required}
                                    defaultValue={required}>
                                <option value={0}>No</option>
                                <option value={1}>Yes</option>

                            </select>
                        </div>
                    }
                    {inputelements["type"] === "widget" ?
                        <div className='w-100 col-3 mb-2 mt-4'>
                            <label htmlFor="check-required" className="">Terms & Conditions URL</label>
                            <textarea className="form-control border" data-checkbox-id={inputelements["id"]}
                                      name="terms-textarea" id="terms-textarea" rows="5">
                            {inputelements["data-terms-url"]}
                        </textarea>
                        </div> : ''}

                    {inputelements["name"] === "document_upload" ?
                        <div className='mt-3 mb-4'>
                            <label className='fs-6 fw-bold'>Request Doc</label>
                            <select className="form-control border"
                                    name="request_library_doc[]" id="request_library_docs" onChange={handlerequestdoc}
                                    data-checkbox-id={inputelements["id"]}
                                    defaultValue={inputelements["request_doc_id"] && inputelements["request_doc_id"] !== "" ? inputelements["request_doc_id"] : -1}>
                                <option value='-1'>No request doc</option>
                                {window.request_docs.map(({id, name}, index) => {
                                    return (
                                        <option key={id} id={id} value={id}>
                                            {name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div> : <div></div>
                    }
                  {inputelements["name"] === "document_display" ?
                        <div className='mt-3 mb-4'>
                            <label className='fs-6 fw-bold'>Choose doc library</label>
                            <select className="form-control border"
                                    name="consent_doc_libraries[]" id="consent_doc_libraries" onChange={handleDocLibrary}
                                    data-checkbox-id={inputelements["id"]}
                                    defaultValue={inputelements["request_doc_id"] && inputelements["request_doc_id"] !== "" ? inputelements["request_doc_id"] : -1}>
                                <option value='-1'>No request doc</option>
                                {window.doc_libs.map(({id, name}, index) => {
                                    return (
                                        <option key={id} id={id} value={id}>
                                            {name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div> : <div></div>
                    }
                    {inputelements["type"] === "date" ?
                        <div className='mt-3 mb-4'>
                            <label className='fs-6 fw-bold'>Range</label>
                            <select className="form-control border"
                                    name="date_range[]" id="date_range" onChange={handleKeyDown}
                                    defaultValue={inputelements["dateRange"] ? inputelements["dateRange"] : "all"}>
                                <option value='all'>All Dates</option>
                                <option value='past'>Past Dates</option>
                                <option value='future'>Future Dates</option>
                            </select>
                        </div> : <div></div>
                    }
                    {['string', 'numeric', 'number', 'list', 'date'].includes(inputelements["type"]) ?
                        <div>
                            <div className='w-100 col-3 mb-2 mt-4'>
                                <label htmlFor="check-required" className="fs-6 fw-bold m-0 mb-2">Pre-populate?</label>
                                <select className="form-control border"
                                        name="pre-populate" id="pre-populate" onChange={handlePrePopulateChange}
                                        value={prePopulate} defaultValue={inputelements["prePopulate"]}>
                                    <option value={false}>No</option>
                                    <option value={true}>Yes</option>

                                </select>
                            </div>
                            {prePopulate ?
                                <div className='w-100 col-3 mb-2 mt-4'>
                                    <label htmlFor="check-required" className="fs-6 fw-bold m-0 mb-2">Disable on
                                        pre-populate?</label>
                                    <select className="form-control border" onChange={handleKeyDown}
                                            name="disable-pre-populate" id="disable-pre-populate"
                                            defaultValue={inputelements["disableOnPrePopulate"]}>
                                        <option value={false}>No</option>
                                        <option value={true}>Yes</option>

                                    </select>
                                </div> : ''}

                        </div>

                        : ''}
                    {['string', 'numeric', 'number', 'list'].includes(inputelements["type"]) ?
                        <div className='w-100 col-3 mb-2 mt-4'>
                            <label htmlFor="check-required" className="">Helper text</label>
                            <textarea className="form-control border" data-field-id={inputelements["id"]}
                                      name="helper-textarea" id="helper-textarea" rows="3">
                            {inputelements["helper-text"]}
                        </textarea>
                        </div> : ''}

                    {['string', 'numeric', 'number'].includes(inputelements["type"]) ? (
                        <div className='w-100 col-3 mb-2 mt-4'>
                            <label htmlFor="check-required" className="fs-6 fw-bold m-0 mb-2">Mask field?</label>
                            <select className="form-control border"
                                    name="mask-field" id="mask-field" onChange={(e) => handleKeyDown(e)}
                                    defaultValue={inputelements["mask-field"]}>
                                <option value={false}>No</option>
                                <option value={true}>Yes</option>

                            </select>
                        </div>) : ""}

                    {inputelements["type"] === "string" ? (
                        <div className='w-100 col-3 mb-2 mt-4 input-group input-group-static'>
                            <label className='fs-6 fw-bold'>Max length</label>
                            <input type="text" id="properties-max-length" placeholder="" name="properties-max-length"
                                   defaultValue={inputelements["maxlength"]}
                                   className='mt-n1 me-3 form-control'></input>
                        </div>) : ""}
                    {inputelements["type"] === "numeric" ? (
                        <div className='w-100 col-3 mb-5 mt-4 input-group input-group-static'>

                            <label className='fs-6 fw-bold'>Min value</label>
                            <input type="text" id="properties-min-value" placeholder="" name="properties-min-value"
                                   defaultValue={inputelements["minimum"]} className='mt-n1 me-3 form-control'></input>

                            <label className='fs-6 fw-bold mt-4'>Max value</label>
                            <input type="text" id="properties-max-value" placeholder="" name="properties-max-value"
                                   defaultValue={inputelements["maximum"]} className='mt-n1 me-3 form-control'></input>

                        </div>) : ""}
                    {inputelements["name"] === "label_control" || inputelements["name"] === "top_label_control" ? (
                        <>
                            <div className='row mt-4'>
                                <label className='fs-6 fw-bold'>Alignment</label>
                                <div className='col-4'>
                                    <i data-checkbox-id={inputelements["id"]}
                                       style={{backgroundColor: inputelements["label-alignment"] === "left" ? "lightgray" : null}}
                                       id="left" class="fa-solid fa-align-left cursor-pointer"
                                       onClick={handleLabelAlignment}></i>
                                </div>
                                <div className='col-4'>
                                    <i data-checkbox-id={inputelements["id"]}
                                       style={{backgroundColor: inputelements["label-alignment"] === "center" ? "lightgray" : null}}
                                       id="center" class="fa-solid fa-align-center cursor-pointer"
                                       onClick={handleLabelAlignment}></i>
                                </div>
                                <div className='col-4'>
                                    <i data-checkbox-id={inputelements["id"]}
                                       style={{backgroundColor: inputelements["label-alignment"] === "right" ? "lightgray" : null}}
                                       id="right" class="fa-solid fa-align-right cursor-pointer"
                                       onClick={handleLabelAlignment}></i>
                                </div>
                            </div>
                            <div className='w-100 col-3 mb-2 mt-4'>

                                <label className='fs-6 fw-bold'>Font Size</label>

                                <select className="form-control border"
                                        name="properties-label-font-size" id="properties-label-font-size"
                                        data-checkbox-id={inputelements["id"]}
                                        defaultValue={inputelements["label-font-size"]} onChange={handlelabelfontsize}>
                                    <option value="5">5px</option>
                                    <option value="6">6px</option>
                                    <option value="7">7px</option>
                                    <option value="8">8px</option>
                                    <option value="9">9px</option>
                                    <option value="10">10px</option>
                                    <option value="11">11px</option>
                                    <option value="12">12px</option>
                                    <option value="14">14px</option>
                                    <option value="16">16px</option>
                                    <option value="18">18px</option>
                                    <option value="20">20px</option>
                                    <option value="22">22px</option>
                                    <option value="24">24px</option>
                                    <option value="26">26px</option>
                                    <option value="28">28px</option>
                                    <option value="36">36px</option>
                                    <option value="48">48px</option>
                                    <option value="72">72px</option>
                                </select>

                            </div>
                        </>) : ""}
                    {(inputelements["name"] === "checkbox_control" || inputelements["name"] === "label_control" || inputelements["name"] === "top_label_control") ?
                        <div className='w-100 col-3 mb-2 mt-4'>
                            <label htmlFor="check-required" className="">Label Text<p className="small mt-1">Can include
                                hyperlinks</p></label>
                            {inputelements["name"] === "checkbox_control" ?
                                <button className="btn btn-secondary btn-secondary-sm mb-1" id="add_document"
                                        data-bs-toggle="modal"
                                        data-bs-target="#modal_add_document">

                                    Add consent
                                </button> : ""}

                            {inputelements["name"] === "checkbox_control" || inputelements["name"] === "label_control" || inputelements["name"] === "top_label_control" ?
                                <button className="btn btn-secondary btn-secondary-sm mb-3" id="add_variable_consent"
                                        data-bs-toggle="modal"
                                        data-bs-target="#modal_add_variable_consent">
                                    Add Variable
                                </button> : ""}

                            <textarea className="form-control border" data-checkbox-id={inputelements["id"]}
                                      name="checkbox-textarea" id="checkbox-textarea" rows="5">
                            {inputelements["data-text"]}
                        </textarea>
                        </div>
                        : ""}


                    {!["label_control", "top_label_control", "checkbox_control"].includes(inputelements["name"]) ? (
                        <div>
                            <div className='w-100 col-3 mb-2 mt-4'>
                                <label htmlFor="check-required" className="fs-6 fw-bold m-0 mb-2">Depends on:</label>
                                <select id="dependent-field-select" name="dependent-field-select" value={dependentField}
                                        onChange={handleKeyDown} className="form-control border"
                                        defaultValue={inputelements["dependentField"]} >
                                    <option value="">--Select Field--</option>
                                    {Object.keys(conditionalFieldOptions).map((field) => (
                                        <option key={field} value={field}>{field}</option>
                                    ))}
                                </select>
                            </div>
                            <div className='w-100 col-3 mb-2 mt-4 provider-select'>
                                <label htmlFor="check-required" className="fs-6 fw-bold m-0 mb-2">Display if dependent
                                    field value is</label>

                                    <Select id="dependent-field-options"
                                        name="dependent-field-options"
                                        defaultValue={dependentOptions.filter(option => inputelements["dependentFieldOption"]?.includes(option.value))}
                                        onChange={handleDependentSelectedValue}
                                        isMulti={true}
                                        options={dependentOptions}
                                        className="react-select"
                                    />
                            </div>
                        </div>
                    ): <div/> }


                    <div className="modal fade" id="modal_add_document" tabIndex="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content p-3">
                                <div className="modal-header border-0">
                                    <h4 className="modal-title">Add Document Library</h4>
                                    <button type="button" className="btn-close fs-3 text-dark fs-3"
                                            data-bs-dismiss="modal"
                                            aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">

                                    <div
                                        className="w-100 doc-group-select">
                                        <div className="text-dark">
                                            Please define the type of document that applicant needs to upload
                                        </div>

                                        <form id="add_document_form">
                                            <div className="input-group mt-3" style={{"display": "none"}}>
                                                <label className="" htmlFor="selected_text">Selected Text</label>
                                                <input type="text" className="form-control" id="selected_text" disabled
                                                       name="offer_name"></input>
                                            </div>
                                            <div className="input-group mt-4">
                                                <label htmlFor="documents" className="mb-2"
                                                       style={{"display": "block"}}>Document
                                                    Library</label>
                                                <select className="form-control border"
                                                        name="doc_libs[]" id="doc_lib_select">
                                                    <option value='-1'>Select doc library</option>
                                                    {window.doc_libs.map(({id, name}, index) => {
                                                        return (
                                                            <option key={id} id={id} value={id}>
                                                                {name}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                            <div className="mt-3 document_details d-none">
                                                <div className="input-group mt-4">
                                                    <label className="">Documents in the Library: </label>
                                                    <div className="text-dark text-bold">
                                                        <ul className="mb-4 docs-in-group">

                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="mt-4 d-flex justify-content-between">
                                                <button type="button" className="btn btn-secondary btn-secondary-sm"
                                                        data-bs-dismiss="modal">Close
                                                </button>
                                                <button type="button" className="btn btn-primary btn-primary-sm" onClick={insertDoclib}
                                                        id="add_document_submit">Add consent
                                                </button>
                                            </div>
                                        </form>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="modal_add_variable_consent" tabIndex="-1" role="dialog"
                         aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content p-3">
                                <div className="modal-header">
                                    <h4 className="modal-title">Add Variable</h4>
                                    <button type="button" className="btn-close fs-3 text-dark fs-3"
                                            data-bs-dismiss="modal"
                                            aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">

                                    <div
                                        className="w-100 doc-group-select">
                                        <div className="text-dark">
                                            Please select a variable
                                        </div>

                                        <form id="add_document_form">
                                            <div className="input-group mt-3" style={{"display": "none"}}>
                                                <label className="" htmlFor="selected_text">Selected Text</label>
                                                <input type="text" className="form-control" id="selected_text" disabled
                                                       name="offer_name"></input>
                                            </div>
                                            <div className="input-group mt-4">
                                                <label htmlFor="documents" className="mb-2"
                                                       style={{"display": "block"}}>
                                                    Variables</label>
                                                <select className="form-control border"
                                                        name="doc_libs[]" id="consent_variable_select">
                                                    <option value='-1'>Select a variable</option>
                                                    {window.consent_variables.map(({id, name, engine_name}, index) => {
                                                        return (
                                                            <option key={id} id={id} value={id}>
                                                                {engine_name}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                            <div className="mt-3 document_details d-none">
                                                <div className="input-group mt-4">
                                                    <label className="">Documents in the Library: </label>
                                                    <div className="text-dark text-bold">
                                                        <ul className="mb-4 docs-in-group">

                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="mt-4 d-flex justify-content-between">
                                                <button type="button" className="btn btn-secondary btn-secondary-sm"
                                                        data-bs-dismiss="modal">Close
                                                </button>
                                                <button type="button" className="btn btn-primary btn-primary-sm ms-2"
                                                        onClick={insertConsentVariable}
                                                        id="add_document_submit">Add variable
                                                </button>
                                            </div>
                                        </form>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                    {["label_control", "top_label_control", "checkbox_control", "widget"].includes(inputelements["name"]) ? '' :
                        <div className='w-100 col-3 mb-2 mt-4 input-group input-group-static'>
                            <label className='fs-6 fw-bold'>Group label</label>
                            <input type="text" id="properties-group-label" placeholder="" name="properties-group-label"
                                   defaultValue={inputelements["group_label"]}
                                   className='mt-n1 me-3 form-control'></input>
                        </div>
                    }


                </div>
            </>
        );
    }

    function Rules({rules = {}}) {
        const [applicantRedirectURL, setapplicantRedirectURL] = useState(rules.applicant_redirect_url? rules.applicant_redirect_url : "");

        function getOutcomeSelects() {
            var selects = []
            if (decTreeOutcomes) {
                decTreeOutcomes.forEach((element) => {
                    for (const [key, value] of Object.entries(element)) {
                        var newvalue = value.replace(/\s/g, '');
                        selects.push(
                            <div className='w-100 col-3 mb-2 mt-4'>
                            <label htmlFor="decision_tree" className="">{value}</label>
                                <select className="form-control border" data-outcome-id={key}
                                        name="outcome-select[]" id={"outcome-select-" + newvalue}
                                        defaultValue={selectedTab && selectedTab[0] ? selectedTab[0][key]: ''}>
                                    <option value="-1">---Select next step---</option>
                                    <option value="0">This is the last step</option>
                                    {/* eslint-disable-next-line  */}
                                    {headers.map(({title, id, label, tab_id}, index) => {
                                        if (id !== keyId)
                                            return (
                                                <option key={index} id={id} value={tab_id}>
                                                    {title}
                                                </option>
                                            );
                                    })}
                                </select>
                            </div>
                        );
                    }
                });
            }

            return selects
        }

        return (
            <>
                <div className='w-100 w-sm-25 card p-3 border rules_custom'>
                    <h3 className='mx-auto mt-3'>Rules</h3>
                    <div className='w-100 col-3 mb-2 mt-4 provider-select' id="provider-select">
                        <label htmlFor="provider_select" className="">Initial Providers</label>
                        {/* eslint-disable-next-line  */}
                        <Select
                            value={selectedProviders !== null || rules === undefined || typeof rules == 'undefined' ? selectedProviders : rules.providers}
                            onChange={setSelectedProviders}
                            options={window.providers}
                            isMulti="true"
                            className="react-select"
                        />
                    </div>

                    {window.product_type === 10002 ?

                        <>
                            <div className='w-100 col-3 mb-2 mt-4' id="multi-decision-select">
                                <label htmlFor="decision_tree" className="">Multi-product decision trees
                                </label>
                                {/* eslint-disable-next-line  */}
                                <Select
                                    defaultValue={multipleDecTrees != null || rules === undefined || typeof rules == 'undefined' ? multipleDecTrees : ""}
                                    // value={multipleDecTrees != null ? multipleDecTrees : ""}
                                    onChange={handleMultipleDecTrees}
                                    // options={window.workflows}
                                    options={window.workflows}
                                    isMulti="true"
                                />
                            </div>

                            {formwidgets.length > 0 && formwidgets[0]["engine_name"] === "multi_offers" ?
                                <div className='w-100 col-3 mb-2 mt-4' id="multi-products-select">
                                    <label htmlFor="multi_products" className="">Products
                                    </label>
                                    {/* eslint-disable-next-line  */}
                                    <Select
                                        defaultValue={multipleProducts != null || rules === undefined || typeof rules == 'undefined' ? multipleProducts : ""}
                                        value={multipleProducts}
                                        onChange={handleMultipleProducts}
                                        // options={window.workflows}
                                        options={window.products}
                                        isMulti="true"
                                    />
                                </div> : <div></div>
                            }
                        </> : <div className='w-100 col-3 mb-2 mt-4' id="decision-select">
                            <label htmlFor="decision_tree" className="">Decision Tree
                            </label>
                            <select className="form-control border"
                                    name="decision_tree[]" id="decision_tree" onChange={handleDecisionTree}
                                    defaultValue={selectedDecisionTree}>
                                <option value='-1'>No Decision Tree</option>
                                {window.workflows.map(({id, name}, index) => {
                                    return (
                                        <option key={id} id={id} value={id}>
                                            {name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>}
                    {selectedDecisionTree ?
                    <div className='w-100 col-3 mb-2 mt-4 provider-select' id="provider-select">
                        <label htmlFor="provider_select" className="">Final Providers</label>
                        {/* eslint-disable-next-line  */}
                        <Select
                            value={nextProviders !== null || rules === undefined || typeof rules == 'undefined' ? nextProviders : rules.next_providers}
                            onChange={setNextProviders}
                            options={window.providers}
                            isMulti="true"
                            className="react-select"
                        />
                    </div> : <></>}

                    {/*// code for multi product only*/}
                    {window.product_type === 10002 ?
                        <>
                            {multipleDecTrees && multipleDecTrees.length === 1 && decTreeOutcomes === null ? getOutcomes(selectedDecisionTree) : ''}

                            {decTreeOutcomes && decTreeOutcomes.length > 0 && multipleDecTrees && multipleDecTrees.length === 1 ?
                                <div id="decisionOutcomesDiv">
                                    <p className="text-muted mx-1  mb-2 mt-4" style={{fontSize: "12px"}}>Select next
                                        step for
                                        each
                                        outcome of the decision tree</p>
                                    {getOutcomeSelects()}

                                </div>
                                : <div className="mt-4">
                                    <label htmlFor="decision_tree" className="">Next step</label>
                                    <select className="form-control border"
                                            name="template_tab[]" id="template_tab" onChange={handleSelectedTab}
                                            defaultValue={selectedTab}>
                                        <option value="-1">---Select next step---</option>
                                        <option value="0">This is the last step</option>
                                        { /* eslint-disable-next-line */}
                                        {headers.map(({title, id, tab_id}, index) => {
                                            if (id !== keyId)
                                                return (
                                                    <option key={index} id={id} value={tab_id}>
                                                        {title}
                                                    </option>
                                                );
                                        })}
                                    </select>
                                </div>}
                        </>
                        : <>
                            {selectedDecisionTree && decTreeOutcomes === null ? getOutcomes(selectedDecisionTree) : ''}

                            {decTreeOutcomes && decTreeOutcomes.length > 0 ?
                                <div id="decisionOutcomesDiv">
                                    <p className="text-muted mx-1  mb-2 mt-4" style={{fontSize: "12px"}}>Select next
                                        step for each
                                        outcome of the decision tree</p>
                                    {getOutcomeSelects()}

                                </div>
                                : <div className="mt-4">
                                    <label htmlFor="decision_tree" className="">Next step</label>
                                    <select className="form-control border"
                                            name="template_tab[]" id="template_tab" onChange={handleSelectedTab}
                                            defaultValue={selectedTab}>
                                        <option value="-1">---Select next step---</option>
                                        <option value="0">This is the last step</option>
                                        { /* eslint-disable-next-line */}
                                        {headers.map(({title, id, tab_id}, index) => {
                                            if (id !== keyId)
                                                return (
                                                    <option key={index} id={id} value={tab_id}>
                                                        {title}
                                                    </option>
                                                );
                                        })}
                                    </select>
                                </div>}
                        </>}
                    <div className='w-100 col-3 mb-2 mt-4'>
                        <label htmlFor="check-required" className="">Is this the first
                            step?</label>
                        <select className="form-control border"
                                name="first-step-select[]" id="first-step-select" onChange={handleFirstStep}
                                defaultValue={firstStep}>
                            <option value={0}>No</option>
                            <option value={1}>Yes</option>

                        </select>
                    </div>


                    {/* manual processing step */}
                    <div className='w-100 col-3 mb-2 mt-4'>
                        <label htmlFor="check-required" className="">Is this the manual processing
                            step?</label>
                        <select className="form-control border"
                                name="manual-processing-step-select[]" id="manual-processing-step-select"
                                onChange={handleManualProcessingStep}
                                defaultValue={manualProcessingStep}>
                            <option value={0}>No</option>
                            <option value={1}>Yes</option>

                        </select>
                    </div>

                    {/* applicant redirect url */}
                    {selectedTab === 0 || selectedTab === '0' ?
                        <div className='w-100 col-3 mb-2 mt-4'>
                            <label className='fs-6 fw-bold'>Redirect URL</label>
                            <input type="text" id="applicant-redirect-url"
                                   placeholder="https://example.com or {{variable_code}}" name="applicant-redirect-url"
                                   value={applicantRedirectURL}
                                   onChange={(e) => setapplicantRedirectURL(e.target.value)}
                                   className='form-control border'/>
                            <p className="text-muted mx-1 mb-2 mt-2" style={{fontSize: "13px"}}>
                                Provide a valid URL or {"{{Variable Code}}"} for Applicant external redirection
                            </p>
                        </div> : ""
                    }
                </div>
            </>
        );
    }


    function check_required(e) {
        var current_tab = document.getElementById("justify-tab-example-tabpane-" + keyId);
        var el = current_tab.querySelector(".focused");

        if (el) {
            var is_required_checked = e.target.value;
            setRequired(is_required_checked)

            var parentElement = el.parentNode;
            var label = parentElement.querySelector('label');
            const iElement = label.querySelector('i');
            if (iElement) {
                label.removeChild(iElement);
            }
            // setRequired(0);
            el.setAttribute("data-isRequired", false);
            if (is_required_checked > 0) {
                if (schema && schema.required && schema.required.indexOf(el.id) < 0) {
                    schema.required.push(el.id)
                }

                var ast = document.createElement('i');
                ast.className = 'fa-solid fa-asterisk fa-xs me-1 text-danger';
                label.appendChild(ast);
                el.setAttribute("data-isRequired", true);
                // setRequired(1);
            }
            else{
               if (schema && schema.required && schema.required.indexOf(el.id) > -1) {
                    schema.required.splice(schema.required.indexOf(el.id), 1)
                }
            }
        }

        setTimeout(() => {
            let elems = current_tab.querySelector('#properties-max-length');
            if (elems) {
                elems.addEventListener('keyup', handleKeyDown);
            }
            let labelText = current_tab.querySelector('#properties-input-label');
            if (labelText) {
                labelText.addEventListener('keyup', handleKeyDown);
            }
            let textField = current_tab.querySelector('#checkbox-textarea');
            if (textField) {
                textField.addEventListener('keyup', handleKeyDown);
            }
            let helperTextArea = current_tab.querySelector('#helper-textarea');
            if (helperTextArea) {
                helperTextArea.addEventListener('keyup', handleKeyDown);
            }
            let min_range = current_tab.querySelector('#properties-min-value');
            let max_range = current_tab.querySelector('#properties-max-value');
            if (min_range) {
                min_range.addEventListener('keyup', handleKeyDown);
            }
            if (max_range) {
                max_range.addEventListener('keyup', handleKeyDown);
            }
        }, 0);
    }


    function handleFocus(e) {
        const obj = {
            "name":e.target.getAttribute("name"),
            "id": e.target.id,
            "type": e.target.getAttribute("type"),
            "maxlength": e.target.getAttribute("maxlength"),
            "minimum": e.target.getAttribute("minimum"),
            "maximum": e.target.getAttribute("maximum"),
            "group_label": e.target.getAttribute('group_label'),
            "required": e.target.getAttribute('data-isrequired'),
            "dependentField": e.target.getAttribute('dependent-field'),
            "dependentFieldOption": e.target.getAttribute('dependent-field-option'),
            "helper-text":e.target.getAttribute('helper-text'),
            "prePopulate":e.target.getAttribute('prepopulate'),
            "mask-field":e.target.getAttribute('mask-field'),
            "disableOnPrePopulate":e.target.getAttribute('disableonprepopulate'),
            "dateRange":e.target.getAttribute('date-range')
        }
        if(e.target.getAttribute('prepopulate') && e.target.getAttribute('prepopulate') === "true"){
            setPrePopulate(true)
        }
        else if(prePopulate){
           setPrePopulate(false)
        }
        if(e.target.getAttribute("name") === "checkbox_control" || e.target.getAttribute("name") === "label_control" || e.target.getAttribute("name") === "top_label_control" || e.target.getAttribute("name") === "document_upload" || e.target.getAttribute("name") === "document_display" || e.target.getAttribute("name") === "scroll_to_bottom"){
            obj["data-text"] = e.target.getAttribute("data-text")
            obj["label-font-size"] = e.target.getAttribute("label-font-size")
            obj["label-alignment"] = e.target.getAttribute("label-alignment")
            obj["request_doc_id"] =  e.target.getAttribute("request_doc_id")
        }
        if(e.target.getAttribute("name") === "prove"){
            obj["data-terms-url"] = e.target.getAttribute("data-terms-url")
        }

        const extractedData = getConditonalFieldsOptions(formvariables.filter(field => field.engine_name !== e.target.id));
        setConditionalFieldOptions(extractedData);
        if (e.target.getAttribute('dependent-field')) {
            setDependentField(e.target.getAttribute('dependent-field'));
            setDependentOptions(conditionalFieldOptions[e.target.getAttribute('dependent-field')] || []);
        }
        else{
            setDependentField('');
        }

        SetInput(obj);
        setshowProperties(1)
        if (e.target.getAttribute("data-isrequired") === "true") {
            setRequired(1);
        } else {
            setRequired(0)
        }


        setTimeout(() => {
            var current_tab = document.getElementById("justify-tab-example-tabpane-" + keyId)
            if(current_tab.querySelector("#check-required")) {
                current_tab.querySelector("#check-required").checked = e.target.getAttribute("data-isRequired") === "true";
            }
            var inputs = current_tab.querySelectorAll('input');
            var select_el = current_tab.querySelectorAll('select');
            var elements = current_tab.querySelectorAll('h4')
            var labels = current_tab.querySelectorAll('h5')
            inputs.forEach(function (input) {
                if (input.classList.contains('focused')) {
                    input.classList.remove('focused');
                }
            })
            select_el.forEach(function (input) {
                if (input.classList.contains('focused')) {
                    input.classList.remove('focused');
                }
            })
            elements.forEach(function (input) {
                if (input.classList.contains('focused')) {
                    input.classList.remove('focused');
                }
            })
            labels.forEach(function (input) {
                if (input.classList.contains('focused')) {
                    input.classList.remove('focused');
                }
            })
            e.target.classList.add('focused');

            let elems = current_tab.querySelector('#properties-max-length');
            if (elems) {
                elems.addEventListener('keyup', handleKeyDown);
            }
            let textField = current_tab.querySelector('#checkbox-textarea');
            if (textField) {
                textField.addEventListener('keyup', handleKeyDown);
            }
            let helperTextArea = current_tab.querySelector('#helper-textarea');
            if (helperTextArea) {
                helperTextArea.addEventListener('keyup', handleKeyDown);
            }
            let min_range = current_tab.querySelector('#properties-min-value');
            let max_range = current_tab.querySelector('#properties-max-value');
            if (min_range) {
                min_range.addEventListener('keyup', handleKeyDown);
            }
            if (max_range) {
                max_range.addEventListener('keyup', handleKeyDown);
            }
            let group_label = current_tab.querySelector('#properties-group-label');
            if (group_label) {
                group_label.addEventListener('keyup', handleKeyDown);
            }
            let input_label = current_tab.querySelector('#properties-input-label');
            if (input_label) {
                input_label.addEventListener('keyup', handleKeyDown);
            }
            let prove_terms_input = current_tab.querySelector('#terms-textarea');
            if (prove_terms_input) {
                prove_terms_input.addEventListener('keyup', handleKeyDown);
            }
            let dependentOption = current_tab.querySelector('#dependent-field-options');
            if (dependentOption) {
                dependentOption.addEventListener('change', handleDependentSelectedValue);
            }
            let dependentSelect = current_tab.querySelector('#dependent-field-select');
            if (dependentSelect) {
                dependentSelect.addEventListener('change', handleKeyDown);
            }

        }, 0);
    }

    const handleDependentSelectedValue = (selectedOption) => {
        var current_tab = document.getElementById("justify-tab-example-tabpane-" + keyId)
        var ele = current_tab.querySelector(".focused")

        // If you need to differentiate by name, you could check a custom attribute
        if (selectedOption) {
            const valueList = selectedOption.map(item => item.value);
            ele.setAttribute("dependent-field-option", valueList)
        }
        else {
            ele.removeAttribute("dependent-field-option")
        }
    }

    const handleKeyDown = (event) => {
        var current_tab = document.getElementById("justify-tab-example-tabpane-" + keyId)
        var ele = current_tab.querySelector(".focused")
        if (event.target.name === 'checkbox-textarea') {
            var checkBox_id = event.target.getAttribute('data-checkbox-id');
            var checkBox = document.getElementById(checkBox_id)
            checkBox.setAttribute("data-text", event.target.value)
            var label = checkBox.parentNode.querySelector('h5');
            if (checkBox.parentNode.contains(checkBox.parentNode.querySelector('label')))
            {
                label = checkBox.parentNode.querySelector('label');
            }
            label.innerHTML = event.target.value
        }
        else if(event.target.name === 'properties-min-value') {
            if (event.target.value !== '')
            {
                ele.setAttribute("minimum", event.target.value)
            }
            else {
                ele.removeAttribute("minimum")
            }
        }
        else if(event.target.name === 'properties-max-value') {
            if (event.target.value !== '')
            {
                ele.setAttribute("maximum", event.target.value)
            }
            else {
                ele.removeAttribute("maximum")
            }
        } else if (event.target.name === 'properties-input-label') {
            if (event.target.value !== '') {
                ele.setAttribute("name", event.target.value)
            } else {
                ele.removeAttribute("name")
            }
            if (ele.parentNode.contains(ele.parentNode.querySelector('label'))) {
                label = ele.parentNode.querySelector('label');
                label.innerHTML = event.target.value
            }
        } else if (event.target.name === 'dependent-field-select') {
            if (event.target.value !== '') {
                handleDependentFieldChange(event)
                ele.setAttribute("dependent-field", event.target.value)
            } else {
                ele.removeAttribute("dependent-field")
            }
        }
        else if (event.target.name === 'pre-populate') {
            if (event.target.value !== '') {
                ele.setAttribute("prepopulate", event.target.value)
            } else {
                ele.removeAttribute("prepopulate")
            }
        }
        else if (event.target.name === 'disable-pre-populate') {
            if (event.target.value !== '') {
                ele.setAttribute("disableonprepopulate", event.target.value)
            } else {
                ele.removeAttribute("disableonprepopulate")
            }
        }
        else if(event.target.name === 'properties-group-label') {
            if (event.target.value !== '')
            {
                ele.setAttribute("group_label", event.target.value)
            }
            else {
                ele.removeAttribute("group_label")
            }
        } else if (event.target.name === 'terms-textarea') {
            if (event.target.value && event.target.value.includes("'")) {
                // Replace the single inverted comma with 'drivers'
                event.target.value = event.target.value.replace("'", "");
            }
            if (event.target.value !== '') {
                ele.setAttribute("data-terms-url", event.target.value)
            } else {
                ele.removeAttribute("data-terms-url")
            }
        }
        else if (event.target.name === 'helper-textarea') {
            if (event.target.value && event.target.value.includes("'")) {
                // Replace the single inverted comma with 'drivers'
                event.target.value = event.target.value.replace("'", "");
            }
            if (event.target.value !== '') {
                ele.setAttribute("helper-text", event.target.value)
            } else {
                ele.removeAttribute("helper-text")
            }
        }
       else if (event.target.name === 'mask-field') {
            if (event.target.value !== '') {
                ele.setAttribute("mask-field", event.target.value)
            } else {
                ele.removeAttribute("mask-field")
            }
        }
        else if (event.target.name === 'date_range[]')
        {
            if (event.target.value !== '') {
                ele.setAttribute("date-range", event.target.value)
            } else {
                ele.removeAttribute("date-range")
            }
        }
        else {
            ele.setAttribute("maxlength", event.target.value)
        }

    };

    const saveSchema = async () => {
        var current_tab = document.getElementById("justify-tab-example-tabpane-" + keyId)
        var input_elements = current_tab.querySelectorAll(".drag-drop-element")
        const ids = [];
        input_elements.forEach((element) => {
            ids.push(element.id);
        });
        var inputElementsData = create_input_elements_data(ids, keyId)
        var builderSchema = createBuilderSchema(inputElementsData)

        var nextStep = 0
        var nextSteps = [];
        if(window.product_type !== 10002) {
            if (selectedDecisionTree.length !==0 && selectedDecisionTree > -1) {
                var dict = {}
                decTreeOutcomes.forEach((element) => {
                    for (const [key, value] of Object.entries(element)) {
                        var newvalue = value.replace(/\s/g, '');
                        if (current_tab.querySelector('#outcome-select-' + newvalue)) {
                            var selectedValue = current_tab.querySelector('#outcome-select-' + newvalue).value
                            var selectedId = current_tab.querySelector('#outcome-select-' + newvalue).getAttribute("data-outcome-id");
                            dict[selectedId] = selectedValue
                        }
                        else if (selectedTab && selectedTab[0] && selectedTab[0][key])
                        {
                            dict[key] = selectedTab[0][key]
                        }
                    }
                });
                nextSteps.push(dict);
            } else {
                nextStep = selectedTab
            }
        }

        var multi_dec_trees = [];
        if (multipleDecTrees) {
            nextSteps = []
            // multi_dec_trees = multipleDecTrees
            multi_dec_trees = multipleDecTrees;
            if (multi_dec_trees.length === 1) {
                // eslint-disable-next-line
                var dict = {}
                decTreeOutcomes.forEach((element) => {
                    // eslint-disable-next-line
                    for (const [key, value] of Object.entries(element)) {
                        var newvalue = value.replace(/\s/g, '');
                        if (current_tab.querySelector('#outcome-select-' + newvalue)) {
                            var selectedValue = current_tab.querySelector('#outcome-select-' + newvalue).value
                            var selectedId = current_tab.querySelector('#outcome-select-' + newvalue).getAttribute("data-outcome-id");
                            dict[selectedId] = selectedValue
                        }
                    }
                });
                nextSteps.push(dict);
            } else {
                nextStep = selectedTab
                if (nextStep.length === 0) {
                    window.show_alert("Please select a next step for this form.")
                    return
                }
            }
        }
        else if (window.product_type === 10002){
            setDecisionTree([])
            nextStep = selectedTab
            if (nextStep.length === 0) {
                window.show_alert("Please select a next step for this form.")
                return
            }
        }

        if (nextStep.length === 0 && nextSteps.length === 0) {
            window.show_alert("Please select a next step for this form.")
            return
        }

        var new_selected_decision_tree = null
        if (multi_dec_trees.length === 1)
        {
            new_selected_decision_tree = multi_dec_trees[0]["id"]
        }

        // for tenant product builder tenant_name field is required on first page
        if (firstStep && window.product_type === 10003) {
            const checkTenantName = ids.includes("tenant_name");

            if (!checkTenantName) {
                window.show_alert("Please drag and drop the tenant_name field, as it is required.");
                return false;
            }
        }
        var applicantRedirectURL = current_tab.querySelector('#applicant-redirect-url')?.value || ''

        setTimeout(() => {

            try {
                const postData = {
                    tab_id: keyId,
                    builder_schema:JSON.stringify(builderSchema, null, 2),
                    product: window.product_type,
                    product_referral: window.product_referral,
                    selected_provider: selectedProviders,
                    next_providers: nextProviders,
                    selected_decision_tree: new_selected_decision_tree ? new_selected_decision_tree: selectedDecisionTree, new_selected_decision_tree,
                    multi_decision_trees: multi_dec_trees,
                    multi_products: multipleProducts,
                    selected_tab: nextStep,
                    nextSteps: nextSteps,
                    first_step: firstStep,
                    test_mode: test_mode_val,
                    manual_processing_step: manualProcessingStep,
                    applicant_redirect_url: applicantRedirectURL,

                    // Other data fields you want to send
                };
                //todo : api call to save tab
                axios.post("/api/v1/save_schema/",
                    postData, {
                        headers: {
                            Authorization: "Basic " + ApiCredentials
                        },
                    }
                ).then(function (response) {
                    window.show_success('Form saved successfully.', 1)
                }).catch(function (error) {
                    window.show_alert(error.response.data.message);
                });
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }, 0);
    };
    const getDependentFieldAndOption = (builderSchema, label) => {
        let dependentField = null;
        let dependentOption = null;

        const traverseElements = (elements) => {
            elements.forEach(element => {
                if (element.dependentField) {
                    const conditionField = element.id

                    if (conditionField === label) {
                        dependentField = element.dependentField;
                        dependentOption = element.dependentFieldOption;
                    }
                }
                if (element.elements) {
                    traverseElements(element.elements);
                }
            });
        };
        traverseElements(builderSchema.fields);

        return {dependentField, dependentOption};
    };

    // const [variables, updatevariables] = useState(InputVariables);
    // Function to fetch data using Axios
    const fetchData = async () => {
        try {
            const response = await axios.get(`/api/v1/list_variables/?product=${window.product_type}&test_mode=${test_mode_val}`, {
                headers: {
                    Authorization: "Basic " + ApiCredentials
                },
            });
            updatevariables(response.data.results)
            setHasRunFunction(true);

        } catch (error) {
            console.error("Error fetching data:", error);
        }


        try {
            const response = await axios.get(`/api/v1/list_widgets/?product=${window.product_type}&test_mode=${test_mode_val}`, {
                headers: {
                    Authorization: "Basic " + ApiCredentials
                },
            });
            updateWidgets(response.data.results)
            setHasRunFunctionWidget(true);

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    // Call fetchData on component mount
    useEffect(() => {
        // if(selectedProviders.length == 0 && rules.providers.length > 0){
        //     setSelectedProviders(rules.providers)
        // }
        fetchData();
    }, []);

    useEffect(() => {
    if (!hasRunFunction) {
        if (schema && schema.fields) {
            // Iterate over each field in the schema
            schema.fields.forEach((schemaField) => {
                variables.forEach((inputVariable) => {
                    // Match variables with schema fields by 'id'
                    if (inputVariable.id === schemaField.id) {
                        // Update inputVariable properties based on the schema field
                        inputVariable['maxLength'] = schemaField.maxLength || '';
                        inputVariable['required'] = schemaField.required  ? true : false;
                        inputVariable['group_label'] = schemaField.group_label || '';
                        inputVariable['helperText'] = schemaField.helperText || '';
                        inputVariable['maskField'] = schemaField.maskField ? "true": "false";
                        inputVariable['prePopulate'] = schemaField.prePopulate || '';
                        inputVariable['disableOnPrePopulate'] = schemaField.disableOnPrePopulate || '';
                        inputVariable['label'] = schemaField.input_label || inputVariable.name;
                        inputVariable['minimum'] = schemaField.minimum ? schemaField.minimum : '';
                        inputVariable['maximum'] = schemaField.maximum ? schemaField.maximum : '';
                        inputVariable['date_range'] = schemaField.date_range ? schemaField.date_range : 'all';

                        // Update 'enum' values if they exist
                        if (schemaField.enum) {
                            inputVariable['enum'] = schemaField.enum;
                        }

                        // Handle dependent fields (if applicable)
                        const { dependentField, dependentOption } = getDependentFieldAndOption(schema, schemaField.id);
                        inputVariable['dependentField'] = dependentField;
                        inputVariable['dependentFieldOption'] = dependentOption;

                        FormInputVariablePresent.push(inputVariable);
                    }
                });
            });

            // Add any variables that aren't present in the schema fields to FormInputVariableNotPresent
            variables.forEach((inputVariable) => {
                if (!FormInputVariablePresent.includes(inputVariable)) {
                    FormInputVariableNotPresent.push(inputVariable);
                }
            });

            // Update states for variables present and not present in the schema
            updatevariables(FormInputVariableNotPresent);
            updateformvariables(FormInputVariablePresent);
            const extractedData = getConditonalFieldsOptions(FormInputVariablePresent);
            setConditionalFieldOptions(extractedData);
        }
    }
    // eslint-disable-next-line
}, [variables]);



    useEffect(() => {
        if (!hasRunFunctionWidget) {
            widgets.forEach((inputVariable) => {
                if (schema && schema.widgets && schema.widgets[inputVariable.id]) {
                    FormInputWidgetsPresent.push(inputVariable);
                } else {
                    FormInputWidgetsNotPresent.push(inputVariable);
                }
            });
            updateWidgets(FormInputWidgetsNotPresent)
            updateFormWidgets(FormInputWidgetsPresent)
        }
        // eslint-disable-next-line
    }, [widgets])


    const onDragStart = (start, provided) => {
        if (start.source.droppableId === 'variables' || start.source.droppableId === 'widgets' || start.source.droppableId === 'controls') {
        setIsDraggingFromUl(true);
        }
    };

    //handle drag and drop
    function handleOnDragEnd(result) {
        setIsDraggingFromUl(false);
        const {destination, source} = result;

        if (!destination) {
            return;
        }

        const invalidMoves = [
            { dest: "widgets", src: "formVariables", msg: "Dragging from form variables to widgets is not allowed." },
            { dest: "controls", src: "formVariables", msg: "Dragging from form variables to controls is not allowed." },
            { dest: "variables", src: "formWidgets", msg: "Dragging from form widgets to variables is not allowed." },
            { dest: "controls", src: "formWidgets", msg: "Dragging from form widgets to controls is not allowed." },
            { dest: "formControlsTop", src: "variables", msg: "Dragging from variables to form controls top is not allowed." },
            { dest: "formControlsTop", src: "widgets", msg: "Dragging from widgets to form controls top is not allowed." },

            { dest: "variables", src: "formControls", msg: "Dragging from controls to variables is not allowed." },
            { dest: "widgets", src: "formControls", msg: "Dragging from controls to widgets is not allowed." },
            { dest: "variables", src: "formControlsTop", msg: "Dragging from controls to variables is not allowed." },
            { dest: "widgets", src: "formControlsTop", msg: "Dragging from controls to widgets is not allowed." },
        ];

        for (const move of invalidMoves) {
            if (destination.droppableId === move.dest && source.droppableId === move.src) {
                window.show_alert(move.msg);
                return;
            }
        }


        // Handle reordering within the same list
    if (destination.droppableId === source.droppableId) {
        let items;
        switch (source.droppableId) {
            case 'variables':
                items = Array.from(variables);
                break;
            case 'formVariables':
                items = Array.from(formvariables);
                break;
            case 'widgets':
                items = Array.from(widgets);
                break;
            case 'formWidgets':
                items = Array.from(formwidgets);
                break;
            case 'controls':
                items = Array.from(Controls);
                break;
            case 'formControlsTop':
                items = Array.from(TopusedControls);
                break;
            case 'formControls':
                items = Array.from(usedControls);
                break;
            default:
                return;
        }

        const [movedItem] = items.splice(source.index, 1);
        items.splice(destination.index, 0, movedItem);

        switch (source.droppableId) {
            case 'variables':
                updatevariables(items);
                break;
            case 'formVariables':
                updateformvariables(items);
                break;
            case 'widgets':
                updateWidgets(items);
                break;
            case 'formWidgets':
                updateFormWidgets(items);
                break;
            case 'controls':
                setControls(items);
                break;
            case 'formControlsTop':
                setTopUsedControls(items);
                break;
            case 'formControls':
                setUsedControls(items);
                break;
            default:
                return;
        }

        return;
    }

        // if (
        //     destination.droppableId === source.droppableId &&
        //     destination.index === source.index
        // ) {
        //     return;
        // }

        let add;
        let active = variables;
        let complete = formvariables;
        // Source Logic
        const sourceIndexVariable = source.index;
        const originalSourceIndexVariable = variables.indexOf(filteredVariables[sourceIndexVariable]);
        if (source.droppableId === "variables" && destination.droppableId === "formVariables") {
            add = active[originalSourceIndexVariable];
            active.splice(originalSourceIndexVariable, 1);
        } else if (destination.droppableId === "variables" && source.droppableId === "formVariables") {
            add = complete[source.index];
            complete.splice(source.index, 1);
        }

        // Destination Logic
        if (destination.droppableId === "variables" && source.droppableId === "formVariables") {
            active.splice(destination.index, 0, add);
        } else if (source.droppableId === "variables" && destination.droppableId === "formVariables"){
            complete.splice(destination.index, 0, add);
        }

        complete = complete.filter(function( element ) {
            return element !== undefined;
         });

        active = active.filter(function( element ) {
            return element !== undefined;
         });

        updateformvariables(complete);
        updatevariables(active);


        active = widgets;
        complete = formwidgets;
        // Source Logic
        const sourceIndexWidget = source.index;
        const originalSourceIndexWidget = widgets.indexOf(filteredWidgets[sourceIndexWidget]);
        if (source.droppableId === "widgets") {
            add = active[originalSourceIndexWidget];
            if(complete.length > 0 && destination.droppableId === "formWidgets")
            {
                window.show_alert("One widget per page allowed. Remove previous widget")
                return false
            }
            active.splice(originalSourceIndexWidget, 1);
        } else if (destination.droppableId === "widgets" && source.droppableId === "formWidgets") {
            add = complete[source.index];
            complete.splice(source.index, 1);
        }

        // Destination Logic
        if (destination.droppableId === "widgets" && source.droppableId === "formWidgets") {
            active.splice(destination.index, 0, add);
        } else if (source.droppableId === "widgets")  {
            if(complete.length > 0 && destination.droppableId === "formWidgets")
            {
                window.show_alert("One widget per page allowed. Remove previous widget")
                return false
            }
            complete.splice(destination.index, 0, add);
        }

        complete = complete.filter(function( element ) {
            return element !== undefined;
         });

        active = active.filter(function( element ) {
            return element !== undefined;
         });

        updateFormWidgets(complete);
        updateWidgets(active);

        active = Controls;
        complete = usedControls;
        let completetop = TopusedControls;
        // Source Logic
        const sourceIndexControl = source.index;
        const originalSourceIndexControl = Controls.indexOf(filteredControls[sourceIndexControl]);
        if (source.droppableId === "controls") {
            add = active[originalSourceIndexControl];
        } else {
            add = complete[source.index];
        }
        if(firstStep && add.type === "document_upload"){
            window.show_alert("Document upload is not allowed on the first tab.")
            return
        }


        // Destination Logic
        if (destination.droppableId === "controls" && (source.droppableId === "formControls" || source.droppableId === "formControlsTop")) {
            if (source.droppableId === "formControlsTop")
                {
                    if (completetop.length === 1)
                    {
                        completetop = []
                    }
                    else {
                        completetop.splice(source.index, 1);
                    }
                }
            else {
                if (complete.length === 1)
                {
                    complete = []
                }
                else {
                    complete.splice(source.index, 1);
                }
            }
        }
        else if(destination.droppableId === "formControlsTop")
        {
            completetop.splice(destination.index, 0, add);
        }
        else if(source.droppableId === "controls") {
            complete.splice(destination.index, 0, add);
        }

        complete = complete.filter(function( element ) {
            return element !== undefined;
         });

        active = active.filter(function( element ) {
            return element !== undefined;
         });
        setUsedControls(complete)
        setControls(active)
        setTopUsedControls(completetop)
        // setUs(complete);
        // updateWidgets(active);

        setTimeout(() => {
            var current_tab = document.getElementById("justify-tab-example-tabpane-" + keyId)
            var input_elements = current_tab.querySelectorAll(".drag-drop-element")
            const ids = [];
            input_elements.forEach((element) => {
                ids.push(element.id);
            });
        }, 0);
    }

    function handleContinue() {
        setshowProperties(0)
    }


    function formatPhoneNumber(value) {
        // Remove non-digit characters
        const input = value.replace(/\D/g, "");

        // Format input according to (123) 456 - 7890 pattern
        const areaCode = input.slice(0, 3);
        const firstPart = input.slice(3, 6);
        const secondPart = input.slice(6, 10);

        let formattedNumber = "";
        if (areaCode) {
          formattedNumber += `(${areaCode}`;
        }
        if (firstPart) {
          formattedNumber += `) ${firstPart}`;
        }
        if (secondPart) {
          formattedNumber += ` - ${secondPart}`;
        }

        return formattedNumber;
    }


    // handle mobile otp widget input
    function handleMobileInputWidget(event)
    {
        const formattedValue = formatPhoneNumber(event.target.value);
        setmobileWidgetPhoneInput(formattedValue);
    }

    
    function sanitizeKeyId(keyId) {
        return keyId.replace(/[^a-zA-Z0-9-_]/g, '_'); // Replace invalid characters with '_'
    }

    return (<>
        <div className="App">
            <div className='d-flex justify-content-md-end justify-content-sm-start align-items-center'>
                <div className='w-sm-25 d-flex justify-content-start align-items-center flex-wrap gap-2 me-2'>
                    <button className='btn btn-success btn-success-sm' style={{width: "125px"}} onClick={saveSchema}>Save form</button>
                    {<a className="btn btn-secondary btn-secondary-sm" style={{width: "125px"}} target="_blank" rel='noreferrer' href={`/application/preview/${window.product_referral}/`}>Preview</a>}
                </div>
            </div>
            <header className="App-header">
                <div className='d-flex flex-wrap flex-sm-nowrap justify-content-sm-between gap-2 me-3'>

                    <DragDropContext onDragStart={onDragStart} onDragEnd={handleOnDragEnd}>
                        {/* THIS IS FOR INPUT VARIABLES */}

                    <div className="card w-100 w-sm-25 border position-relative">
                        <div className="d-flex flex-column justify-content-center position-sticky top-0" style={{ zIndex: 999, backgroundColor: "#fff" }}>
                            <ul className="nav nav-fill p-3 pb-2 px-0 gap-1 flex-row" role="tablist">
                                <li className="nav-item" role="presentation">
                                        <a className="nav-link mb-0 px-0 py-1 active" data-bs-toggle="tab" href={`#variable_tab_${sanitizeKeyId(keyId)}`}  role="tab" aria-controls="cam1" aria-selected="false" tabIndex="-1">
                                        Variables
                                    </a>
                                </li>
                                <li className="nav-item" role="presentation">
                                        <a className="nav-link mb-0 px-0 py-1" data-bs-toggle="tab" href={`#widget_tab_${sanitizeKeyId(keyId)}`} role="tab" aria-controls="cam2" aria-selected="true">
                                        Widgets
                                    </a>
                                </li>
                                   <li className="nav-item" role="presentation">
                                    <a className="nav-link mb-0 px-0 py-1" data-bs-toggle="tab"
                                            href={`#control_tab_${sanitizeKeyId(keyId)}`} role="tab" aria-controls="cam2"
                                       aria-selected="true">
                                        Controls
                                    </a>
                                </li>
                            </ul>

                            <input type="text" name="filterQuery" id="filterQuery"  placeholder='Search...' className='mb-2' style={{fontSize: "16px"}} value={filterQuery} onChange={(e) => setFilterQuery(e.target.value)}/>
                        </div>
                        <div className="tab-content border-radius-lg h-100" id="v-pills-tabContent">
                            <Droppable droppableId="variables">
                                {(provided) => (
                                        <div className="tab-pane fade show position-relative active border-radius-lg h-100" id={`variable_tab_${sanitizeKeyId(keyId)}`} role="tabpanel" aria-labelledby="overview" loading="lazy">
                                        <ul className="variables h-100" id="variables-ul" {...provided.droppableProps} ref={provided.innerRef} style={{
                                            backgroundColor: isDraggingFromUl ? '#e9e1ff' : 'white', // Change the color based on the state
                                            borderRadius: '12px',
                                            }}>
                                            {filteredVariables.map(({ id, name, value, type }, index) => {
                                                return (
                                                    <Draggable key={id} draggableId={id} index={index}>
                                                        {(provided) => (
                                                            <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} class="d-flex justify-content-center align-items-center">
                                                                {/* Your content for draggable items in Variables tab */}
                                                                <p className='w-100 d-flex gap-2
                                                                    justify-content-start align-items-center flex-wrap mt-2 p-2 mb-2 ms-2' style={{fontSize: "0.9rem"}}>
                                                                    <span className='me-3'>
                                                                        {type === "numeric" ? <i className="fa-solid fa-hashtag"></i> : ""}
                                                                        {type === "string" ? <i className="fa-solid fa-font"></i> : ""}
                                                                        {type === "boolean" ? <i className="fa-solid fa-square-check"></i> : ""}
                                                                        {type === "date" ? <i className="fa-solid fa-calendar-days"></i> : ""}
                                                                        {type === "list" ? <i className="fa-solid fa-list-ul"></i> : ""}
                                                                    </span>

                                                                    <div className='text-start' style={{ wordWrap: "break-word", overflowWrap: "break-word", maxWidth: "100%"}}>
                                                                        {name}
                                                                    </div>
                                                                </p>
                                                            </li>
                                                        )}
                                                    </Draggable>
                                                );
                                            })}
                                            {provided.placeholder}
                                        </ul>
                                    </div>
                                )}
                            </Droppable>

                            <Droppable droppableId="widgets">
                                {(provided) => (
                                        <div className="tab-pane fade position-relative border-radius-lg h-100" id={`widget_tab_${sanitizeKeyId(keyId)}`} role="tabpanel" aria-labelledby="overview" loading="lazy">
                                        <ul className="variables h-100" id="widgets-ul" {...provided.droppableProps} ref={provided.innerRef} style={{
                                            backgroundColor: isDraggingFromUl ? '#e9e1ff' : 'white', // Change the color based on the state
                                            borderRadius: '12px',
                                            }}>
                                            {filteredWidgets.map(({ id, name, value, type }, index) => {
                                                return (
                                                    <Draggable key={id} draggableId={id} index={index}>
                                                        {(provided) => (
                                                            <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} class="d-flex justify-content-center align-items-center">
                                                                {/* Your content for draggable items in Widgets tab */}
                                                                <p className='w-100 d-flex gap-2
                                                                    justify-content-start align-items-center flex-wrap mt-2 p-2 mb-2 ms-2' style={{fontSize: "0.9rem"}}>
                                                                    <span className='me-3'>
                                                                        {type === "string" ? <i className="fa-solid fa-font"></i> : ""}
                                                                    </span>

                                                                    <div className=''>
                                                                        {name}
                                                                    </div>
                                                                </p>
                                                            </li>
                                                        )}
                                                    </Draggable>
                                                );
                                            })}
                                            {provided.placeholder}
                                        </ul>
                                    </div>
                                )}
                            </Droppable>
                            <Droppable droppableId="controls">
                                {(provided) => (
                                    <div className="tab-pane fade position-relative border-radius-lg h-100"
                                            id={`control_tab_${sanitizeKeyId(keyId)}`}  role="tabpanel" aria-labelledby="overview"
                                         loading="lazy">
                                        <ul className="variables h-100" id="controls-ul" {...provided.droppableProps}
                                            ref={provided.innerRef} style={{
                                                backgroundColor: isDraggingFromUl ? '#e9e1ff' : 'white', // Change the color based on the state
                                                borderRadius: '12px',
                                                }}>
                                            {filteredControls.map(({id, name, text, type}, index) => {
                                                return (
                                                    <Draggable key={id} draggableId={id} index={index}>
                                                        {(provided) => (
                                                            <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} class="d-flex justify-content-center align-items-center">
                                                                {/* Your content for draggable items in Widgets tab */}
                                                                <p className='w-100 d-flex gap-2
                                                                    justify-content-start align-items-center flex-wrap mt-2 p-2 mb-2 ms-2' style={{fontSize: "0.9rem"}}>
                                                                    <span className='me-3'>
                                                                        {type === "checkbox" ?
                                                                            <i className="fa-solid fa-circle me-2"></i> : type === "label" ?
                                                                                <i className="fa-solid fa-tags me-2"></i> : type === "document_upload" ?
                                                                                    <i className="fa-solid fa-upload me-2"></i> : type === "document_display" ?
                                                                                        <i className="fa-solid fa-file me-2"></i> : type === "scroll_to_bottom" ?
                                                                                            <i className="fa-solid fa-circle-down me-2"></i>: type === "rich_text_editor" ? <i class="fa-solid fa-font me-2"></i> : ""}
                                                                    </span>

                                                                    <div className=''>
                                                                        {name}
                                                                    </div>
                                                                </p>
                                                            </li>
                                                        )}
                                                    </Draggable>
                                                );
                                            })}
                                            {provided.placeholder}
                                        </ul>
                                    </div>
                                )}
                            </Droppable>
                        </div>
                    </div>


                        {/* THIS IS FOR FORM VARIABLES */}
                                <div className='card w-100 w-sm-45 border'>
                                    <h3 className='mx-auto mt-4'>{heading}</h3>

                                    <Droppable droppableId="formControlsTop">
                                {(provided) => (
                                    <div className="row p-3" {...provided.droppableProps}
                                         ref={provided.innerRef}>
                                        { /* eslint-disable-next-line */
                                        TopusedControls.map(({id, name, text, type, required, label_font_size, label_alignment}, index) => {
                                            if (type === "label" || type === "toplabel")
                                            {
                                                return (
                                                    <Draggable key={'top_label_control_' + keyId +index} draggableId={'top_label_control_' + keyId + index} index={index}>
                                                        {(provided) => (
                                                            <div className="row my-1" onClick={handleFocus}
                                                                 ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                <div className="my-1" style={{textAlign:label_alignment}}>
                                                                    <label
                                                                    style={{ fontSize: label_font_size && label_font_size !== "" ? `${label_font_size}px` : '10px' }}

                                                                    name="top_label_control"
                                                                    id={'top_label_control' + keyId + index}
                                                                    type="toplabel"
                                                                    data-text={text}
                                                                    label-alignment={label_alignment}
                                                                    label-font-size={label_font_size && label_font_size !== "" ? label_font_size : 10}
                                                                    data-isRequired={required}
                                                                    className="col-auto m-0 drag-drop-element" htmlFor={'top_label_control' + keyId + index} dangerouslySetInnerHTML={{__html: text}} ></label>

                                                                </div>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            }
                                            else if (type === "rich_text_editor" || type === "rich_text_editor_top_control") {
                                                const editorId = `rich_text_editor_top${keyId}${index}`;
                                                const editorContent = richTextValues[editorId] || text || "";
                                                return (
                                                    <Draggable key={'rich_text_editor_top' + keyId + index}
                                                               draggableId={'rich_text_editor_top' + keyId + index}
                                                               index={index}>
                                                        {(provided) => (
                                                            <div className="row mt-2" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                <div className='drag-drop-element' id={'rich_text_editor_top' + keyId + index} data-text={editorContent} name="rich_text_editor_top_control" type="rich_text_editor_top_control">
                                                                    <RichTextEditor editorContent={editorContent} id={`rich_text_editor_top${keyId}${index}`} onChange={(value, editorId) => handleRichTextEditorChange1(value, editorId)} />
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            }
                                        })}
                                        {provided.placeholder}
                                    </div>
                                )}
                                </Droppable>

                                    <Droppable droppableId="formVariables">
                                        {(provided, snapshot) => (
                                    <div className="row p-3" {...provided.droppableProps}
                                         ref={provided.innerRef}>
                                        {
                                        formwidgets.length === 0 && formvariables.length === 0 ? (
                                            <div className="empty-container row mx-auto">
                                                <i class="fa-solid fa-share-from-square fs-4"></i>
                                            <p className='fs-5'>Please drag and drop variables here</p>
                                            </div>
                                        ) :
                                        formvariables.map(({id, name,label, value, type, required, maxLength,helperText, prePopulate, maskField, disableOnPrePopulate , minimum, maximum, group_label, dependentField, dependentFieldOption, display_type, date_range}, index) => {
                                            if (type === "string") {
                                                return (
                                                    <Draggable key={id} draggableId={id} index={index}>
                                                        {(provided) => (
                                                            <div
                                                                className='w-50 col-3 input-variables input-group input-group-static mb-4'
                                                                ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                <label>
                                                                    {label ? label : name}&nbsp;
                                                                    {required ?
                                                                        <i className="fa-solid fa-asterisk fa-xs me-1 text-danger"></i> : ''}

                                                                </label>
                                                                <input type="string" onFocus={handleFocus} mask-field={maskField}
                                                                       data-isRequired={required} id={id} placeholder="" helper-text={helperText} disableOnPrePopulate={disableOnPrePopulate} prePopulate={prePopulate}
                                                                       dependent-field={dependentField} dependent-field-option={dependentFieldOption}
                                                                       name={label ? label : name} maxLength={maxLength} group_label={group_label}
                                                                       className='form-control drag-drop-element mt-n1 pb-0'></input>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            } else if (type === "list") {
                                                const options = value.map((val, index) => {
                                                    return (
                                                        <option key={index} value={val.value}>
                                                            {val.displayValue}
                                                        </option>
                                                    );
                                                });

                                                return (
                                                    <Draggable key={id} draggableId={id} index={index}>
                                                        {(provided) => (
                                                            <div
                                                                className='w-50 col-3 input-variables input-group input-group-static mb-4'
                                                                ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                <label>
                                                                    {label ? label : name}&nbsp;                                                                                      {required ?
                                                                        <i className="fa-solid fa-asterisk fa-xs me-1 text-danger"></i> : ''}
                                                                </label>
                                                                <select type="list" id={id} name={label ? label : name} data-isRequired={required} group_label={group_label} disableOnPrePopulate={disableOnPrePopulate} prePopulate={prePopulate}
                                                                        onFocus={handleFocus} dependent-field={dependentField} dependent-field-option={dependentFieldOption} helper-text={helperText}
                                                                        className='form-control mt-n1 drag-drop-element'>
                                                                    {options}
                                                                </select>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            } else if (type === "boolean") {
                                                return (
                                                    <Draggable key={id} draggableId={id} index={index}>
                                                        {(provided) => (
                                                            <div
                                                            className='w-50 col-3 input-variables input-group input-group-static mb-4'
                                                            ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                            <label>
                                                                {label ? label : name}&nbsp;                                                                                  {required ?
                                                                    <i className="fa-solid fa-asterisk fa-xs me-1 text-danger"></i> : ''}
                                                            </label>
                                                            <select type="boolean" id={id} name={label ? label : name} data-isRequired={required} group_label={group_label} disableOnPrePopulate={disableOnPrePopulate} prePopulate={prePopulate}
                                                                    onFocus={handleFocus} dependent-field={dependentField} helper-text={helperText} dependent-field-option={dependentFieldOption}
                                                                    className='form-control mt-n1 drag-drop-element'>
                                                                    <option key={index} value='Yes'>
                                                                        Yes
                                                                    </option>
                                                                    <option key={index} value='No'>
                                                                        No
                                                                    </option>
                                                            </select>
                                                        </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            }
                                            else if (type === "date") {
                                                return (
                                                    <Draggable key={id} draggableId={id} index={index}>
                                                        {(provided) => (
                                                            <div
                                                                className='w-50 col-3 input-variables input-group input-group-static mb-4'
                                                                ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                <label>
                                                                    {label ? label : name}&nbsp; {required ?
                                                                        <i className="fa-solid fa-asterisk fa-xs me-1 text-danger"></i> : ''}
                                                                </label>
                                                                <input type="date" onFocus={handleFocus} helper-text={helperText}
                                                                       data-isRequired={required} id={id} placeholder="" disableOnPrePopulate={disableOnPrePopulate}
                                                                       dependent-field={dependentField} dependent-field-option={dependentFieldOption}
                                                                       prePopulate={prePopulate}
                                                                       name={label ? label : name} group_label={group_label}
                                                                       date-range = {date_range}
                                                                       className='form-control drag-drop-element mt-n1 pb-0'></input>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            }
                                            else if (type === "numeric") {
                                                return (
                                                    <Draggable key={id} draggableId={id} index={index}>
                                                        {(provided) => (
                                                            <div
                                                                className='w-50 col-3 input-variables input-group input-group-static mb-4'
                                                                ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                <label>
                                                                    {label ? label : name}&nbsp;                                                                                      {required ?
                                                                        <i className="fa-solid fa-asterisk fa-xs me-1 text-danger"></i> : ''}
                                                                </label>
                                                                <input type="numeric" onFocus={handleFocus}
                                                                       data-isRequired={required} id={id} placeholder=""
                                                                       name={label ? label : name}
                                                                       minimum={minimum} helper-text={helperText} disableOnPrePopulate={disableOnPrePopulate}
                                                                       dependent-field={dependentField} dependent-field-option={dependentFieldOption}prePopulate={prePopulate}
                                                                       maximum={maximum} group_label={group_label} display_type={display_type}
                                                                       className='form-control drag-drop-element mt-n1 pb-0'></input>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            }
                                            else {
                                                    return (
                                                        <Draggable key={id} draggableId={id} index={index}>
                                                            {(provided) => (
                                                                <div
                                                                    className='w-50 col-3 input-variables input-group input-group-static mb-4'
                                                                    ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>                                                                    <label>
                                                                        {label ? label : name}&nbsp;                                                                                          {required ?
                                                                        <i className="fa-solid fa-asterisk fa-xs me-1 text-danger"></i> : ''}
                                                                    </label>
                                                                    <input type="numeric" onFocus={handleFocus} helper-text={helperText} mask-field={maskField}
                                                                           data-isRequired={required} id={id} placeholder="" disableOnPrePopulate={disableOnPrePopulate} prePopulate={prePopulate}
                                                                           name={label ? label : name} group_label={group_label}
                                                                           className='form-control drag-drop-element mt-n1 pb-0'></input>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    );
                                            }

                                        })}
                                        {provided.placeholder}
                                    </div>
                                    )}
                                    </Droppable>


                                <Droppable droppableId="formWidgets">
                                {(provided) => (
                                    <div className="row p-3" {...provided.droppableProps}
                                         ref={provided.innerRef}>
                                        {
                                        formwidgets.map(({id, name, value, type, required}, index) => {
                                            if (value === "offer_agreement" || name === "offer_agreement" || id === "offer_agreement" || value === "prequalification_offer" || name === "prequalification_offer" || id === "prequalification_offer")
                                            {
                                                return (
                                                    <Draggable key={id} draggableId={id} index={index}>
                                                        {(provided) => (
                                                             <div class="row mt-3" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                             <div class="col-12">
                                                                 <iframe id={id} btn className="shadow drag-drop-element" title={id} height="500px" width="100%"
                                                                         src={window.pdf_url + '#toolbar=0&navpanes=0&view=fitH'}></iframe>
                                                             </div>
                                                         </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            }
                                            else if (value === "multi_agreements" || name === "Multi Agreements" || id === "multi_agreements")
                                            {
                                                return (
                                                    <Draggable key={id} draggableId={id} index={index}>
                                                        {(provided) => (
                                                             <div class="row mt-3" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                             <div class="col-12">
                                                                 <iframe id={id} btn className="shadow drag-drop-element" title={id}height="500px" width="100%"
                                                                         src={window.pdf_url + '#toolbar=0&navpanes=0&view=fitH'}></iframe>
                                                             </div>
                                                         </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            }
                                            else if (value === "agreement" || name === "agreement" || id === "agreement")
                                            {
                                                return (
                                                    <Draggable key={id} draggableId={id} index={index}>
                                                        {(provided) => (
                                                             <div className="row mt-3" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                             <div className="col-1 mt-3">
                                                             <input type="checkbox" id={id} placeholder="" name={id}
                                                                       className='mt-n1 me-3 drag-drop-element'></input>
                                                             </div>
                                                             <div className="col-5 mt-1">
                                                                <p>Agreement Text</p>
                                                             </div>
                                                             <div className="col-6 mt-3 d-flex justify-content-between">
                                                                <a href="/">Link 1</a>
                                                                <a href="/">Link 2</a>
                                                                <a href="/">Link 3</a>
                                                             </div>
                                                         </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            }
                                            else if (value === "review" || name === "review" || id === "review")
                                            {
                                                return (
                                                    <Draggable key={id} draggableId={id} index={index}>
                                                        {(provided) => (
                                                             <div class="col-12 col-lg-8 mx-auto my-2" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                             <div class="card border">
                                                                 <div class="card-body d-flex justify-content-center">
                                                                 <form id={id} className="drag-drop-element">

                                                                         <header>
                                                                             <h2 class="header-title text-center">In Review</h2>
                                                                         </header>
                                                                         <div class="row mb-5 text-center">
                                                                             Your application is in Review
                                                                         </div>
                                                                     </form>
                                                                 </div>
                                                             </div>
                                                         </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            }
                                            else if (value === "decline" || name === "decline" || id === "decline")
                                            {
                                                return (
                                                    <Draggable key={id} draggableId={id} index={index}>
                                                        {(provided) => (
                                                             <div class="col-12 col-lg-8 mx-auto my-2" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                             <div class="card border">
                                                                 <div class="card-body d-flex justify-content-center">
                                                                 <form id={id} className="drag-drop-element">

                                                                         <header>
                                                                             <h2 class="header-title text-center">In Decline</h2>
                                                                         </header>
                                                                         <div class="row mb-5 text-center">
                                                                             Your application declined
                                                                         </div>
                                                                     </form>
                                                                 </div>
                                                             </div>
                                                         </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            }
                                            else if (value === "approve" || name === "approve" || id === "approve")
                                            {
                                                return (
                                                    <Draggable key={id} draggableId={id} index={index}>
                                                        {(provided) => (
                                                             <div class="col-12 col-lg-8 mx-auto my-2" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                             <div class="card border">
                                                                 <div class="card-body d-flex justify-content-center">
                                                                 <form id={id} className="drag-drop-element">

                                                                         <header>
                                                                             <h2 class="header-title text-center">Congrats!</h2>
                                                                         </header>
                                                                         <div class="row mb-5 text-center">
                                                                         Your application got approved
                                                                         </div>
                                                                     </form>
                                                                 </div>
                                                             </div>
                                                         </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            }
                                            else if (value === "plaid_asset" || name === "Plaid Asset" || id === "plaid_asset")
                                            {
                                                return (
                                                    <Draggable key={id} draggableId={id} index={index}>
                                                        {(provided) => (
                                                             <div class="col-12 col-lg-8 mx-auto my-2" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                             <div class="card border">
                                                                 <div class="card-body d-flex justify-content-center">
                                                                 <form id={id} className="drag-drop-element">

                                                                         <header>
                                                                             <h2 class="header-title text-center">Plaid Asset</h2>
                                                                         </header>
                                                                         <div class="row mb-5 text-center">
                                                                             Calling Plaid Asset Provider...
                                                                         </div>
                                                                     </form>
                                                                 </div>
                                                             </div>
                                                         </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            }
                                            else if (value === "mobile_otp" || name === "Mobile Otp" || id === "mobile_otp")
                                                {
                                                    return (
                                                        <Draggable key={id} draggableId={id} index={index}>
                                                            {(provided) => (
                                                                 <div class="col-12 col-lg-8 mx-auto my-2" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                 <div class="card border">
                                                                     <div class="card-body d-flex justify-content-center">
                                                                     <form id={id} className="drag-drop-element">

                                                                             <header>
                                                                                 <h2 class="header-title text-center">Mobile OTP</h2>
                                                                             </header>
                                                                             <div class="row mb-5 text-center input-group w-auto">
                                                                             <input class="form-control border p-2" placeholder="(XXX) XXX - XXXX" onChange={handleMobileInputWidget} value={mobileWidgetPhoneInput}/>
                                                                             </div>
                                                                         </form>
                                                                     </div>
                                                                 </div>
                                                             </div>
                                                            )}
                                                        </Draggable>
                                                    );
                                                }
                                            else if (value === "plaid_cra_base" || name === "Plaid CRA Base" || id === "plaid_cra_base")
                                            {
                                                return (
                                                    <Draggable key={id} draggableId={id} index={index}>
                                                        {(provided) => (
                                                             <div class="col-12 col-lg-8 mx-auto my-2" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                             <div class="card border">
                                                                 <div class="card-body d-flex justify-content-center">
                                                                 <form id={id} className="drag-drop-element">

                                                                         <header>
                                                                             <h2 class="header-title text-center">Plaid</h2>
                                                                         </header>
                                                                         <div class="row mb-5 text-center">
                                                                             Calling Plaid provider... Please wait!
                                                                         </div>
                                                                     </form>
                                                                 </div>
                                                             </div>
                                                         </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            }
                                            else if (value === "plaid_cra_income" || name === "Plaid CRA Income" || id === "plaid_cra_income")
                                            {
                                                return (
                                                    <Draggable key={id} draggableId={id} index={index}>
                                                        {(provided) => (
                                                             <div class="col-12 col-lg-8 mx-auto my-2" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                             <div class="card border">
                                                                 <div class="card-body d-flex justify-content-center">
                                                                 <form id={id} className="drag-drop-element">

                                                                         <header>
                                                                             <h2 class="header-title text-center">Plaid Income</h2>
                                                                         </header>
                                                                         <div class="row mb-5 text-center">
                                                                             Calling Plaid provider... Please wait!
                                                                         </div>
                                                                     </form>
                                                                 </div>
                                                             </div>
                                                         </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            }
                                            else if (value === "plaid_cra_partner" || name === "Plaid CRA Partner" || id === "plaid_cra_partner")
                                            {
                                                return (
                                                    <Draggable key={id} draggableId={id} index={index}>
                                                        {(provided) => (
                                                             <div class="col-12 col-lg-8 mx-auto my-2" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                             <div class="card border">
                                                                 <div class="card-body d-flex justify-content-center">
                                                                 <form id={id} className="drag-drop-element">

                                                                         <header>
                                                                             <h2 class="header-title text-center">Plaid Partner</h2>
                                                                         </header>
                                                                         <div class="row mb-5 text-center">
                                                                             Calling Plaid provider... Please wait!
                                                                         </div>
                                                                     </form>
                                                                 </div>
                                                             </div>
                                                         </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            }
                                            else if (value === "payment_schedule_widget" || name === "Payment Schedule" || id === "payment_schedule_widget")
                                                {
                                                    return (
                                                        <Draggable key={id} draggableId={id} index={index}>
                                                            {(provided) => (
                                                                 <div class="col-12 col-lg-8 mx-auto my-2" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                 <div class="card border">
                                                                     <div class="card-body d-flex justify-content-center">
                                                                     <form id={id} className="drag-drop-element">

                                                                             <header>
                                                                                 <h2 class="header-title text-center">Payment Schedule</h2>
                                                                             </header>
                                                                             <div class="row mb-5 text-center">
                                                                                 Calling amortization provider... Please wait!
                                                                             </div>
                                                                         </form>
                                                                     </div>
                                                                 </div>
                                                             </div>
                                                            )}
                                                        </Draggable>
                                                    );
                                                }
                                            else if (value === "decision_logic" || name === "Decision Logic" || id === "decision_logic")
                                            {
                                                return (
                                                    <Draggable key={id} draggableId={id} index={index}>
                                                        {(provided) => (
                                                             <div className="col-12 col-lg-8 mx-auto my-2" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                             <div className="card border">
                                                                 <div className="card-body d-flex justify-content-center">
                                                                 <form id={id} className="drag-drop-element">

                                                                         <header>
                                                                             <h2 className="header-title text-center">Decision Logic</h2>
                                                                         </header>
                                                                     </form>
                                                                 </div>
                                                             </div>
                                                         </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            }
                                            else if (value === "plaid_auth" || name === "Plaid Auth" || id === "plaid_auth")
                                            {
                                                return (
                                                    <Draggable key={id} draggableId={id} index={index}>
                                                        {(provided) => (
                                                             <div class="col-12 col-lg-8 mx-auto my-2" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                             <div class="card border">
                                                                 <div class="card-body d-flex justify-content-center">
                                                                 <form id={id} className="drag-drop-element">

                                                                         <header>
                                                                             <h2 class="header-title text-center">Plaid Auth</h2>
                                                                         </header>
                                                                         <div class="row mb-5 text-center">
                                                                             Calling Plaid Auth provider...
                                                                         </div>
                                                                     </form>
                                                                 </div>
                                                             </div>
                                                         </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            }
                                            else if (value === "flinks_and_welspot" || name === "Flinks" || id === "flinks_and_welspot")
                                            {
                                                return (
                                                    <Draggable key={id} draggableId={id} index={index}>
                                                        {(provided) => (
                                                             <div class="col-12 col-lg-8 mx-auto my-2" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                             <div class="card border">
                                                                 <div class="card-body d-flex justify-content-center">
                                                                 <form id={id} className="drag-drop-element">

                                                                         <header>
                                                                             <h2 class="header-title text-center">Flinks</h2>
                                                                         </header>
                                                                         <div class="row mb-5 text-center">
                                                                             Calling Flinks Provider...
                                                                         </div>
                                                                     </form>
                                                                 </div>
                                                             </div>
                                                         </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            }
                                            else if (value === "loan_offers" || name === "Loan Offers" || id === "loan_offers")
                                            {
                                                return (
                                                    <Draggable key={id} draggableId={id} index={index}>
                                                        {(provided) => (
                                                             <div class="col-12 col-lg-12 mx-auto my-2" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                             <div class="card border">
                                                                 <div class="card-body d-flex justify-content-center">
                                                                 <form id={id} className="drag-drop-element">

                                                                         <header>
                                                                             <h2 class="header-title text-center">Loan Offers</h2>
                                                                         </header>
                                                                         <div class="row mb-5 text-center">
                                                                            Please wait for your offer, it can take up to one minute to process
                                                                         </div>
                                                                     </form>
                                                                 </div>
                                                             </div>
                                                         </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            }
                                            else if (value === "multi_offers" || name === "Multi Offers" || id === "multi_offers")
                                            {
                                                return (
                                                    <Draggable key={id} draggableId={id} index={index}>
                                                        {(provided) => (
                                                             <div class="col-12 col-lg-12 mx-auto my-2" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                             <div class="card border">
                                                                 <div class="card-body d-flex justify-content-center">
                                                                 <form id={id} className="drag-drop-element">

                                                                         <header>
                                                                             <h2 class="header-title text-center">Multi Offers</h2>
                                                                         </header>
                                                                         <div class="row mb-5 text-center">
                                                                            Please wait for your offer, it can take up to one minute to process
                                                                         </div>
                                                                     </form>
                                                                 </div>
                                                             </div>
                                                         </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            }
                                            else if (value === "prove" || name === "Prove" || id === "prove")
                                            {
                                                return (
                                                    <Draggable key={'prove'} draggableId={'prove'} index={index}>
                                                        {(provided) => (
                                                             <div class="col-12 col-lg-12 mx-auto my-2" onClick={handleFocus} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                             <div class="card border">
                                                                 <div class="card-body d-flex justify-content-center drag-drop-element" type="widget" onClick={handleFocus} id="prove" name="prove" data-terms-url="">
                                                                 <form  className="" style={{"pointer-events":"none"}} disabled={true}>

                                                                             <h2 class="header-title text-center" disabled={true}>Prove</h2>
                                                                     </form>
                                                                 </div>
                                                             </div>
                                                         </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            }
                                            else if (value === "chirp_verification" || name === "Chirp Verification" || id === "chirp_verification")
                                            {
                                                return (
                                                    <Draggable key={id} draggableId={id} index={index}>
                                                        {(provided) => (
                                                             <div class="col-12 col-lg-8 mx-auto my-2" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                             <div class="card border">
                                                                 <div class="card-body d-flex justify-content-center">
                                                                 <form id={id} className="drag-drop-element">

                                                                         <header>
                                                                             <h2 class="header-title text-center">Chirp Verification</h2>
                                                                         </header>
                                                                         <div class="row mb-5 text-center">
                                                                             Calling Chirp Verification... Please wait!
                                                                         </div>
                                                                     </form>
                                                                 </div>
                                                             </div>
                                                         </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            }
                                            else if (value === "product_order_widget" || name === "Product Order Widget" || id === "product_order_widget")
                                                {
                                                    return (
                                                        <Draggable key={id} draggableId={id} index={index}>
                                                            {(provided) => (
                                                                 <div class="col-12 col-lg-8 mx-auto my-2" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                 <div class="card border">
                                                                     <div class="card-body d-flex justify-content-center">
                                                                     <form id={id} className="drag-drop-element">

                                                                             <header>
                                                                                 <h5 class="header-title text-center">Product Order Widget</h5>
                                                                             </header>
                                                                             <div class="row mb-5 text-center">
                                                                                Configure Products on Manual Processing
                                                                             </div>
                                                                         </form>
                                                                     </div>
                                                                 </div>
                                                             </div>
                                                            )}
                                                        </Draggable>
                                                    );
                                                }
                                                else if (value === "payroll_selection_widget" || name === "Payroll Selection Widget" || id === "payroll_selection_widget")
                                                    {
                                                        return (
                                                            <Draggable key={id} draggableId={id} index={index}>
                                                                {(provided) => (
                                                                     <div class="col-12 col-lg-8 mx-auto my-2" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                     <div class="card border">
                                                                         <div class="card-body d-flex justify-content-center">
                                                                         <form id={id} className="drag-drop-element">

                                                                                 <header>
                                                                                     <h5 class="header-title text-center">Payroll Selection Widget</h5>
                                                                                 </header>
                                                                                 <div class=" mb-5 text-center">
                                                                                 Payroll Details
                                                                                 </div>
                                                                             </form>
                                                                         </div>
                                                                     </div>
                                                                 </div>
                                                                )}
                                                            </Draggable>
                                                        );
                                                    }
                                                 else if (value === "enervee_loan_offer_widget" || name === "Enervee loan offers" || id === "enervee_loan_offer_widget")
                                                    {
                                                        return (
                                                            <Draggable key={id} draggableId={id} index={index}>
                                                                {(provided) => (
                                                                     <div class="col-12 col-lg-8 mx-auto my-2" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                     <div class="card border">
                                                                         <div class="card-body d-flex justify-content-center">
                                                                         <form id={id} className="drag-drop-element">

                                                                                 <header>
                                                                                     <h5 class="header-title text-center">Enervee loan offers</h5>
                                                                                 </header>
                                                                                 <div class=" mb-5 text-center">
                                                                                 Select an offer
                                                                                 </div>
                                                                             </form>
                                                                         </div>
                                                                     </div>
                                                                 </div>
                                                                )}
                                                            </Draggable>
                                                        );
                                                    }
                                                  else if (value === "payment_method_widget" || name === "Payment method Widget" || id === "payment_method_widget")
                                                    {
                                                        return (
                                                            <Draggable key={id} draggableId={id} index={index}>
                                                                {(provided) => (
                                                                     <div class="col-12 col-lg-8 mx-auto my-2" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                     <div class="card border">
                                                                         <div class="card-body d-flex justify-content-center">
                                                                         <form id={id} className="drag-drop-element">

                                                                                 <header>
                                                                                     <h5 class="header-title text-center">Payment Method Widget</h5>
                                                                                 </header>
                                                                                 <div class=" mb-5 text-center">
                                                                                 Payment method details
                                                                                 </div>
                                                                             </form>
                                                                         </div>
                                                                     </div>
                                                                 </div>
                                                                )}
                                                            </Draggable>
                                                        );
                                                    }
                                                    else if (value === "invoice_widget" || name === "Invoice Widget" || id === "invoice_widget")
                                                        {
                                                            return (
                                                                <Draggable key={id} draggableId={id} index={index}>
                                                                    {(provided) => (
                                                                         <div class="col-12 col-lg-8 mx-auto my-2" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                         <div class="card border">
                                                                             <div class="card-body d-flex justify-content-center">
                                                                             <form id={id} className="drag-drop-element">

                                                                                     <header>
                                                                                         <h5 class="header-title text-center">Invoice Widget</h5>
                                                                                     </header>
                                                                                     <div class=" mb-5 text-center">
                                                                                        Invoice Details.
                                                                                     </div>
                                                                                 </form>
                                                                             </div>
                                                                         </div>
                                                                     </div>
                                                                    )}
                                                                </Draggable>
                                                            );
                                                        }
                                            else {
                                                return (
                                                    <Draggable key={id} draggableId={id} index={index}>
                                                        {(provided) => (
                                                             <div class="col-12 col-lg-8 mx-auto my-2" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                             <div class="card border">
                                                                 <div class="card-body d-flex justify-content-center">
                                                                 <form id={id} className="drag-drop-element">

                                                                         <header>
                                                                             <h2 class="header-title text-center">Congrats!</h2>
                                                                         </header>
                                                                         <div class="row mb-5 text-center">
                                                                         Your application got approved
                                                                             <p><i className="fa fa-card"></i></p>
                                                                         </div>
                                                                     </form>
                                                                 </div>
                                                             </div>
                                                         </div>
                                                        )}
                                                    </Draggable>
                                                );

                                            }

                                        })}
                                        {provided.placeholder}
                                    </div>
                                )}
                                </Droppable>

                                <Droppable droppableId="formControls">
                                {(provided) => (
                                    <div className="row p-3" {...provided.droppableProps}
                                         ref={provided.innerRef}>
                                        { /* eslint-disable-next-line */
                                        usedControls.map(({id, name, text, type, required,label_font_size, request_doc_id, label_alignment}, index) => {
                                            if (type === "document_upload")
                                                {
                                                    let temp_name = window.request_template_data[request_doc_id]
                                                    return (
                                                        <Draggable key={'document_upload_' + keyId +index} draggableId={'document_upload_' + keyId + index} index={index}>
                                                            {(provided) => (
                                                                <div className="row mt-2" onClick={handleFocus}
                                                                     ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                    <div className="row mt-3 justify-content-center">

                                                                    <div class="wrapper-file-input">
                                                                        <div className='input-box text-center d-flex justify-content-center'>
                                                                            <h4 id={'document_upload' + keyId + index} placeholder=""
                                                                               name='document_upload' data-isRequired={required} data-text={text} type="document_upload"
                                                                               request_doc_id={request_doc_id} className='drag-drop-element'>
                                                                            <i class="fa-solid fa-upload me-3"></i>
                                                                            {request_doc_id && request_doc_id !== '' ? temp_name : "Choose Doc Upload Template"}
                                                                            </h4>
                                                                            <label className="col-auto ms-2" htmlFor={'document_upload' + keyId + index}>{required === "true" ?
                                                                        <i className="fa-solid fa-asterisk fa-xs ms-3 text-danger"></i> : ''}</label>
                                                                        </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    );
                                                }
                                            else if (type === "document_display")
                                                {   var libName = window.doc_libs.find(item => item.id === request_doc_id);
                                                    return (
                                                        <Draggable key={'document_display_' + keyId +index} draggableId={'document_display_' + keyId + index} index={index}>
                                                            {(provided) => (
                                                                <div className="row mt-2" onClick={handleFocus}
                                                                     ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                    <div className="row mt-3 justify-content-center">

                                                                    <div class="wrapper-file-input">
                                                                        <div className='input-box text-center d-flex justify-content-center'>
                                                                            <h4 id={'document_display' + keyId + index} placeholder=""
                                                                               name='document_display' data-isRequired={required} data-text={text} type="document_display"
                                                                               request_doc_id={request_doc_id} className='drag-drop-element'>
                                                                            <i class="fa-solid fa-file me-3"></i>
                                                                            {libName ? libName.name : "Choose Consent Doc"}
                                                                            </h4>
                                                                            <label className="col-auto ms-2" htmlFor={'document_display' + keyId + index}>{required === "true" ?
                                                                        <i className="fa-solid fa-asterisk fa-xs ms-3 text-danger"></i> : ''}</label>
                                                                        </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    );
                                                } else if (type === "scroll_to_bottom") {
                                                return (
                                                    <Draggable key={'scroll_to_bottom' + keyId + index}
                                                               draggableId={'scroll_to_bottom_' + keyId + index}
                                                               index={index}>
                                                        {(provided) => (
                                                            <div className="row mt-2"
                                                                 ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                <div className="row text-center">
                                                                    <div className="">
                                                                    
                                                                        <label className="col-auto ms-2 text-primary drag-drop-element cursor-pointer"
                                                                            onClick={handleFocus}
                                                                            name={text ? text : 'Click here to scroll down'}
                                                                            data-isRequired={required}
                                                                            type="scroll_to_bottom"
                                                                            request_doc_id={request_doc_id}
                                                                            id="scroll_to_bottom"
                                                                            htmlFor={'scroll_to_bottom'}><i className="fa-regular fa-circle-down me-2"></i>{text ? text : 'Click here to scroll down'}{required === "true" ?
                                                                            <i className="fa-solid fa-asterisk fa-xs ms-3 text-danger"></i> : ''}</label>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            }
                                            else if (type === "rich_text_editor" || type === "rich_text_editor_control") {
                                                const editorId = `rich_text_editor${keyId}${index}`;
                                                const editorContent = richTextValues[editorId] || text || "";
                                                return (
                                                    <Draggable key={'rich_text_editor' + keyId + index}
                                                               draggableId={'rich_text_editor_' + keyId + index}
                                                               index={index}>
                                                        {(provided) => (
                                                            <div className="row mt-2" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                <div className='drag-drop-element' id={'rich_text_editor' + keyId + index} data-text={editorContent} name="rich_text_editor_control" type="rich_text_editor_control">
                                                                    <RichTextEditor editorContent={editorContent} id={`rich_text_editor${keyId}${index}`} onChange={(value, editorId) => handleRichTextEditorChange1(value, editorId)} />
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            }
                                            else if (type === "checkbox")
                                        {
                                            return (
                                            <Draggable key={'checkbox_control_' + keyId +index} draggableId={'checkbox_control_' + keyId + index} index={index}>
                                                        {(provided) => (
                                                            <div className="row mt-2" onClick={handleFocus}
                                                                 ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                <div className="row mt-2 justify-content-center">
                                                                    <input type="checkbox" id={'checkbox_control' + keyId + index} placeholder=""
                                                                           name='checkbox_control' data-isRequired={required} data-text={text}
                                                                           className='col-1 drag-drop-element' style={{"height": "20px"}}></input>
                                                                    <label className="col-auto m-0" htmlFor={'checkbox_control' + keyId + index} dangerouslySetInnerHTML={{__html: text}} ></label>

                                                                </div>
                                                                {/*<div*/}
                                                                {/*    className="col-6 mt-3 d-flex justify-content-between">*/}
                                                                {/*    <a href="/">Link 1</a>*/}
                                                                {/*    <a href="/">Link 2</a>*/}
                                                                {/*    <a href="/">Link 3</a>*/}
                                                                {/*</div>*/}
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            }
                                            else if (type === "label")
                                            {
                                                return (
                                                    <Draggable key={'label_control_' + keyId +index} draggableId={'label_control_' + keyId + index} index={index}>
                                                        {(provided) => (
                                                            <div className="row my-1" onClick={handleFocus}
                                                                 ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                <div className="" style={{textAlign:label_alignment}}>
                                                                    <label
                                                                    style={{ fontSize: label_font_size && label_font_size !== "" ? `${label_font_size}px` : '10px' }}
                                                                    name="label_control"
                                                                    id={'label_control' + keyId + index}
                                                                    type="label"
                                                                    data-text={text}
                                                                    label-alignment={label_alignment}
                                                                    label-font-size={label_font_size && label_font_size !== "" ? label_font_size : 10}
                                                                    data-isRequired={required}
                                                                    className="col-auto m-0 drag-drop-element" htmlFor={'label_control' + keyId + index} dangerouslySetInnerHTML={{__html: text}} ></label>

                                                                </div>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            }

                                        })}
                                        {provided.placeholder}
                                    </div>
                                )}
                                </Droppable>
                                    <button
                                        className="btn mx-auto overflow-visible btn-primary btn-primary-sm continue_btn my-2"
                                        onClick={handleContinue}>Continue
                                    </button>
                                </div>
                    </DragDropContext>
                    {showProperties ? <Properties inputelements={inputelements}/> : <Rules rules={rules}/>}


                </div>
            </header>
        </div>

        </>

    );
}

export default Variables;