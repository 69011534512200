import React from 'react';
import './Spinner.css'; // Import CSS for spinner animation

const Spinner = ({ size = 50, color = '#6f4bd1', speed = '0.8s' }) => {
  return (
    <div className="loader-overlay">
      <div className="loader-container">
        <div
          className="spinner"
          style={{
            width: `${size}px`,
            height: `${size}px`,
            // border: `${size / 20}px solid ${color}`,
            // borderTop: `${size / 20}px solid transparent`,
            borderRadius: '50%',
            animation: `spin ${speed} linear infinite`,
          }}
        />
      </div>
    </div>

  );
};

export default Spinner;
