import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';

import Spinner from '../ReusableComponents/Spinner/Spinner';
import Notification from '../ReusableComponents/Notification/Notification';
import AuthBackground from '../ReusableComponents/AuthBackground/AuthBackground';
import CardComponent from '../ReusableComponents/CardComponent/CardComponent';
import './ResetPassword.css';
import { getFromSessionStorage } from '../Utils/utils';

const getCookie = (name) => {
  const cookieValue = document.cookie
    .split('; ')
    .find(row => row.startsWith(name + '='))
    ?.split('=')[1];
  return cookieValue || '';
};

const ResetPassword = () => {
  const navigate = useNavigate();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);
  const { register, handleSubmit, formState: { errors }, setValue, watch } = useForm();
  const [signupFormData, setSignupFormData] = useState(null);

  // State for notification
  const [notification, setNotification] = useState({
    message: '',
    type: '',
    visible: false,
  });

  const [loading, setLoading] = useState(false);

  // Password validation states
  const [passwordValidation, setPasswordValidation] = useState({
    lengthValid: false,
    caseValid: false,
    specialCharValid: false,
  });

  // Watch password and confirm_password fields
  const password = watch('password');
  const confirmPassword = watch('confirm_password');

  // Function to validate password match
  const validatePasswordMatch = (value) => {
    if (!value) {
      return "Confirm password is required";
    }
    if (value !== password) {
      return "Passwords do not match";
    }
    return true;
  };

  // Display notification
  const showNotification = (message, type) => {
    setNotification({ message, type, visible: true });
  };

  const closeNotification = () => {
    setNotification({ ...notification, visible: false });
  };

  const togglePasswordVisibility = (field) => {
    if (field === 'password') {
      setIsPasswordVisible(!isPasswordVisible);
    } else if (field === 'confirm_password') {
      setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
    }
  };

  // Get signup form data from sessionStorage
  useEffect(() => {
    const savedFormData = getFromSessionStorage('signupFormData');
    if (savedFormData) {
      const parsedData = savedFormData;
      setSignupFormData(parsedData);
      setValue('first_name', parsedData.first_name);
      setValue('last_name', parsedData.last_name);
      setValue('company_name', parsedData.company_name);
      setValue('email', parsedData.email);
      setValue('mobile', parsedData.mobile);
    }
  }, [setValue]);

  // Handle password validation
  const handlePasswordChange = (password) => {
    const lengthValid = password.length >= 8;
    const caseValid = /[a-z]/.test(password) && /[A-Z]/.test(password);
    const specialCharValid = /[0-9]/.test(password) && /[!@#$%^&*(),.?":{}|<>]/.test(password);

    setPasswordValidation({
      lengthValid,
      caseValid,
      specialCharValid,
    });
  };

  // Function to handle form submission
  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const csrfToken = getCookie('csrftoken');
      const sessionId = window.__SESSIONID__;

      const config = {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken,
        }
      };

      const formData = {
        password: data.password,
        session_id: sessionId
      };

      // Submit the form data using axios
      const response = await axios.post('/signup/v1/reset_password/', formData, config);

      // Handle response
      if (response.status === 200) {
        showNotification('Password is updated.', 'success');
        setTimeout(() => {
          navigate("/login/");
        }, 2000)
      }
    } catch (error) {
      if (error.response?.status === 400) {
        showNotification('Password must have uppercase, lowercase, a number, a special character and be at least 8 characters long.', 'error');
      } else if (error.response?.status === 401) {
        showNotification('Unauthorized: Please check your credentials.', 'error');
      } else {
        showNotification('Request failed. Please try again.', 'error');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthBackground>
      <div className="main-content min-vh-100 d-flex align-items-center">
        <div className="container-fluid">
          <div className="d-flex flex-wrap justify-content-evenly">
            {/* Form Card - Reset Password Verification */}
            <CardComponent className="mt-0 form-card">
              <div className="col-12">
                <div className="d-flex justify-content-center align-items-center flex-column text-center bg-white gap-2 card-header">
                  <h3 className="main-title text-center mb-0">Reset your password</h3>
                  <p className="sub-heading text-center mb-0 fw-normal" style={{ opacity: '0.8' }}>
                  Choose a strong password to protect your account
                  </p>
                </div>
                <div className="">
                  <div className="card-body">
                    <form onSubmit={handleSubmit(onSubmit)} className="text-start" id="form-signup">
                      {/* Form Fields */}
                      {/* Choose a new password */}
                      <div className="input-group mb-0">
                        <label>Choose a new Password <span>*</span></label>
                        <input
                          type={isPasswordVisible ? 'text' : 'password'}
                          className={`form-control p-2 border ${errors.password ? 'is-invalid' : ''}`}
                          placeholder="Choose password"
                          {...register('password', {
                            required: 'Password is required',
                            minLength: {
                              value: 8,
                              message: 'Password must be at least 8 characters long',
                            },
                            onChange: (e) => handlePasswordChange(e.target.value),
                          })}
                          id="comp-pass1"
                        />
                        <i
                          className={`fa fa-fw ${isPasswordVisible ? 'fa-eye-slash' : 'fa-eye'} field-icon toggle-password fs-6 mt-n2 mt-lg-0`}
                          onClick={() => togglePasswordVisibility('password')}
                          style={{ opacity: isPasswordVisible ? "0.7" : "0.9", top: "45px", position: "obsolute", right: "10px", trasnform: "translateY(-50%)", fontSize: "1rem", cursor: "pointer" }}
                          aria-label="Toggle password visibility"
                        ></i>
                        {/* {errors.password && <p className="text-danger">{errors.password.message}</p>} */}
                      </div>

                      {/* Helper Texts Below Password */}
                      <div className="password-help-texts mb-4">
                        <p
                          className={`help-text mb-0 ${passwordValidation.lengthValid ? 'valid' : passwordValidation.lengthValid === null ? 'valid' : 'invalid'}`}
                        >
                          at least 8 characters
                        </p>
                        <p
                          className={`help-text mb-0 ${passwordValidation.caseValid ? 'valid' : passwordValidation.caseValid === null ? 'valid' : 'invalid'}`}
                        >
                          at least 1 uppercase & 1 lowercase
                        </p>
                        <p
                          className={`help-text mb-0 ${passwordValidation.specialCharValid ? 'valid' : passwordValidation.specialCharValid === null ? 'valid' : 'invalid'}`}
                        >
                          at least 1 number & special character
                        </p>
                      </div>

                      {/* Confirm password */}
                      <div className="input-group mb-0">
                        <label>Confirm password <span>*</span></label>
                        <input
                          type={isConfirmPasswordVisible ? 'text' : 'password'}
                          className={`form-control p-2 border ${errors.confirm_password ? 'is-invalid' : ''}`}
                          placeholder="Re-enter your password"
                          {...register('confirm_password', {
                            required: 'Confirm password is required',
                            validate: (value) => value === watch('password') || 'Passwords do not match',
                          })}
                          id="comp-pass2"
                        />
                        <i
                          className={`fa fa-fw ${isConfirmPasswordVisible ? 'fa-eye-slash' : 'fa-eye'} field-icon toggle-password fs-6 mt-n2 mt-lg-0`}
                          onClick={() => togglePasswordVisibility('confirm_password')}
                          style={{ opacity: isPasswordVisible ? "0.7" : "0.9", top: "45px", position: "obsolute", right: "10px", trasnform: "translateY(-50%)", fontSize: "1rem", cursor: "pointer" }}
                          aria-label="Toggle confirm password visibility"
                        ></i>
                        {/* {errors.confirm_password && <p className="text-danger">{errors.confirm_password.message}</p>} */}
                      </div>

                      {/* Password Match Help Text */}
                      <div className="password-match-text">
                        {confirmPassword && password ? (
                          <p className={`help-text mb-0 ${confirmPassword === password ? 'valid' : 'invalid'}`}>
                            {confirmPassword === password ? 'passwords match' : 'passwords do not match'}
                          </p>
                        ) : (
                          <p className="help-text mb-0" style={{color: "#939393"}}>please confirm your password</p>
                        )}
                      </div>

                      {/* Submit Button */}
                      <div className="col-12 mt-lg-4 mt-4 d-flex justify-content-center align-items-center">
                        <button disabled={loading} type="submit" className="btn btn-primary-sm btn-primary w-100 my-3 mb-3 text-capitalize text-center">
                          {loading ? "Creating..." : "Confirm"}
                        </button>
                      </div>

                      {/* Already have an account? */}
                      <div className="mt-2">
                        <p className="text-sm fw-normal text-center mb-0">
                          Already have an account?
                          <Link to="/login" className="text-info ms-1">
                            Sign in
                          </Link>
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </CardComponent>
          </div>
        </div>
      </div>

      {loading && (
        <div className="d-flex justify-content-center align-items-center spinner-wrapper">
          <Spinner animation="border" />
        </div>
      )}

      <Notification
        message={notification.message}
        type={notification.type}
        visible={notification.visible}
        onClose={closeNotification}
      />
    </AuthBackground>
  );
};

export default ResetPassword;
