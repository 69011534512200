import React, {useState, useEffect} from 'react';
import axios from 'axios';
import Select from 'react-select';
import {getTestModeCookieValue} from "../../../utils/global_function";
import Modal from "react-bootstrap/Modal";

axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";
const combinedCredentials = window.api_username + ":" + window.api_password;
const ApiCredentials = btoa(combinedCredentials);
const test_mode_val = getTestModeCookieValue()


const ConfigModal = (props) => {
    const [selectedVariables, setSelectedVariables] = useState(props?.productConfig ? props?.productConfig?.approve_variables : null);
    const [variableOptions, setVariableOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get(`/api/v1/list_variables/?product=${window.product_type}&test_mode=${test_mode_val}&all_vars=true`, {
                headers: {
                    Authorization: "Basic " + ApiCredentials
                },
            });
            if (response.data.results) {
                const varOptions = response.data.results?.map((item) => ({
                    ...item,
                    label: item.name,
                    value: item.engine_name,
                }))
                setVariableOptions(varOptions)
            }

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    const handleSave = async () => {
        setIsLoading(true);
        const postData = {
            product: window.product_type,
            product_referral: window.product_referral,
            test_mode: test_mode_val,
            approve_variables: selectedVariables,
        };
        console.log(postData)

        axios.post("/api/v1/save_product_config/",
            postData,
            {
                headers: {
                    Authorization: "Basic " + ApiCredentials
                },
            }
        ).then(function (response) {
            setIsLoading(false);
            props.onHide();

        }).catch(function (error) {
            setIsLoading(false);
            window.show_alert(error.response.data.message);
            props.onHide();

        });
    };

    return (<>
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Product configuration
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='w-100 col-3 mb-2 mt-4' id="provider-select">
                    <p htmlFor="provider_select" className="h6 b strong">Variables required for approval
                    </p>
                    <small className="mb-3">At the time of manual application approval, values will need to be provided
                        for each selected variable.
                        These values will take priority and will be used to guide the subsequent application
                        workflow.</small>
                    <Select
                        value={selectedVariables}
                        onChange={setSelectedVariables}
                        options={variableOptions}
                        isMulti
                        className="react-select provider-select mt-3"
                    />
                </div>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between">
                <button className='btn btn-secondary btn-secondary-sm' style={{boxShadow: "none"}}
                        onClick={props.onHide}>Close
                </button>
                <button className='btn btn-primary btn-primary-sm' type="submit"
                        onClick={handleSave}>
                    {isLoading ? (
                    <span
                        className="spinner-border spinner-border-sm me-2"
                        role="status"
                        aria-hidden="true"
                    ></span> ) : null}
                    {isLoading ? 'Saving...' : 'Save config'}
                </button>
            </Modal.Footer>
        </Modal>
    </>);
};

export default ConfigModal;
